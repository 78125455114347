import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { AccordionItem } from '@cp-shared-8/frontend-ui';
import { VehicleDetailsUi } from './ui';
import {
    VehicleDetails as VehicleDetailsType,
    ContractDetailsData,
    isErrorDetailsData,
    isSuccessDetailsData,
} from '../../../../common';
import { withNoConnectionHandler } from '../../../integration-wrapper';

type VehicleDetailsProps = { vehicleDetails?: ContractDetailsData<VehicleDetailsType> };

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({ vehicleDetails }) => {
    const { t } = useTranslation('vehicle-details');

    if (!vehicleDetails || (isSuccessDetailsData(vehicleDetails) && isEmpty(vehicleDetails.data))) return null;

    const hasError = !!vehicleDetails && isErrorDetailsData(vehicleDetails);

    const VehicleDetailsUiWithHandlers = withNoConnectionHandler(VehicleDetailsUi);

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <VehicleDetailsUiWithHandlers vehicleDetailsData={vehicleDetails?.data} hasError={hasError} />
        </AccordionItem>
    );
};
