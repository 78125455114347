import {
    Contract,
    ContractDetails,
    CustomerRole,
    getAmortizationTableEndpoint,
    ProductTypeEN,
} from '../../../../common';
import {
    Accordion,
    ContractActionItemType,
    ContractActions,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { Modal } from '@vwfs-bronson/bronson-react';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from '../../../../cp-xhr';
import {
    contractHandoverPath,
    myDocumentsWithContractIdPagePath,
    partialEarlySettlementPath,
    requestAppointmentForMaintenancePath,
} from '../../../navigation/paths';
import { withRequestAppointmentForMaintenance } from '../../utils';
import { BundledProductsDetails } from '../bundled-products';
import { ContractParties } from '../contract-parties';
import { FinancialDetails } from '../financial-details';
import { VehicleDetails } from '../vehicle-details';

type ContractDetailsUiProps = {
    contract: Contract;
    contractDetails?: ContractDetails;
};

export const ContractDetailsUi: React.FC<ContractDetailsUiProps> = ({ contract, contractDetails }) => {
    const { t, i18n } = useTranslation('contracts');

    const currentLanguage = i18n.languages[0];

    const [hasError, setHasError] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { onAction: trackOnDownload } = useAnalyticsActionTracker('onAmortizationTableDownload');
    const { onAction: trackOnAccordionItemOpen } = useAnalyticsActionTracker('onOpenContractAccordion');

    const fileName = t('contract-actions.amortization-table.filename', {
        contractId: contract.contractId,
        date: moment(moment.now()).format('YYYYMMDD'),
    });

    const downloadPdf = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        e.preventDefault();
        const requestBody = {
            locale: currentLanguage,
        };
        setIsSubmitting(true);
        CpDataApi.get(getAmortizationTableEndpoint(contract.contractId), { params: requestBody })
            .then((response) => {
                const fileContent = response.data ?? '';
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                trackOnDownload();
            })
            .catch(() => {
                setHasError(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const amortisationTable = (): ContractActionItemType[] => {
        if (
            contract.productTypeEN === ProductTypeEN.FINANCIAL &&
            contract.isActive &&
            (contract.customerRole === CustomerRole.FIRST_HOLDER ||
                contract.customerRole === CustomerRole.SECOND_HOLDER)
        )
            return [
                {
                    isLoading: isSubmitting,
                    pageUrl: '/#',
                    iconName: 'semantic-calendar',
                    label: t('contract-actions.amortization-table.link'),
                    onClick: downloadPdf,
                },
            ];
        return [];
    };

    const contractHandover = (): ContractActionItemType[] => {
        return contract.isActive
            ? [
                  {
                      pageUrl: contractHandoverPath(),
                      iconName: 'semantic-edit',
                      label: t('contract-actions.contract-handover.link'),
                  },
              ]
            : [];
    };

    const partialEarlySettlement = (): ContractActionItemType[] => {
        return contract.productTypeEN === ProductTypeEN.FINANCIAL && contract.isActive
            ? [
                  {
                      pageUrl: partialEarlySettlementPath(contract.contractId),
                      iconName: 'semantic-calculator',
                      label: t('contract-actions.partial-early-settlement'),
                  },
              ]
            : [];
    };

    const requestForMaintenance = (): ContractActionItemType[] => {
        return withRequestAppointmentForMaintenance(contract)
            ? [
                  {
                      pageUrl: requestAppointmentForMaintenancePath(contract.contractId),
                      iconName: 'semantic-location',
                      label: t('contract-actions.request-appointment-for-maintenance'),
                  },
              ]
            : [];
    };

    const contractActionItems: ContractActionItemType[] = [
        ...amortisationTable(),
        ...contractHandover(),
        ...partialEarlySettlement(),
        ...requestForMaintenance(),
        {
            iconName: 'semantic-mail',
            pageUrl: myDocumentsWithContractIdPagePath(contract.contractId),
            label: t('contract-actions.my-documents'),
        },
    ];

    const getViewChange = (currentViewName: string) => {
        switch (currentViewName) {
            case t('financial-details:headline'):
                return 'Financial details';
            case t('vehicle-details:headline'):
                return 'Vehicle details';
            case t('contract-parties:headline'):
                return 'Contract parties';
            case t('bundled-products:headline'):
                return 'Products / Services included';
        }
    };

    return (
        <>
            <Accordion
                lazyRender
                onChange={([items]: string[]) => {
                    if (!!items) {
                        const viewChange = getViewChange(items);
                        trackOnAccordionItemOpen(viewChange);
                    }
                }}
            >
                <>
                    {contractActionItems.length ? (
                        <div className="u-pb-small u-mt-xxsmall">
                            <ContractActions contractActionItems={contractActionItems} />
                        </div>
                    ) : null}
                    <FinancialDetails contract={contract} financialDetails={contractDetails?.financialDetails} />
                    <VehicleDetails vehicleDetails={contractDetails?.vehicleDetails} />
                    <ContractParties contractParties={contractDetails?.contractParties} />
                    <BundledProductsDetails bundledProducts={contractDetails?.bundledProducts} />
                </>
            </Accordion>
            <Modal
                shown={hasError}
                buttonConfirmText={t('contract-actions.amortization-table.button-text')}
                onConfirm={(): void => setHasError(false)}
                onClose={(): void => setHasError(false)}
                status="error"
                testId={'amortization-table-error-modal'}
            >
                {t('contract-actions.amortization-table.error')}
            </Modal>
        </>
    );
};
