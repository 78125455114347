import { createCmsContentSlice } from 'cms-integration';
import { getDashboardMarketingCardEndpoint, MarketingCard } from '../../common';

const { reducer, fetchContent } = createCmsContentSlice<MarketingCard>({
    contentName: 'dashboardMarketingCard',
    contentEndpoint: getDashboardMarketingCardEndpoint,
});

export default reducer;
export const fetchDashboardMarketingCard = fetchContent;
