import React, { useCallback } from 'react';
import { ContractInfo } from 'common';
import { useTranslation } from 'react-i18next';
import { Combobox, ErrorMessage, FormField } from '@vwfs-bronson/bronson-react';
import { RequestForm } from '../initialValues';
import { FormikProps, useField, useFormikContext } from 'formik';
import { ComboboxOption } from '../../../../types/bronson-react';

export type ContractSelectionProps = {
    contracts?: ContractInfo[];
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    resetForm: (...args: any[]) => any;
    currentContractNumber: string;
};

export const generalKey = `generalKey`;

export const ContractSelection: React.FC<ContractSelectionProps> = ({
    contracts,
    resetForm,
    currentContractNumber,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, meta, helpers] = useField('contractSelection');
    const { initialValues }: FormikProps<RequestForm> = useFormikContext();
    const { t } = useTranslation('request');
    const currentContract: string = currentContractNumber || generalKey;
    const generalOptionValue = t(`contract-selection.general-value`);

    const setValueChange = useCallback((selectedContractsOptions: ComboboxOption[]) => {
        const selectedContractNumber = selectedContractsOptions.map((contract) => contract.key);

        const { isActive, contractId, contractNumber, productTypeEN } = contracts?.find(({ contractNumber }) =>
            selectedContractNumber.includes(contractNumber),
        ) || {
            isActive: true,
            productTypeEN: '',
            contractNumber: '',
            contractId: '',
        };

        const contractSelectionValues = {
            isActive,
            contractId,
            contractNumber: contractNumber || generalOptionValue,
            productTypeEN,
        };

        if (currentContractNumber !== contractNumber) {
            resetForm({
                values: { ...initialValues, contractSelection: contractSelectionValues },
            });
        }

        return helpers.setValue(contractSelectionValues || initialValues.contractSelection);
    }, []);

    if (!contracts) {
        return null;
    }

    const contractOptions = [
        ...contracts.filter((contract) => contract.isActive),
        ...contracts.filter((contract) => contract.isActive === false),
    ]
        .map(({ brand, model, productType, licensePlate, contractNumber, isActive }) => {
            if (!contractNumber || !productType) return null;

            const getProductTypeValue = () => (isActive ? productType : t(`contract-selection.contract-expired`));
            const carInformation = `${brand} ${model}, ${licensePlate?.toUpperCase()}`;

            return (
                <Combobox.Item
                    testId={`contract-selection-item-${contractNumber}`}
                    optionValue={contractNumber}
                    optionKey={contractNumber}
                    key={contractNumber}
                >
                    <b>{contractNumber}&nbsp;</b>
                    <br />
                    <b>{getProductTypeValue()}&nbsp;</b>
                    <br />
                    {brand && model && licensePlate && <span>{carInformation}</span>}
                </Combobox.Item>
            );
        })
        .filter(Boolean);

    const defaultState = {
        value: [
            {
                value: currentContractNumber,
                key: currentContractNumber,
            },
        ],
    };

    const generalOption = (
        <Combobox.Item
            testId={`contract-selection-item-general`}
            optionValue={generalOptionValue}
            optionKey={generalOptionValue}
            key={`${currentContract}-general`}
        >
            <b>{generalOptionValue}</b>
        </Combobox.Item>
    );
    const options = [generalOption, ...contractOptions];

    const label = `${t('contract-selection.label')} *`;

    return (
        <FormField
            type="select"
            testId={'contract-selection'}
            id={'contract-selection'}
            errorMessage={
                meta.touched &&
                meta.error && <ErrorMessage testId={'textarea-error-message'}>{meta.error}</ErrorMessage>
            }
            labelText={label}
            notion
        >
            <Combobox
                name={'contractSelection'}
                testId={'custom-contract-select'}
                onChange={setValueChange}
                defaultState={defaultState}
                key={'contractSelection'}
            >
                {options}
            </Combobox>
        </FormField>
    );
};
