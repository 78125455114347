import React from 'react';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { BaseAddress, AddressType, EditStatus } from 'common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { SubmitStatusNotification } from '../../submit-status-notification';

type ConsultViewProps = {
    address?: BaseAddress;
    addressType: AddressType;
    startEditing: () => void;
    lastEditStatus?: EditStatus;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    address = {},
    addressType,
    startEditing,
    lastEditStatus,
}) => {
    const { t } = useTranslation('my-profile');

    const noValuePlaceholder = '-';
    const translationSuffix = addressType === AddressType.FISCAL ? 'fiscal-address' : 'postal-address';
    const {
        roadType = '',
        streetName = '',
        houseNumber = '',
        block = '',
        floor = '',
        door = '',
        place = '',
        zipCode = '',
        localityCity = '',
        country = '',
    } = address;

    const formatAsAddress = (array: string[]): string => array.filter(Boolean).join(', ').toUpperCase();

    const blockLine = block ? ` ${t('addresses.block')} ${block}` : '';
    const floorDoorLine = floor && door ? `${floor}-${door}` : '';
    const zipCodeLine = zipCode && localityCity ? `${zipCode} ${localityCity}` : zipCode || localityCity;

    const addressLine1 = formatAsAddress([roadType, streetName, houseNumber, blockLine, floorDoorLine, place]);
    const addressLine2 = formatAsAddress([zipCodeLine, country]);

    const addressList: DefinitionListItem[] = [
        {
            label: t('addresses.address-line-1'),
            value: addressLine1 && addressLine2 ? addressLine1 : noValuePlaceholder,
            testId: 'address-line-1',
        },
        {
            label: t('addresses.address-line-2'),
            value: addressLine1 && addressLine2 ? addressLine2 : noValuePlaceholder,
            testId: 'address-line-2',
        },
    ];

    return (
        <DataOverview
            title={t(`addresses.headline.${translationSuffix}`)}
            buttonLabel={t('translation:editable-section-nav.edit')}
            buttonProps={{
                onClick: startEditing,
                testId: 'edit-button',
            }}
        >
            <DefinitionListHorizontal list={addressList} />
            <SubmitStatusNotification lastEditStatus={lastEditStatus} section="address" />
        </DataOverview>
    );
};
