import React from 'react';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';

const temporaryTimeSlots =
    '10:00,10:30,11:00,11:30,12:00,12:30,13:00,13:30,14:00,14:30,15:00,15:30,16:00,16:30,17:00,17:30,18:00';
const temporaryTimeSlotsItems: ValidatedSelectItem[] = temporaryTimeSlots
    .split(',')
    .map((time) => ({ label: time, value: time }));

export const TimeSlotSelection: React.FC<{
    name: string;
    label: string;
    placeholder: string;
}> = ({ name, label, placeholder }) => {
    return (
        <ValidatedSelect
            name={name}
            label={label}
            testId={`test-time`}
            placeholder={placeholder}
            isMandatory={true}
            selectItems={temporaryTimeSlotsItems}
        />
    );
};
