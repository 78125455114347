import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getMyDocumentsEndpoint } from 'common';
import { DocumentsWithAllValues, DocumentsWithoutContractNumber } from './ExampleData';
import { ContractsMock } from '../contracts';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: DocumentsWithAllValues,
    },
    'With missing contract number': {
        status: 200,
        responseBody: DocumentsWithoutContractNumber,
    },
    'With no documents': {
        status: 200,
        responseBody: [],
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyDocumentsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptions,
    url: getMyDocumentsEndpoint(),
};

export const MyDocumentsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyDocumentsMock, ContractsMock]);

    return <div>{storyFn()}</div>;
};
