import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-8/frontend-ui';
import { NavigationBar } from 'components/navigation/NavigationBar';

import { IconFooter } from 'components/footer/icon-footer';
import { LegalFooter } from 'components/footer/legal-footer';
import { registrationPagePath } from '../../components/navigation/paths';

//eslint-disable-next-line react/display-name
const MasterPageComponent: React.FC<{ isAuthenticated: boolean }> = memo(({ isAuthenticated, children }) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const language = i18n.languages[0];

    return (
        <AnalyticsProvider
            market={'pt'}
            isAuthenticated={isAuthenticated}
            version={'1'}
            releaseDate={'2021-04-01'}
            language={language}
            registrationPath={registrationPagePath()}
            loginPath={'/authentication/callback'}
        >
            <MasterPageShared
                navigationBar={<NavigationBar />}
                iconFooter={<IconFooter />}
                legalFooter={<LegalFooter />}
                label={t('back-to-top')}
            >
                {children}
            </MasterPageShared>
        </AnalyticsProvider>
    );
});

export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    return <MasterPageComponent isAuthenticated={isAuthenticated}>{children}</MasterPageComponent>;
};
