import { formatCpDate } from '@cp-shared-8/common-utilities';
import { Contract, CustomerRole, ProductTypeEN } from '../../common';

export const ActiveOperativeLeasingWithAllValues: Contract = {
    isActive: true,
    contractId: 'AX0XD',
    contractNumber: '11234567',
    customerRole: CustomerRole.FIRST_HOLDER,
    productType: 'Contrato de Renting',
    productTypeEN: ProductTypeEN.OPERATIVE_LEASING,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
    vin: 'XAUZZZ4G1FN076529',
    encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
    nextDueDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    nextDueAmount: 350,
    outstandingBalance: 5200.23,
    hasDigitalInvoice: true,
};

export const ActiveFinanceWithReturnCarNotification: Contract = {
    isActive: true,
    contractId: 'AX7XD',
    contractNumber: '11234568',
    customerRole: CustomerRole.FIRST_HOLDER,
    productType: 'Contrato de Auto Crédito',
    productTypeEN: ProductTypeEN.FINANCIAL,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
    vin: 'XAUZZZ4G1FN076529',
    encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
    nextDueDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    nextDueAmount: 350,
    outstandingBalance: 5200.23,
    hasDigitalInvoice: true,
    endDate: '2021-08-02T23:00:00.000Z',
};

export const ActiveFinanceWithUnpaidAndReturnCarNotification: Contract = {
    ...ActiveFinanceWithReturnCarNotification,
    contractId: 'AX9XD',
    contractNumber: '11234579',
};

export const ActiveFinanceContractWithAllValues: Contract = {
    isActive: true,
    contractId: 'AX8XD',
    contractNumber: '11234569',
    customerRole: CustomerRole.FIRST_HOLDER,
    productType: 'Contrato de Auto Crédito',
    productTypeEN: ProductTypeEN.FINANCIAL,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
    vin: 'XAUZZZ4G1FN076529',
    encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
    nextDueDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    nextDueAmount: 350,
    outstandingBalance: 5200.23,
    hasDigitalInvoice: true,
};

export const ActiveFinanceContractWithAllValuesAsGuarantor: Contract = {
    isActive: true,
    contractId: 'AX1XD',
    contractNumber: '11234570',
    customerRole: CustomerRole.GUARANTOR,
    productType: 'Contrato de Auto Crédito',
    productTypeEN: ProductTypeEN.FINANCIAL,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
    vin: 'XAUZZZ4G1FN076529',
    encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
    nextDueDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    nextDueAmount: 350,
    outstandingBalance: 5200.23,
};

export const ActiveFinanceContractWithMissingValues: Contract = {
    isActive: true,
    contractId: 'AX2XD',
    contractNumber: '21234571',
    productType: 'Contrato de Auto Crédito',
    productTypeEN: ProductTypeEN.FINANCIAL,
    brand: 'VW',
    model: 'Golf',
    outstandingBalance: 5200.23,
    endDate: formatCpDate('2022-11-11T09:44:57.299').toCpDate(),
};

export const ActiveMaintenanceContractWithAllValues: Contract = {
    isActive: true,
    contractId: 'AX3XD',
    contractNumber: '31234572',
    customerRole: CustomerRole.FIRST_HOLDER,
    productType: 'Contrato de Manutenção',
    productTypeEN: ProductTypeEN.MAINTENANCE,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
    vin: 'XAUZZZ4G1FN076529',
    encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
    nextDueDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    nextDueAmount: 350,
    endDate: formatCpDate('2022-11-11T09:44:57.299').toCpDate(),
    hasDigitalInvoice: true,
};

export const ActiveMaintenanceContractWithMissingValues: Contract = {
    isActive: true,
    contractId: 'AX4XD',
    contractNumber: '41234573',
    productType: 'Contrato de Manutenção',
    productTypeEN: ProductTypeEN.MAINTENANCE,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
};

export const InactiveFinanceContractWithAllValues: Contract = {
    isActive: false,
    contractId: 'AX5XD',
    contractNumber: '51234574',
    customerRole: CustomerRole.FIRST_HOLDER,
    productType: 'Contrato de Auto Crédito',
    productTypeEN: ProductTypeEN.FINANCIAL,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
    vin: 'XAUZZZ4G1FN076529',
    encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
    endDate: formatCpDate('2024-02-10T09:44:57.299').toCpDate(),
    terminationType: 'Rescisão Contenciosa',
    hasDigitalInvoice: true,
};

export const InactiveFinanceContractWithMissingValues: Contract = {
    isActive: false,
    contractId: 'AX6XD',
    contractNumber: '61234575',
    productType: 'Contrato de Auto Crédito',
    productTypeEN: ProductTypeEN.FINANCIAL,
    brand: 'VW',
    model: 'Golf',
    endDate: formatCpDate('2024-02-11T09:44:57.299').toCpDate(),
};

export const InactiveContractExpiredEndDate: Contract = {
    isActive: false,
    contractId: 'AX5XD',
    contractNumber: '51234576',
    productType: 'Contrato de Auto Crédito',
    productTypeEN: ProductTypeEN.FINANCIAL,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
    vin: 'XAUZZZ4G1FN076529',
    encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
    endDate: formatCpDate('2016-02-10T09:44:57.299').toCpDate(),
    terminationType: 'Rescisão Contenciosa',
};

export const InactiveFinanceContractWithUnpaidAlert: Contract = {
    isActive: false,
    contractId: 'AX1XD',
    contractNumber: '11234577',
    customerRole: CustomerRole.FIRST_HOLDER,
    productType: 'Contrato de Auto Crédito',
    productTypeEN: ProductTypeEN.FINANCIAL,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
    vin: 'XAUZZZ4G1FN076529',
    encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
    nextDueDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    nextDueAmount: 350,
    outstandingBalance: 5200.23,
    litigationState: false,
    amountsInOverdue: 2000,
};

export const ActiveContractWithMissingRequiredValues: Contract = {
    isActive: true,
    contractId: 'AX1XD',
    contractNumber: '11234578',
    brand: 'VW',
    licensePlate: '12-AB-34',
    nextDueDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    nextDueAmount: 350,
    outstandingBalance: 5200.23,
};

export const ContractsWithAllValues: Contract[] = [
    ActiveFinanceContractWithAllValues,
    ActiveMaintenanceContractWithAllValues,
    InactiveFinanceContractWithAllValues,
    InactiveFinanceContractWithUnpaidAlert,
];

export const ContractWithAllValues: Contract = {
    isActive: false,
    contractId: 'AX5XD',
    contractNumber: '51234574',
    customerRole: CustomerRole.FIRST_HOLDER,
    productType: 'Contrato de Auto Crédito',
    productTypeEN: ProductTypeEN.FINANCIAL,
    brand: 'VW',
    model: 'Golf',
    licensePlate: '12-AB-34',
    vin: 'XAUZZZ4G1FN076529',
    encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
    endDate: formatCpDate('2024-02-10T09:44:57.299').toCpDate(),
    terminationType: 'Rescisão Contenciosa',
    hasDigitalInvoice: true,
};
