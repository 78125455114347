import { PaymentDetails, getPaymentDetailsEndpoint } from 'common';
import { DefaultMarketApiErrors } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { useContract } from 'components/contracts/useContract';
import { useMemo } from 'react';

import { PaymentDetailsDataSelector } from './PaymentDetailsDataSelector';
import { fetchPaymentDetails } from './PaymentDetailsSlice';

export function usePaymentDetails(contractId: string): AbstractDataState<PaymentDetails, DefaultMarketApiErrors> {
    const contractState = useContract(contractId);
    const paymentDetailsState = useGetContractBasedApiData(
        contractId,
        fetchPaymentDetails,
        PaymentDetailsDataSelector,
        getPaymentDetailsEndpoint(contractId),
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || paymentDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && paymentDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? paymentDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: paymentDetailsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? paymentDetailsState.loadingError
                : //eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (contractState.loadingError as any),
        }),
        [contractState, paymentDetailsState],
    );
}
