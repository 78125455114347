import React, { useState } from 'react';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { Button, ButtonContainer, Fieldset, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from '../../../../../../navigation/paths';
import { useHistory } from 'react-router-dom';
import { InitialValues } from './InitialValues';
import { Form, Formik } from 'formik';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ValidatedIbanInput } from './validated-iban-input/ValidatedIbanInput';
import { IbanStateHandler } from './types';
import { BankData, SignedData } from '@cp-shared-8/apis';
import { getValidationSchema } from './validationSchema';
import { EditViewNames } from '../../enums';
import { isEmpty } from 'lodash';

type IbanViewProps = {
    setCurrentView: (newEditView: EditViewNames) => void;
    ibanViewValues: InitialValues;
    setIbanViewValues: (ibanValues: InitialValues) => void;
};

export const IbanView: React.FC<IbanViewProps> = ({ setCurrentView, ibanViewValues, setIbanViewValues }) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const history = useHistory();
    const { onAction: onStartTyping } = useAnalyticsActionTracker('financialDetailsEditIbanSectionStartTyping');
    const { onAction: onEditCancel } = useAnalyticsActionTracker('financialDetailsEditIbanSectionCancel');
    const { onAction: onValidationError } = useAnalyticsActionTracker('financialDetailsEditIbanSectionValidationError');

    const [signedBankData, setSignedBankData] = useState<SignedData<BankData>>();
    const [savedIban, setSavedIban] = useState<{ iban?: string; error?: string }>({});
    const [isValidating, setIsValidating] = useState<boolean>(false);

    const ibanStateHandler: IbanStateHandler = {
        signedBankData,
        setSignedBankData,
        savedIban,
        setSavedIban,
    };

    const validationSchema = getValidationSchema(t, setIsValidating, ibanStateHandler, ibanViewValues.currentIban);

    const handleStepChange = (values: InitialValues): void => {
        setIbanViewValues({ ...values, bic: signedBankData?.data.bankDetails?.bic || '' });
        setCurrentView(EditViewNames.SEPA_MANDATE_DOWNLOAD_VIEW);
    };

    return (
        <>
            <Heading className="u-pt" level={'6'}>
                {t('iban-section.edit.title')}
            </Heading>
            <Notification
                className={'u-mb'}
                testId={'info-notification'}
                status={NotificationStatus.info}
                text={t('iban-section.notification.info.text')}
            />
            <Formik
                initialValues={{ currentIban: ibanViewValues.currentIban, iban: '', bic: '' }}
                validationSchema={validationSchema}
                onSubmit={handleStepChange}
                validateOnBlur={true}
            >
                {({ handleSubmit, errors, touched }) => (
                    <Form
                        onSubmit={(e) => e.preventDefault()}
                        data-testid="iban-form"
                        onChange={() => {
                            onStartTyping();
                        }}
                    >
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/2" s="1/1">
                                        <ValidatedIbanInput
                                            isValidating={isValidating}
                                            ibanStateHandler={ibanStateHandler}
                                            ibanViewValues={ibanViewValues}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer className={'u-mt'} center>
                                    <Button
                                        secondary
                                        onClick={(): void => {
                                            onEditCancel();
                                            history.push(dashboardPagePath());
                                        }}
                                        testId="dashboard"
                                    >
                                        {t('translation:editable-section-nav.back')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            if (isEmpty(touched) && isEmpty(errors))
                                                onValidationError({ iban: 'Iban' });
                                            if (!isEmpty(errors)) onValidationError(errors);
                                            handleSubmit();
                                        }}
                                        testId="continue-to-sepa-view"
                                        type="submit"
                                    >
                                        {t('translation:editable-section-nav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </>
    );
};
