import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from '../../../../cp-xhr';
import {
    Contract,
    dateFormatPt,
    getRequestAppointmentForCarReturnEndpoint,
    RequestAppointmentForCarReturn,
} from 'common';
import { getInitialValues, RequestAppointmentForm } from './initialValues';
import { validationSchema } from './validationSchema';
import { Button, ButtonContainer, ContentSection, Fieldset, Layout, Modal } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../../navigation/paths';
import { Spinner, ValidatedDate, ValidatedInput, ValidatedTextarea } from '@cp-shared-8/frontend-ui';
import { ErrorNotification } from '../../../notifications/error/ErrorNotification';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { withRequestAppointmentForCarReturn } from '../../../contracts/utils';
import { DealerSelection } from '../../dealer-selection';
import { currentBrand } from '../../../../config';
import { TimeSlotSelection } from '../../time-slot-selection/TimeSlotSelection';

const today = formatCpDate().toMoment().startOf('day');

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    NONE = 'NONE',
}

export const FormView: React.FC<{
    contract?: Contract;
    defaultPhoneNumber?: string;
    showNotificationError?: boolean;
}> = ({ contract, defaultPhoneNumber }) => {
    const { t, i18n } = useTranslation('request-appointment-for-car-return');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const history = useHistory();
    const language = i18n.languages[0];
    // const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    // const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');

    if (!contract) {
        return null;
    }

    if (!withRequestAppointmentForCarReturn(contract)) {
        return <ErrorNotification testId={'notification-no-contract'} />;
    }

    const handleSubmit = (
        values: RequestAppointmentForm,
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        { resetForm }: { resetForm: (...args: any[]) => any },
    ): void => {
        const { dealer, date, time, phoneNumber, comment } = values;
        const dateFormat = language === 'pt' ? dateFormatPt : 'DD/MM/YYYY';
        const request: RequestAppointmentForCarReturn = {
            dealer,
            date: formatCpDate(date, dateFormat).toCpDate(),
            time,
            phoneNumber,
            comment,
        };

        CpDataApi.post(getRequestAppointmentForCarReturnEndpoint(contract.contractId), request)
            .then(() => {
                // onSuccess(`Category: ${category}`);
                setIsSubmitting(false);
                resetForm();
                setMessageType(MessageType.SUCCESS);
            })
            .catch(() => {
                // onError(`Category: ${category}`);
                setIsSubmitting(false);
                setMessageType(MessageType.ERROR);
            });

        setIsSubmitting(true);
    };

    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSuccessModalClose = (resetForm: (...args: any[]) => any): void => {
        resetForm({ values: getInitialValues(defaultPhoneNumber) });
        handleModalClose();
    };

    const handleSuccessModalConfirm = (): void => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    return (
        <Formik
            initialValues={getInitialValues(defaultPhoneNumber)}
            validationSchema={validationSchema(t, currentBrand)}
            onSubmit={handleSubmit}
            validateOnBlur={true}
        >
            {({ submitForm, values, resetForm, dirty, isValid }): React.ReactNode => (
                <Form onSubmit={(e): void => e.preventDefault()} data-testid="request-form">
                    <ContentSection>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    {isSubmitting && (
                                        <Layout.Item>
                                            <Spinner fullPage />
                                        </Layout.Item>
                                    )}
                                    <Layout.Item>
                                        <DealerSelection
                                            currentBrand={currentBrand}
                                            name={'dealer'}
                                            testId={'test-dealer'}
                                            currentDealerSelection={values.dealer}
                                            label={t('dealer.label')}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <ValidatedDate
                                            name={'date'}
                                            label={t('date.label')}
                                            language={language}
                                            outputDateFormat={language === 'pt' ? 'd-m-Y' : 'd/m/Y'}
                                            isMandatory={true}
                                            excludeWeekend={true}
                                            placeholder={t('date.placeholder')}
                                            value={values.date}
                                            testId={`test-date`}
                                            minDate={today.clone().add(1, 'days').toISOString()}
                                            maxDate={contract?.endDate}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <TimeSlotSelection
                                            name={'time'}
                                            label={t('time.label')}
                                            placeholder={t('time.placeholder')}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <ValidatedInput
                                            name={'phoneNumber'}
                                            label={t('phone-number.label')}
                                            inputMode={'text'}
                                            testId={`test-phoneNumber`}
                                            placeholder={t('phone-number.placeholder')}
                                            isMandatory={true}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <ValidatedTextarea
                                            label={t('comment.label')}
                                            name={'comment'}
                                            testId={`test-comment`}
                                            maxLength={1000}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button
                                        secondary
                                        testId={'dashboard-button'}
                                        type="button"
                                        onClick={(): void => {
                                            history.push(dashboardPagePath());
                                        }}
                                    >
                                        {t('translation:editable-section-nav.dashboard')}
                                    </Button>
                                    <Button
                                        testId={'submit-button'}
                                        type="submit"
                                        onClick={(): void => {
                                            (!dirty || !isValid) && setMessageType(MessageType.VALIDATION_ERROR);
                                            submitForm();
                                        }}
                                    >
                                        {t('translation:editable-section-nav.send')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </ContentSection>
                    <Modal
                        shown={messageType === MessageType.ERROR}
                        status="error"
                        onClose={handleModalClose}
                        onCancel={handleModalClose}
                        onClickOutside={handleModalClose}
                        buttonCancelText={t('translation:editable-section-nav.back')}
                        testId={'error-modal'}
                    >
                        {t('modal-error.text')}
                    </Modal>
                    <Modal
                        shown={messageType === MessageType.SUCCESS}
                        status="success"
                        title={t('modal-success.headline')}
                        onClose={(): void => handleSuccessModalClose(resetForm)}
                        onConfirm={handleSuccessModalConfirm}
                        onClickOutside={(): void => handleSuccessModalClose(resetForm)}
                        buttonConfirmText={t('translation:editable-section-nav.dashboard')}
                        testId={'success-modal'}
                    >
                        <p dangerouslySetInnerHTML={{ __html: t('modal-success.text') }} />
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
