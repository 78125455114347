import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from '../../../cp-xhr';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { CategoryId, ContractInfo, getRequestSendingEndpoint, Request } from 'common';
import { initialValues, RequestForm } from './initialValues';
import { otherCategoryId, validationSchema } from './validationSchema';
import { ContractSelection } from './contract-selection/ContractSelection';
import { Button, ButtonContainer, ContentSection, Fieldset, Layout, Modal, Spinner } from '@vwfs-bronson/bronson-react';
import { CategorySelection } from './category-selection/CategorySelection';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../navigation/paths';
import { CategoryView } from './category-selection/category-view/CategoryView';

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    NONE = 'NONE',
}

export const FormView: React.FC<{
    contracts?: ContractInfo[];
}> = ({ contracts }) => {
    const { t } = useTranslation('request');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const history = useHistory();
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');

    if (!contracts) {
        return null;
    }

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = (values: RequestForm, { resetForm }: { resetForm: (...args: any[]) => any }) => {
        const category = values.categorySelection;
        if (!category) return;

        const request: Request = {
            contractId: values.contractSelection.contractId,
            categoryId: category as unknown as CategoryId,
            ...(category === otherCategoryId ? { requestTitle: values.requestTitle } : {}),
            requestDescription: values.requestDescription,
        };

        CpDataApi.post(getRequestSendingEndpoint(), request)
            .then(() => {
                onSuccess(`Category: ${category}`);
                setIsSubmitting(false);
                resetForm();
                setMessageType(MessageType.SUCCESS);
            })
            .catch(() => {
                onError(`Category: ${category}`);
                setIsSubmitting(false);
                setMessageType(MessageType.ERROR);
            });

        setIsSubmitting(true);
    };

    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSuccessModalClose = (resetForm: (...args: any[]) => any) => {
        resetForm({ values: initialValues });
        handleModalClose();
    };

    const handleSuccessModalConfirm = () => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t)}
            onSubmit={handleSubmit}
            validateOnBlur={true}
        >
            {({ values, submitForm, resetForm, dirty, isValid }) => (
                <Form onSubmit={(e) => e.preventDefault()} data-testid="request-form">
                    <ContentSection>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <ContractSelection
                                            contracts={contracts}
                                            resetForm={resetForm}
                                            currentContractNumber={values.contractSelection.contractNumber}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <CategorySelection
                                            key={values.contractSelection.contractNumber}
                                            contractSelection={values.contractSelection}
                                            resetForm={resetForm}
                                            currentCategorySelection={values.categorySelection}
                                            dirty={dirty}
                                        />
                                    </Layout.Item>
                                    <CategoryView categoryId={values.categorySelection} />
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button
                                        secondary
                                        testId={'dashboard-button'}
                                        type="button"
                                        onClick={(): void => {
                                            history.push(dashboardPagePath());
                                        }}
                                    >
                                        {t('translation:editable-section-nav.dashboard')}
                                    </Button>
                                    {isSubmitting ? (
                                        <Spinner small />
                                    ) : (
                                        <Button
                                            testId={'submit-button'}
                                            type="submit"
                                            onClick={() => {
                                                (!dirty || !isValid) && setMessageType(MessageType.VALIDATION_ERROR);
                                                submitForm();
                                            }}
                                        >
                                            {t('translation:editable-section-nav.send')}
                                        </Button>
                                    )}
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </ContentSection>
                    <Modal
                        shown={messageType === MessageType.ERROR}
                        status="error"
                        onClose={handleModalClose}
                        onCancel={handleModalClose}
                        onClickOutside={handleModalClose}
                        buttonCancelText={t('translation:editable-section-nav.back')}
                        testId={'error-modal'}
                    >
                        {t('modal-error.text')}
                    </Modal>
                    <Modal
                        shown={messageType === MessageType.SUCCESS}
                        status="success"
                        title={t('modal-success.headline')}
                        onClose={() => handleSuccessModalClose(resetForm)}
                        onConfirm={handleSuccessModalConfirm}
                        onClickOutside={() => handleSuccessModalClose(resetForm)}
                        buttonConfirmText={t('translation:editable-section-nav.dashboard')}
                        testId={'success-modal'}
                    >
                        {t('modal-success.text')}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
