import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const otherCategoryId = '11';

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        categorySelection: Yup.string().required(t('validation-error.category-missing')),
        requestTitle: Yup.string().when('categorySelection', {
            is: (val: string) => {
                return val && val === otherCategoryId;
            },
            then: Yup.string().trim().required(t('validation-error.title-missing')),
        }),
        requestDescription: Yup.string()
            .trim()
            .required(t('validation-error.description-missing'))
            .max(1000, t('validation-error.description-to-long')),
    });
};
