import React from 'react';
import { useContract } from 'components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { PartialEarlySettlementUi } from './ui';
import { useIsInvalidContractId } from 'utils';

export const PartialEarlySettlement: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { data: contract, isLoading, loadingError } = useContract(contractId);
    const isInvalidContractId = useIsInvalidContractId(isLoading, contract);

    const PartialEarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(PartialEarlySettlementUi);

    return (
        <PartialEarlySettlementWithHandlers
            isLoading={isLoading}
            contract={contract}
            hasError={!!loadingError || isInvalidContractId}
        />
    );
};
