import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { paymentDetailsPath } from 'components/navigation/paths';
import { formatAsCurrency } from '../../../../common';

export type UnpaidAlertNotificationProps = {
    contractId: string;
    totalUnpaidAmount?: number;
    paymentDetailsEntity?: number;
    paymentDetailsReference?: string;
    setUnpaid: (contractId: string, unpaid: boolean) => void;
};

export const UnpaidAlertNotificationUi: React.FC<UnpaidAlertNotificationProps> = ({
    contractId,
    totalUnpaidAmount,
    paymentDetailsEntity,
    paymentDetailsReference,
    setUnpaid,
}) => {
    const { t } = useTranslation('contracts');
    const history = useHistory();

    const shouldRender = contractId && totalUnpaidAmount && totalUnpaidAmount > 0;

    useEffect(() => {
        if (shouldRender) setUnpaid(contractId, true);
        else setUnpaid(contractId, false);
    }, [shouldRender, setUnpaid, contractId]);

    if (!shouldRender) {
        return null;
    }

    const handleClick = () => history.push(paymentDetailsPath(contractId));

    const notificationText = `${t('unpaid-alert.active.text', {
        totalUnpaidAmount: formatAsCurrency(totalUnpaidAmount),
    })} ${
        paymentDetailsEntity &&
        t('unpaid-alert.active.entity', {
            paymentDetailsEntity,
        })
    } ${
        paymentDetailsReference &&
        t('unpaid-alert.active.reference', {
            paymentDetailsReference,
        })
    }`;

    return (
        <Notification
            status={NotificationStatus.warning}
            headline={t('unpaid-alert.active.headline')}
            text={notificationText}
            testId={'active-contract-unpaid-alert'}
            className={'u-mb-small'}
        >
            <Button
                type={'button'}
                secondary
                className="u-bg-white u-mt "
                testId={'unpaid-alert-button'}
                onClick={handleClick}
            >
                {t('unpaid-alert.active.button')}
            </Button>
        </Notification>
    );
};
