import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import React from 'react';
import { CpDataApi } from '../../../../cp-xhr';
import { Base64Example } from '../ExampleData';
import { getAmortizationTableEndpoint } from '../../../../common';
import { ActiveFinanceContractWithAllValues, ActiveMaintenanceContractWithAllValues } from '../../ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: Base64Example,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const AmortizationTableMocks: EndpointMockDescription<DefaultBusinessMarketApiErrorCode>[] = [
    {
        name: 'Amortization Table Response',
        method: 'get',
        defaultMockOption: 'Success',
        mockOptions,
        url: getAmortizationTableEndpoint(ActiveFinanceContractWithAllValues.contractId),
    },
    {
        name: 'Amortization Table Response',
        method: 'get',
        defaultMockOption: 'Success',
        mockOptions,
        url: getAmortizationTableEndpoint(ActiveMaintenanceContractWithAllValues.contractId),
    },
];

export const AmortizationTableMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, AmortizationTableMocks);

    return <div>{storyFn()}</div>;
};
