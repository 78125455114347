import React from 'react';
import { Card, Button } from '@vwfs-bronson/bronson-react';
import { openInNewWindow, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { MarketingCard } from '../../../common';

export const MarketingCardUi: React.FC<{ marketingCard?: MarketingCard }> = ({ marketingCard }) => {
    const { onAction } = useAnalyticsActionTracker('onLearnMore');

    if (!marketingCard) {
        return null;
    }
    const { title, imageUrl, buttonUrl, buttonLabel, text } = marketingCard;
    return (
        <div style={{ height: 520 }}>
            <Card
                element="article"
                title={title || ''}
                imageSrc={imageUrl || ''}
                footer={true}
                buttons={[
                    <Button
                        key={buttonUrl || 'isLoading'}
                        link={true}
                        href={buttonUrl}
                        icon="semantic-forward"
                        iconReversed
                        small
                        onClick={() => {
                            openInNewWindow(buttonUrl);
                            onAction(title);
                        }}
                    >
                        {buttonLabel}
                    </Button>,
                ]}
            >
                {text}
            </Card>
        </div>
    );
};
