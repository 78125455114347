import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ButtonContainer,
    Fieldset,
    Form,
    Heading,
    RadioButtonGroup,
    RadioButton,
} from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsFormTracker,
} from '@cp-shared-8/frontend-ui';
import { AvailablePaymentDays, EditStatus, getPaymentDayEndpoint, UpdatePaymentDay } from '../../../../../../common';
import { CpDataApi } from '../../../../../../cp-xhr';

export type EditViewProps = {
    monthlyDueDay?: number;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus) => void;
    contractId: string;
};

export const EditView: React.FC<EditViewProps> = ({ monthlyDueDay, cancelEditing, finishEditing, contractId }) => {
    const { t } = useTranslation('financial-details');
    const translationPrefix = 'payment-day-section.edit-view';
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        onError: trackOnError,
        onSuccess: trackOnSuccess,
        onTyping,
    } = useAnalyticsFormTracker({
        confirmError: 'financialDetailsEditDueDateSectionSubmitFailed',
        confirmSuccess: 'financialDetailsEditDueDateSectionSuccess',
        startTyping: 'financialDetailsEditDueDateSectionStartTyping',
    });

    const initialValues: UpdatePaymentDay = {
        paymentDay: monthlyDueDay || 5,
    };

    const onSubmit = ({ paymentDay }: { paymentDay: number }) => {
        if (paymentDay === monthlyDueDay) {
            finishEditing(EditStatus.WARNING);
            trackOnError();
            return;
        }
        setIsSubmitting(true);
        CpDataApi.put(getPaymentDayEndpoint(contractId), { paymentDay })
            .then(() => {
                finishEditing(EditStatus.SUCCESS);
                trackOnSuccess();
            })
            .catch(() => {
                finishEditing(EditStatus.ERROR);
                trackOnError();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div className={'c-labeled-content-section u-pt-none'}>
            <Heading className={`c-data-overview__header u-mt-xsmall u-mb-small`} level={'6'}>
                {t(`${translationPrefix}.headline`)}
                <button
                    className="c-icon c-btn__icon  c-icon--[semantic-crossed-out] u-pb-small u-pr-small"
                    onClick={cancelEditing}
                    id={'x-button'}
                />
            </Heading>
            <UiBlockingSpinner isBlocking={isSubmitting}>
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {(formik) => (
                        <Form
                            data-testid="payment-day-form"
                            onSubmit={preventSubmit}
                            onChange={() => {
                                onTyping(formik.errors, formik.touched);
                            }}
                        >
                            <Fieldset>
                                <Fieldset.Row>
                                    <p>{t(`${translationPrefix}.radio-button-group-label`)}</p>
                                    <div className="u-pr">
                                        <RadioButtonGroup>
                                            {AvailablePaymentDays.map((item: number, index: number) => (
                                                <RadioButton
                                                    key={index}
                                                    testId={`radio-button-${item}`}
                                                    name="paymentDay"
                                                    value={item}
                                                    defaultChecked={monthlyDueDay === item}
                                                    onChange={() => formik.setFieldValue('paymentDay', item)}
                                                >
                                                    <div>{item}</div>
                                                </RadioButton>
                                            ))}
                                        </RadioButtonGroup>
                                    </div>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Notification
                                        status={NotificationStatus.info}
                                        text={t(`${translationPrefix}.notification.info.text`)}
                                        testId={'notification-info'}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button secondary onClick={cancelEditing} testId="edit-payment-day-cancel">
                                            {t('translation:editable-section-nav.back')}
                                        </Button>
                                        <Button onClick={formik.submitForm} testId="submit">
                                            {t(`${translationPrefix}.submit-button`)}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </UiBlockingSpinner>
        </div>
    );
};
