import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { phoneValidator } from 'utils';
import { RequestAppointmentForm } from './initialValues';
import { getDealersForBrand } from 'common';

export const validationSchema = (t: TFunction, brand: string): Yup.SchemaOf<RequestAppointmentForm> => {
    return Yup.object()
        .shape({
            dealer: Yup.string()
                .trim()
                .required(t('validation-error.dealer-missing'))
                .test('validDealer', t('validation-error.dealer-invalid'), (value?: string): boolean => {
                    return getDealersForBrand(brand).some((dealer: string) => dealer === value);
                }),
            mileage: Yup.number()
                .required(t('validation-error.mileage-missing'))
                .moreThan(0, t('validation-error.mileage-missing')),
            reason: Yup.string()
                .trim()
                .required(t('validation-error.reason-missing'))
                .max(1000, t('validation-error.reason-to-long')),
            date: Yup.string().trim().required(t('validation-error.date-missing')),
            time: Yup.string().trim().required(t('validation-error.time-missing')),
            phoneNumber: Yup.string()
                .trim()
                .required(t('validation-error.phone-number-missing'))
                .test('is valid phone number', t('validation-error.phone-number-invalid'), (value?: string) =>
                    phoneValidator(value),
                ),
            comment: Yup.string().trim().max(1000, t('validation-error.comment-to-long')),
            files: Yup.array().min(0, t('validation-error.files-missing')).max(1, t('validation-error.files-to-many')),
        })
        .defined();
};
