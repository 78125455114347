import { Button, ButtonContainer, Heading, Paragraph } from '@vwfs-bronson/bronson-react';
import { saveAs } from 'file-saver';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ContractHandover: React.FC = () => {
    const { t } = useTranslation('contract-handover');

    return (
        <>
            <Heading level={3}>{t('entry-point.subtitle')}</Heading>
            <Paragraph className={'u-mb'}>{t('entry-point.text')}</Paragraph>
            <ButtonContainer center>
                <Button
                    secondary
                    onClick={() => {
                        saveAs('/HandoverCompany.pdf', 'Cessão_de_Posição_Contratual_para_Empresa');
                    }}
                    testId="company-form-button"
                >
                    {t('entry-point.buttons.company-form')}
                </Button>
                <Button
                    onClick={() => {
                        saveAs('/HandoverParticular.pdf', 'Cessão-de_Posição_Contratual_para_Particular');
                    }}
                    testId="private-form-button"
                >
                    {t('entry-point.buttons.private-form')}
                </Button>
            </ButtonContainer>
        </>
    );
};
