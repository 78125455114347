import React, { useState } from 'react';
import { Button, ButtonContainer, DataOverview, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { CpDataApi } from 'cp-xhr';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
    preventSubmit,
    ValidatedInput,
    withAutoScroll,
    UiBlockingSpinner,
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { EditStatus, ContactDetails, getContactEndpoint } from 'common';
import { validationSchema } from './validationSchema';

type EditViewProps = {
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedContactDetails?: ContactDetails) => void;
    contactDetails?: ContactDetails;
};

type FormValues = {
    email: string;
    mobilePhone: string;
    otherPhone: string;
};

export const EditView: React.FC<EditViewProps> = withAutoScroll(
    ({ cancelEditing, finishEditing, contactDetails = {} }) => {
        const [isSubmitting, setIsSubmitting] = useState(false);

        const { t } = useTranslation('my-profile');
        const translationPrefix = 'contact-details.edit.form-view';

        const { onAction: onStartTyping } = useAnalyticsActionTracker('onEditProfileContactTypedIn');
        const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileContactValidationError');
        const { onAction: onFail } = useAnalyticsActionTracker('onEditProfileContactSubmitFailed');
        useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, 'Contact');

        const { email = '', mobilePhone = '', otherPhone = '' } = contactDetails;

        const initialValues: FormValues = {
            email,
            mobilePhone,
            otherPhone,
        };

        const onSubmit = (values: FormValues) => {
            setIsSubmitting(true);
            CpDataApi.put(getContactEndpoint(), values)
                .then(() => {
                    finishEditing(EditStatus.SUCCESS, values);
                })
                .catch(() => {
                    onFail();
                    finishEditing(EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        return (
            <DataOverview title={t(`${translationPrefix}.headline`)}>
                <Notification
                    status={NotificationStatus.info}
                    text={t(`contact-details.consult-view.info-alert`)}
                    testId={'contact-details-change-info'}
                    className="u-mb"
                />
                <Formik initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={onSubmit}>
                    {({ submitForm, errors, touched }) => (
                        <Form
                            onSubmit={preventSubmit}
                            data-testid="edit-form"
                            onChange={() => {
                                onStartTyping();
                            }}
                        >
                            <UiBlockingSpinner isBlocking={isSubmitting}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ValidatedInput
                                            label={t(`${translationPrefix}.email.label`)}
                                            tooltip={t(`${translationPrefix}.email.tooltip`)}
                                            name="email"
                                            testId="email"
                                            type="email"
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.mobile-phone.label`)}
                                                    tooltip={t(`${translationPrefix}.mobile-phone.tooltip`)}
                                                    name="mobilePhone"
                                                    testId="mobilePhone"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/2" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.other-phone.label`)}
                                                    name="otherPhone"
                                                    testId="otherPhone"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer center>
                                            <Button
                                                secondary
                                                onClick={cancelEditing}
                                                testId="cancel-button"
                                                type="button"
                                            >
                                                {t(`${translationPrefix}.cancel`)}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    if (isEmpty(touched) && isEmpty(errors))
                                                        onValidationError({ contact: 'contact' });
                                                    if (!isEmpty(errors)) onValidationError(errors);
                                                    submitForm();
                                                }}
                                                testId="submit-button"
                                                type="submit"
                                            >
                                                {t(`${translationPrefix}.submit`)}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </UiBlockingSpinner>
                        </Form>
                    )}
                </Formik>
            </DataOverview>
        );
    },
    'ContactEditView',
);
