import { SimplePage, getLegalNoticeEndpoint } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePage>({
    contentName: 'legalNotice',
    contentEndpoint: getLegalNoticeEndpoint,
});

export default reducer;
export const fetchLegalNotice = fetchContent;
