import { MyDocument } from 'common';
import { formatCpDate } from '@cp-shared-8/common-utilities';

export const InvoiceDocumentWithAllValues: MyDocument = {
    creationDate: formatCpDate('2021-07-27').toCpDate(),
    group: 'invoice',
    documentType: 'INVOICE',
    documentTypeAbbrev: 'Invoice Document',
    documentName: 'invoice123.pdf',
    contractNumber: '11234569',
    invoiceAmount: 123.45,
    _downloadLink: '/postbox-document-123-download',
};

export const EntityDocumentWithAllValues: MyDocument = {
    creationDate: formatCpDate('2020-03-06').toCpDate(),
    group: 'entity',
    documentType: 'FINANCIAL_PLAN',
    documentTypeAbbrev: 'VWB - Factura/ND/NC',
    documentName: 'financial_plan_2020.pdf',
    contractNumber: '11234569',
    _downloadLink: '/postbox-document-123-download',
};

export const ContractDocumentWithAllValues: MyDocument = {
    creationDate: formatCpDate('1990-01-08').toCpDate(),
    group: 'contract',
    documentType: 'AVISO_10',
    documentTypeAbbrev: 'VW - Factura/ND/NC2',
    documentName: 'my_aviso_10.pdf',
    contractNumber: '31234572',
    _downloadLink: '/postbox-document-123-download',
};

export const InvoiceDocumentWithoutContractNumber: MyDocument = {
    ...InvoiceDocumentWithAllValues,
    contractNumber: undefined,
};

export const EntityDocumentWithoutContractNumber: MyDocument = {
    ...EntityDocumentWithAllValues,
    contractNumber: undefined,
};

export const ContractDocumentWithoutContractNumber: MyDocument = {
    ...ContractDocumentWithAllValues,
    contractNumber: undefined,
};

export const DocumentsWithAllValues: MyDocument[] = [
    InvoiceDocumentWithAllValues,
    EntityDocumentWithAllValues,
    ContractDocumentWithAllValues,
];

export const DocumentsWithoutContractNumber: MyDocument[] = [
    InvoiceDocumentWithoutContractNumber,
    EntityDocumentWithoutContractNumber,
    ContractDocumentWithoutContractNumber,
];
