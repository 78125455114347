import React, { useMemo, useState } from 'react';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { DATE_FORMAT } from 'common';
import {
    CleaveInput,
    InfoCheckbox,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
} from '@cp-shared-8/frontend-ui';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { RegistrationErrorNotification } from '../../registration-error';
import { useRegistrationSubmission } from '../useRegistrationSubmission';
import { dashboardPagePath } from '../../../navigation/paths';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema';
import { useTranslation } from 'react-i18next';
import { CheckNumberType } from './enums';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

export const testIds: { [key: string]: string } = {
    checkNumber: 'checkNumber',
    fiscalNumber: 'fiscal-number',
    dateOfBirth: 'date-of-birth',
    confirmPrivacyPolicy: 'confirm-privacy-policy',
    confirmLegalNotice: 'confirm-legal-notice',
    submitButton: 'submit-registration-button',
};

type RegistrationFormFields = {
    checkNumber: string;
    fiscalNumber: string;
    dateOfBirth: string;
    confirmPrivacyPolicy: boolean;
    confirmTermsAndConditions: boolean;
};

type RegistrationFormProps = {
    privacyPolicy: string;
    legalNotice: string;
};

export const RegistrationForm: React.FC<RegistrationFormProps> = ({ privacyPolicy, legalNotice }) => {
    const { t } = useTranslation('registration');
    const history = useHistory();

    const [checkNumberType, setCheckNumberType] = useState<CheckNumberType>(CheckNumberType.INVITATION_CODE);

    const [lastTouchedField, setLastTouchedField] = useState<string>();
    const [touched, setTouched] = useState(false);

    const { onAction: trackOnBeforeUnload } = useAnalyticsActionTracker('onConfirmIdentityAborted');

    window.onbeforeunload = () => {
        trackOnBeforeUnload(lastTouchedField);
    };

    const { onError: trackOnError, onSuccess: trackOnSuccess } = useAnalyticsFormTracker({
        confirmError: 'onConfirmIdentiyAuthFailed',
        confirmSuccess: 'onConfirmIdentiyAuthSuccess',
    });

    const { onAction: onTyping } = useAnalyticsActionTracker('onStartTypingConfirmIdentity');
    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');

    const onSubmissionSuccess = useMemo(
        () => (): void => {
            trackOnSuccess();
            history.push(dashboardPagePath());
        },
        [trackOnSuccess, history],
    );

    const onSubmissionError = useMemo(
        () => (): void => {
            trackOnError();
        },
        [trackOnError],
    );

    const { submit, isLoading, errorCode } = useRegistrationSubmission(onSubmissionSuccess, onSubmissionError);

    const onSubmit = (values: RegistrationFormFields) => {
        const { checkNumber, fiscalNumber, dateOfBirth } = values;
        submit({ checkNumber, fiscalNumber, dateOfBirth: formatCpDate(dateOfBirth, DATE_FORMAT).toCpDate() });
    };

    const initialValues: RegistrationFormFields = {
        checkNumber: '',
        fiscalNumber: '',
        dateOfBirth: '',
        confirmPrivacyPolicy: false,
        confirmTermsAndConditions: false,
    };

    const toggledTranslation = checkNumberType === CheckNumberType.INVITATION_CODE ? 'invitation-code' : 'iban';

    const labels = {
        checkNumber: `${t(`input-labels.check-number.${toggledTranslation}.label`)} `,
        dateOfBirth: {
            label: `${t(`input-labels.date-of-birth.label`)} `,
            tooltip: t(`input-labels.date-of-birth.tooltip`),
        },
        fiscalNumber: `${t(`input-labels.fiscal-number`)} `,
        confirmPrivacyPolicy: {
            linkLabel: t(`input-labels.agreements.privacy-policy.link`),
            preLinkLabel: t(`input-labels.agreements.privacy-policy.label`),
        },
        confirmTermsAndConditions: {
            linkLabel: t(`input-labels.agreements.legal-notice.link`),
            preLinkLabel: t(`input-labels.agreements.legal-notice.label`),
        },
    };

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLinkClick = (resetForm: (...args: any[]) => any, values: RegistrationFormFields) => {
        checkNumberType === CheckNumberType.INVITATION_CODE
            ? setCheckNumberType(CheckNumberType.IBAN)
            : setCheckNumberType(CheckNumberType.INVITATION_CODE);
        resetForm({ ...values, checkNumber: '' });
    };

    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const initialListErrors = 'checkNumber, dateOfBirth, fiscalNumber, confirmTermsAndConditions, confirmPrivacyPolicy';

    const startOnTyping = (): void => {
        if (!touched && lastTouchedField) {
            onTyping();
            setTouched(true);
        }
    };

    return (
        <>
            {isLoading && <Spinner fullPage={true} />}
            <Layout.Item default={'1/1'}>
                <RegistrationErrorNotification errorCode={errorCode} className={'u-mb'} />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema(t, checkNumberType)}
                    onSubmit={onSubmit}
                    onChange={startOnTyping()}
                >
                    {(formik) => (
                        <Form onSubmit={preventSubmit}>
                            <Fieldset>
                                <Fieldset.Row className={'u-pb-xsmall'}>
                                    <CleaveInput
                                        cleaveOptions={{
                                            numericOnly: true,
                                            blocks: [5],
                                        }}
                                        label={labels.checkNumber}
                                        name={'checkNumber'}
                                        testId={testIds.checkNumber}
                                        onFocus={() => setLastTouchedField(labels.checkNumber)}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row className={'u-text-right'}>
                                    <Button
                                        link
                                        onClick={() => handleLinkClick(formik.resetForm, formik.values)}
                                        testId={'toggle-link'}
                                    >
                                        {t(`input-labels.check-number.${toggledTranslation}.link`)}
                                    </Button>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <CleaveInput
                                        cleaveOptions={{
                                            delimiter: '-',
                                            blocks: [2, 2, 4],
                                            numericOnly: true,
                                        }}
                                        inputMode={'numeric'}
                                        label={labels.dateOfBirth.label}
                                        tooltip={labels.dateOfBirth.tooltip}
                                        name={'dateOfBirth'}
                                        placeholder={t(`input-labels.date-of-birth.placeholder`)}
                                        testId={testIds.dateOfBirth}
                                        onFocus={() => setLastTouchedField(labels.dateOfBirth.label)}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <CleaveInput
                                        cleaveOptions={{
                                            numericOnly: true,
                                            blocks: [9],
                                        }}
                                        label={labels.fiscalNumber}
                                        name={'fiscalNumber'}
                                        testId={testIds.fiscalNumber}
                                        onFocus={() => setLastTouchedField(labels.fiscalNumber)}
                                    />
                                </Fieldset.Row>
                                <InfoCheckbox
                                    preLinkLabel={labels.confirmPrivacyPolicy.preLinkLabel}
                                    linkLabel={labels.confirmPrivacyPolicy.linkLabel}
                                    fieldName={'confirmPrivacyPolicy'}
                                    testId={testIds.confirmPrivacyPolicy}
                                    onFocus={() =>
                                        setLastTouchedField(
                                            `${labels.confirmPrivacyPolicy.preLinkLabel}${labels.confirmPrivacyPolicy.linkLabel}`,
                                        )
                                    }
                                >
                                    <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
                                </InfoCheckbox>
                                <InfoCheckbox
                                    preLinkLabel={labels.confirmTermsAndConditions.preLinkLabel}
                                    linkLabel={labels.confirmTermsAndConditions.linkLabel}
                                    fieldName={'confirmTermsAndConditions'}
                                    testId={testIds.confirmLegalNotice}
                                    onFocus={() =>
                                        setLastTouchedField(
                                            `${labels.confirmTermsAndConditions.preLinkLabel}${labels.confirmTermsAndConditions.linkLabel}`,
                                        )
                                    }
                                >
                                    <div dangerouslySetInnerHTML={{ __html: legalNotice }} />
                                </InfoCheckbox>
                                <Fieldset.Row>
                                    <Button
                                        full={true}
                                        onClick={() => {
                                            formik.submitForm();
                                            if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                                const errorToString = getErrors(formik.errors).toString();
                                                if (!errorToString) {
                                                    onAction(initialListErrors);
                                                } else onAction(getErrors(formik.errors));
                                            }
                                        }}
                                        testId={testIds.submitButton}
                                    >
                                        {t(`confirm-button`)}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
        </>
    );
};
