import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { AccordionItem } from '@cp-shared-8/frontend-ui';
import { ContractPartiesUi } from './ui';
import {
    ContractParties as ContractPartiesType,
    ContractDetailsData,
    isErrorDetailsData,
    isSuccessDetailsData,
} from '../../../../common';
import { withNoConnectionHandler } from '../../../integration-wrapper';

type ContractPartiesProps = { contractParties?: ContractDetailsData<ContractPartiesType> };

export const ContractParties: React.FC<ContractPartiesProps> = ({ contractParties }) => {
    const { t } = useTranslation('contract-parties');

    if (!contractParties || (isSuccessDetailsData(contractParties) && isEmpty(contractParties.data))) return null;

    const hasError = !!contractParties && isErrorDetailsData(contractParties);

    const ContractPartiesUiWithHandlers = withNoConnectionHandler(ContractPartiesUi);

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <ContractPartiesUiWithHandlers contractPartiesData={contractParties?.data} hasError={hasError} />
        </AccordionItem>
    );
};
