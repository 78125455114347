import { AbstractDataState, useGetContractBasedApiData } from '@cp-shared-8/frontend-integration';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { fetchVehicleDetails } from './VehicleDetailsSlice';
import { VehicleDetailsDataSelector } from './VehicleDetailsDataSelector';
import { getVehicleServiceEndpoint, VehicleServiceResponse } from '@cp-shared-8/apis';

export function useVehicleDetails(
    encryptedVin: string,
): AbstractDataState<VehicleServiceResponse, DefaultBusinessMarketApiErrorCode> {
    return useGetContractBasedApiData(
        encryptedVin,
        fetchVehicleDetails,
        VehicleDetailsDataSelector,
        getVehicleServiceEndpoint(encryptedVin),
    );
}
