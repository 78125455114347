import { useState, useEffect } from 'react';
import { Contract } from 'common';

export const useIsInvalidContractId = (isLoading: boolean, contract: Contract | undefined) => {
    const [contractsLoadingStarted, setContractsLoadingStarted] = useState<boolean>();
    const [isInvalidContractId, setIsInvalidContractId] = useState<boolean>(false);

    useEffect(() => {
        if (isLoading) {
            setContractsLoadingStarted(true);
        }
        if (contractsLoadingStarted && !isLoading) {
            setIsInvalidContractId(!contract);
        }
    }, [isLoading, contractsLoadingStarted, contract]);

    return isInvalidContractId;
};
