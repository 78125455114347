import React from 'react';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { FinancialDetails } from '../../../../../common';
import { Hr } from '@vwfs-bronson/bronson-react';

type GeneralSectionProps = {
    details?: FinancialDetails;
    isActive: boolean;
};

export const GeneralSection: React.FC<GeneralSectionProps> = ({ details, isActive }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');

    if (!details) {
        return null;
    }

    const { startDate, endDate, lastTerm, pendingInstalments } = details;
    const allValuesUndefined =
        (isActive && !startDate && !endDate && !lastTerm && !pendingInstalments) ||
        (!isActive && !startDate && !endDate);

    if (allValuesUndefined) {
        return null;
    }

    const noValuePlaceholder = '-';

    const activeContractItemList: DefinitionListItem[] = isActive
        ? [
              {
                  label: t('general-section.last-term'),
                  value: lastTerm || noValuePlaceholder,
                  testId: 'last-term',
              },
              {
                  label: t('general-section.pending-instalments'),
                  value: pendingInstalments || noValuePlaceholder,
                  testId: 'pending-instalments',
              },
          ]
        : [];

    const itemList: DefinitionListItem[] = [
        {
            label: t('general-section.start-date'),
            value: startDate ? f(startDate, TranslationFormat.DATE) : noValuePlaceholder,
            testId: 'start-date',
        },
        {
            label: t('general-section.end-date'),
            value: endDate ? f(endDate, TranslationFormat.DATE) : noValuePlaceholder,
            testId: 'end-date',
        },
        ...activeContractItemList,
    ];

    return (
        <>
            <DefinitionListHorizontal list={itemList} />
            <Hr className="u-mb-none" />
        </>
    );
};
