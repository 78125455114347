import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { EditStatus } from 'common';

type SubmitStatusNotificationProps = {
    lastEditStatus?: EditStatus;
    section: 'identification' | 'address' | 'contact';
};

export const SubmitStatusNotification: React.FC<SubmitStatusNotificationProps> = ({ lastEditStatus, section }) => {
    const { t } = useTranslation('my-profile');

    const getSectionPrefix = () => {
        switch (section) {
            case 'identification':
                return 'identification';
            case 'address':
                return 'addresses';
            case 'contact':
                return 'contact-details';
        }
    };
    const translationPrefix = `${getSectionPrefix()}.edit.notification`;

    switch (lastEditStatus) {
        case EditStatus.SUCCESS: {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t(`${translationPrefix}.submit-success.headline`)}
                    text={t(`${translationPrefix}.submit-success.text`)}
                    testId={'submit-success-notification'}
                />
            );
        }
        case EditStatus.ERROR: {
            return (
                <Notification
                    status={NotificationStatus.error}
                    text={t(`${translationPrefix}.submit-error.text`)}
                    testId={'submit-error-notification'}
                />
            );
        }
        default: {
            return null;
        }
    }
};
