export type InitialValues = {
    amount: string;
    requestDate: string;
    option: string;
    contractAmendment: File[];
    paymentReceipt: File[];
};

export const getInitialValues = (): InitialValues => ({
    amount: '',
    requestDate: '',
    option: '',
    contractAmendment: [],
    paymentReceipt: [],
});
