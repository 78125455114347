import { CPDate } from '@cp-shared-8/common-utilities';

export enum PartialEarlySettlementOptions {
    REDUCE_TERMS = 'REDUCE_TERMS',
    REDUCE_INSTALLMENT = 'REDUCE_INSTALLMENT',
    BOTH = 'BOTH',
}

export type PartialEarlySettlement = {
    requestDate: CPDate;
    amount: number;
    option: PartialEarlySettlementOptions;
    fileIds: string[];
};
