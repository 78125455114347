import { useMemo, useState } from 'react';
import { Registration, RegistrationError, getRegistrationEndpoint } from 'common';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-8/common-utilities';
import { CpDataApi } from 'cp-xhr';

type RegistrationSubmissionProps = {
    submit: (values: Registration) => void;
};

type RegistrationRequestProps = {
    isLoading: boolean;
    errorCode?: WithDefaultCpIntegrationErrors<RegistrationError>;
};

export const useRegistrationSubmission = (
    onSuccess: () => void,
    onError: () => void,
): RegistrationSubmissionProps & RegistrationRequestProps => {
    const [registrationRequest, setRegistrationRequest] = useState<RegistrationRequestProps>({
        isLoading: false,
        errorCode: undefined,
    });
    const submit = useMemo(
        () =>
            (values: Registration): void => {
                setRegistrationRequest({ errorCode: undefined, isLoading: true });
                CpDataApi.post(getRegistrationEndpoint(), values)
                    .then(() => {
                        setRegistrationRequest({
                            errorCode: undefined,
                            isLoading: false,
                        });
                        onSuccess();
                    })
                    .catch((error) => {
                        const errorCode = parseErrorResponse<RegistrationError>(error).code;
                        setRegistrationRequest({
                            isLoading: false,
                            errorCode,
                        });
                        onError();
                    });
            },
        [onSuccess, onError],
    );
    return { submit, ...registrationRequest };
};
