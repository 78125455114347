import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FormField, Combobox } from '@vwfs-bronson/bronson-react';
import { getDealersForBrand } from 'common';
import { ComboboxOption } from 'types/bronson-react';

type DealerSelectionProps = {
    currentBrand: string;
    name: string;
    testId: string;
    currentDealerSelection: string;
    label?: string;
    isMandatory?: boolean;
};

export const DealerSelection: React.FC<DealerSelectionProps> = ({
    currentBrand,
    name,
    testId,
    currentDealerSelection,
    label,
    isMandatory = true,
}) => {
    const [value, setValue] = useState<string>(currentDealerSelection);
    const [filteredDealersForBrand, setFilteredDealersForBrand] = useState<string[]>([]);

    const dealersForBrand = useMemo(() => getDealersForBrand(currentBrand), [currentBrand]);

    useEffect(() => {
        setFilteredDealersForBrand(dealersForBrand);
    }, [dealersForBrand]);

    const setValueChange = useCallback((selectedPaymentDay: ComboboxOption[]): void => {
        return setValue(selectedPaymentDay?.[0]?.value);
    }, []);

    const options = filteredDealersForBrand.map((dealer, index) => {
        return (
            <Combobox.Item
                testId={`dealer-selection-item-${index}`}
                optionValue={dealer}
                optionKey={dealer}
                key={`${dealer}${index}`}
            >
                <>{dealer}</>
            </Combobox.Item>
        );
    });

    return (
        <FormField labelText={label} notion={isMandatory} type="input">
            <Combobox name={name} searchable testId={testId} placeholder={value} onChange={setValueChange}>
                {options}
            </Combobox>
        </FormField>
    );
};
