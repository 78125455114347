import { dateFormatPt } from '../../../../common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { TFunction } from 'i18next';
import { dotFormattedStringToNumber } from 'utils';
import * as Yup from 'yup';

export const getToday = () => formatCpDate().toMoment();

export const validationSchema = (t: TFunction, language: string, outstandingBalance: number) => {
    const translationPrefix = 'partial-early-settlement';
    return Yup.object().shape({
        amount: Yup.string()
            .required(t(`${translationPrefix}.form.amount.validation.required`))
            .test(
                'max value',
                t(`${translationPrefix}.form.amount.validation.max-value`),
                (value) => !!value && dotFormattedStringToNumber(value) <= outstandingBalance,
            ),

        requestDate: Yup.string()
            .required(t(`${translationPrefix}.form.request-date.validation.required`))
            .test('min date', t(`${translationPrefix}.form.request-date.validation.min-date`), (date) =>
                formatCpDate(date, language === 'pt' ? dateFormatPt : 'DD/MM/YYYY')
                    .toMoment()
                    .isAfter(getToday().startOf('day')),
            ),
        option: Yup.string().required(t(`${translationPrefix}.form.option.validation.required`)),
        paymentReceipt: Yup.array().max(2, t(`${translationPrefix}.form.payment-receipt.validation.max-files-number`)),
    });
};
