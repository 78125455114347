import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { dotFormattedStringToNumber } from 'utils';
import { MAX_MILEAGE } from '../../../../../../common';

const isEqualOrUnderTheLimit = (value: string | undefined): boolean =>
    !!value && dotFormattedStringToNumber(value) <= MAX_MILEAGE;

export const validationSchema = (t: TFunction) => {
    const translationPrefix = 'mileage-section.edit-view';
    return Yup.object().shape({
        newMileage: Yup.string()
            .required(t(`${translationPrefix}.new-mileage.validation.required`))
            .test('wrongValue', t(`${translationPrefix}.new-mileage.validation.wrong-value`), (value) =>
                isEqualOrUnderTheLimit(value),
            ),
        currentMileage: Yup.string()
            .required(t(`${translationPrefix}.current-mileage.validation.required`))
            .test('wrongValue', t(`${translationPrefix}.current-mileage.validation.wrong-value`), (value) =>
                isEqualOrUnderTheLimit(value),
            ),
    });
};
