export type ContractSelection = {
    isActive: boolean;
    contractId: string;
    contractNumber: string;
    productTypeEN: string;
};

export type RequestForm = {
    contractSelection: ContractSelection;
    categorySelection: string;
    requestTitle: string;
    requestDescription: string;
};

export const initialValues: RequestForm = {
    contractSelection: {
        isActive: true,
        contractId: '',
        contractNumber: 'General request',
        productTypeEN: '',
    },
    categorySelection: '',
    requestTitle: '',
    requestDescription: '',
};
