import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DATE_FORMAT } from 'common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { CheckNumberType } from './enums';

export const errorMessageIds = {
    checkNumber: {
        invitationCode: {
            required: 'validation-errors.check-number.invitation-code.required',
            minLength: 'validation-errors.check-number.invitation-code.min-length',
        },
        iban: {
            required: 'validation-errors.check-number.iban.required',
            minLength: 'validation-errors.check-number.iban.min-length',
        },
    },
    dateOfBirth: {
        required: 'validation-errors.date-of-birth.required',
        format: 'validation-errors.date-of-birth.format',
    },
    fiscalNumber: {
        required: 'validation-errors.fiscal-number.required',
        minlength: 'validation-errors.fiscal-number.min-length',
    },
    privacyPolicy: 'validation-errors.privacy-policy',
    legalNotice: 'validation-errors.legal-notice',
};

export const validationSchema = (t: TFunction, checkNumberType: CheckNumberType) => {
    const checkNumberTranslationPrefix =
        checkNumberType === CheckNumberType.INVITATION_CODE
            ? errorMessageIds.checkNumber.invitationCode
            : errorMessageIds.checkNumber.iban;
    return Yup.object().shape({
        checkNumber: Yup.string()
            .trim()
            .required(t(checkNumberTranslationPrefix.required))
            .min(5, t(checkNumberTranslationPrefix.minLength)),
        dateOfBirth: Yup.string()
            .required(t(errorMessageIds.dateOfBirth.required))
            .test('format', t(errorMessageIds.dateOfBirth.format), (date) => {
                const newDate = formatCpDate(date, DATE_FORMAT);
                return newDate.isValid();
            }),
        fiscalNumber: Yup.string()
            .trim()
            .required(t(errorMessageIds.fiscalNumber.required))
            .min(9, t(errorMessageIds.fiscalNumber.minlength)),
        confirmTermsAndConditions: Yup.bool().oneOf([true], t(errorMessageIds.legalNotice)),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], t(errorMessageIds.privacyPolicy)),
    });
};
