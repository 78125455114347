import React from 'react';
import { ContentSection, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Greeting } from 'components/greeting';
import { Contracts } from '../../components/contracts';
import { DashboardMarketingCard } from '../../components/dashboard-marketing-card';
import { DashboardMarketingCardInsurance } from '../../components/dashboard-marketing-card-insurance';
import { Carousel } from '@cp-shared-8/frontend-ui';
import { FlickityOptions } from 'react-flickity-component';

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation('dashboard');
    const title = t('headline');

    const options: FlickityOptions = {
        adaptiveHeight: false,
        autoPlay: 10000,
    };

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <Greeting />
            <Layout>
                <Layout.Item default={'2/3'} m={'1/1'}>
                    <Contracts />
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    <Carousel options={options}>
                        <DashboardMarketingCardInsurance />
                        <DashboardMarketingCard />
                    </Carousel>
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
