import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const validationSchema = (t: TFunction) => {
    const translationPrefix = 'addresses.edit.form-view';
    return Yup.object().shape({
        roadType: Yup.string()
            .trim()
            .max(20, t(`${translationPrefix}.road-type.error`)),
        streetName: Yup.string()
            .trim()
            .max(60, t(`${translationPrefix}.street-name.error`)),
        houseNumber: Yup.string()
            .trim()
            .max(10, t(`${translationPrefix}.house-number.error`)),
        block: Yup.string()
            .trim()
            .max(10, t(`${translationPrefix}.block.error`)),
        floor: Yup.string()
            .trim()
            .max(10, t(`${translationPrefix}.floor.error`)),
        door: Yup.string()
            .trim()
            .max(5, t(`${translationPrefix}.door.error`)),
        place: Yup.string()
            .trim()
            .max(50, t(`${translationPrefix}.place.error`)),
        zipCode: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.zip-code.error`))
            .max(10, t(`${translationPrefix}.zip-code.error`)),
        localityCity: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.locality-city.error`))
            .max(40, t(`${translationPrefix}.locality-city.error`)),
        country: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.country.error`))
            .max(40, t(`${translationPrefix}.country.error`)),
    });
};
