import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { ValidatedInput, ValidatedTextarea } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { otherCategoryId } from '../../validationSchema';

export type CategoryViewProps = {
    categoryId: string;
};

export const CategoryView: React.FC<CategoryViewProps> = ({ categoryId }) => {
    const { t } = useTranslation('request');
    if (!categoryId) return null;

    return (
        <>
            {categoryId === otherCategoryId && (
                <Layout.Item>
                    <ValidatedInput
                        label={`${t('title.label')} *`}
                        name={'requestTitle'}
                        inputMode={'text'}
                        testId={`test-requestTitle`}
                        placeholder={t('title.placeholder')}
                        maxLength={40}
                    />
                </Layout.Item>
            )}
            <Layout.Item>
                <ValidatedTextarea
                    label={`${t('description.label')} *`}
                    name={'requestDescription'}
                    testId={`test-requestDescription`}
                    placeholder={t('title.placeholder')}
                    maxLength={1000}
                />
            </Layout.Item>
        </>
    );
};
