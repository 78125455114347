import { CPDate } from '@cp-shared-8/common-utilities';

export enum InterestRateType {
    FIXED = 'Fixed',
    VARIABLE = 'Variable',
}

export enum IndexDescriptionType {
    EUR3M = 'EUR3M',
}

export type FinancialDetails = {
    startDate?: CPDate;
    endDate?: CPDate;
    lastTerm?: number;
    pendingInstalments?: number;
    iban?: string;
    monthlyDueDay?: number;
    nominalAmount?: number;
    interestRate?: number;
    interestRateType?: InterestRateType;
    indexDescription?: IndexDescriptionType;
    nextIndexingDate?: CPDate;
    amortizedCapital?: number;
    pendingCapital?: number;
    lastMileage?: number;
};

export type UpdatePaymentDay = {
    paymentDay: number;
};

export type UpdatePaymentDayError = 'NEW_PAYMENT_DAY_IS_INVALID';

export const AvailablePaymentDays: Readonly<number[]> = Object.freeze([5, 15, 28]);

export type UpdateMileage = {
    currentMileage: number;
    newMileage: number;
};

export type UpdateMileageError = 'MILEAGE_OUT_OF_SCOPE' | 'LAST_MILEAGE_NOT_EXIST';

export const MAX_MILEAGE = 200_000;
