import { formatAsFileSize } from 'common';
import { FileUpload as SharedFileUpload } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { maxFileSize } from '../../config';

export type FileUploadProps = { name: string; label?: string; isMandatory?: boolean };

export const FileUpload: React.FC<FileUploadProps> = ({ name, label, isMandatory = true }) => {
    const { t } = useTranslation('file-upload');
    const sizeError = t('size-error');
    const typeError = t('type-error');
    const sizeAndTypeError = (
        <>
            {sizeError}
            <br />
            {typeError}
        </>
    );
    return (
        <>
            {label && (
                <p
                    dangerouslySetInnerHTML={{
                        __html: isMandatory ? `${label} *` : label,
                    }}
                    className={'c-form-field__label'}
                />
            )}
            <SharedFileUpload
                name={name}
                descriptionText={t('headline')}
                descriptionSupplementaryText={t('text')}
                buttonText={t('button')}
                cancelLabel={t('translation:editable-section-nav.cancel')}
                fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                maxFileSize={maxFileSize}
                validFileFormats={['pdf', 'jpeg', 'jpg', 'png', 'tiff']}
                sizeError={sizeError}
                typeError={typeError}
                sizeAndTypeError={sizeAndTypeError}
            />
        </>
    );
};
