import { AuthenticationProvider, AutoLogout, ScrollToTopOnNavigation, Spinner } from '@cp-shared-8/frontend-ui';
import {
    changeContactDetailsPath,
    changeFiscalAddressPath,
    changeIbanPath,
    changeIdentificationPath,
    changePaymentDayPath,
    changePostalAddressPath,
    civicToolCookiePolicyPath,
    contractHandoverPath,
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    forbiddenPagePath,
    landingPagePath,
    legalNoticePath,
    loginRedirectPagePath,
    myDocumentsPagePath,
    myDocumentsWithContractIdPagePath,
    myProfilePagePath,
    myRequestsPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    partialEarlySettlementPath,
    paymentDetailsPath,
    privacyPolicyPath,
    registrationPagePath,
    requestAppointmentForCarReturnPath,
    requestAppointmentForMaintenancePath,
    logoutPath,
    errorPagePath,
} from 'components/navigation/paths';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import {
    ContractHandoverPage,
    CookiePolicyPage,
    DashboardPage,
    FaqPage,
    ForbiddenPage,
    LandingPage,
    LegalNoticePage,
    MasterPage,
    MyDocumentsPage,
    MyProfilePage,
    NotAuthorizedPage,
    NotFoundPage,
    PartialEarlySettlementPage,
    PaymentDetailsPage,
    PrivacyPolicyPage,
    RegistrationPage,
    RequestAppointmentForCarReturnPage,
    RequestAppointmentForMaintenancePage,
    RequestPage,
    LogoutSuccessPage,
    ErrorPage,
} from './pages';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { history } from './store/rootReducer';
import { AxiosConfiguration } from './auth/AxiosConfiguration';
import { AuthenticatedRoute, AuthorizedRoute } from './auth';
import { LoginRedirect } from './components/login-redirect/LoginRedirect';

const App: React.FC = () => (
    <div className="App">
        <Suspense fallback={<Spinner center />}>
            <AuthenticationProvider loadingComponent={<Spinner center />}>
                <AutoLogout idleTimeInMinutes={30} redirectUri={window.location.origin + logoutPath()}>
                    <AxiosConfiguration>
                        <ConnectedRouter history={history}>
                            <>
                                <ScrollToTopOnNavigation
                                    exceptions={[
                                        { from: /\S*/, to: changeIdentificationPath() },
                                        { from: /\S*/, to: changePostalAddressPath() },
                                        { from: /\S*/, to: changeFiscalAddressPath() },
                                        { from: /\S*/, to: changeContactDetailsPath() },
                                        { from: /\S*/, to: changePaymentDayPath('[\\w-]+') },
                                        { from: /\S*/, to: new RegExp(changeIbanPath('[\\w-]+')) },
                                        { from: new RegExp(changeIbanPath('[\\w-]+')), to: dashboardPagePath() },
                                        { from: changeIdentificationPath(), to: myProfilePagePath() },
                                        { from: changePostalAddressPath(), to: myProfilePagePath() },
                                        { from: changeFiscalAddressPath(), to: myProfilePagePath() },
                                        { from: changeContactDetailsPath(), to: myProfilePagePath() },
                                        { from: changePaymentDayPath('[\\w-]+'), to: dashboardPagePath() },
                                    ]}
                                />
                                <LastLocationProvider>
                                    <MasterPage>
                                        <Switch>
                                            <Route exact path={landingPagePath()}>
                                                <LandingPage />
                                            </Route>
                                            <Route path={loginRedirectPagePath()}>
                                                <LoginRedirect />
                                            </Route>
                                            <Route path={faqPagePath()}>
                                                <FaqPage />
                                            </Route>
                                            <Route path={privacyPolicyPath()}>
                                                <PrivacyPolicyPage />
                                            </Route>
                                            <Route path={legalNoticePath()}>
                                                <LegalNoticePage />
                                            </Route>
                                            <AuthorizedRoute path={contractHandoverPath()}>
                                                <ContractHandoverPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={dashboardPagePath()}>
                                                <DashboardPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={myProfilePagePath()}>
                                                <MyProfilePage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={myDocumentsWithContractIdPagePath()}>
                                                <MyDocumentsPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={myDocumentsPagePath()}>
                                                <MyDocumentsPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={myRequestsPagePath()}>
                                                <RequestPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={paymentDetailsPath()}>
                                                <PaymentDetailsPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={requestAppointmentForCarReturnPath()}>
                                                <RequestAppointmentForCarReturnPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={requestAppointmentForMaintenancePath()}>
                                                <RequestAppointmentForMaintenancePage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={partialEarlySettlementPath()}>
                                                <PartialEarlySettlementPage />
                                            </AuthorizedRoute>
                                            <AuthenticatedRoute path={registrationPagePath()}>
                                                <RegistrationPage />
                                            </AuthenticatedRoute>
                                            <Route path={forbiddenPagePath()}>
                                                <ForbiddenPage />
                                            </Route>
                                            <Route path={notAuthorizedPagePath()}>
                                                <NotAuthorizedPage />
                                            </Route>
                                            <Route path={notFoundPagePath()}>
                                                <NotFoundPage />
                                            </Route>
                                            <Route path={cookiePolicyPath()}>
                                                <CookiePolicyPage />
                                            </Route>
                                            <Route path={civicToolCookiePolicyPath()}>
                                                <Redirect to={cookiePolicyPath()} />
                                            </Route>
                                            <Route path={logoutPath()}>
                                                <LogoutSuccessPage />
                                            </Route>
                                            <Route path={errorPagePath()}>
                                                <ErrorPage />
                                            </Route>
                                            <Route path="*">
                                                <Redirect to={notFoundPagePath()} />
                                            </Route>
                                        </Switch>
                                    </MasterPage>
                                </LastLocationProvider>
                            </>
                        </ConnectedRouter>
                    </AxiosConfiguration>
                </AutoLogout>
            </AuthenticationProvider>
        </Suspense>
    </div>
);

export default App;
