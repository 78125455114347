import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { AccordionItem } from '@cp-shared-8/frontend-ui';
import { FinancialDetailsUi } from './ui';
import {
    Contract,
    ContractDetailsData,
    FinancialDetails as FinancialDetailsType,
    isErrorDetailsData,
    isSuccessDetailsData,
} from '../../../../common';
import { withNoConnectionHandler } from '../../../integration-wrapper';

type FinancialDetailsProps = {
    contract: Contract;
    financialDetails?: ContractDetailsData<FinancialDetailsType>;
};

export const FinancialDetails: React.FC<FinancialDetailsProps> = ({ contract, financialDetails }) => {
    const { t } = useTranslation('financial-details');

    if (!financialDetails || (isSuccessDetailsData(financialDetails) && isEmpty(financialDetails.data))) return null;

    const hasError = !!financialDetails && isErrorDetailsData(financialDetails);

    const FinancialDetailsUiWithHandlers = withNoConnectionHandler(FinancialDetailsUi);

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <FinancialDetailsUiWithHandlers
                contract={contract}
                financialDetailsData={financialDetails?.data}
                hasError={hasError}
            />
        </AccordionItem>
    );
};
