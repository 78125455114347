import { CPDate } from '@cp-shared-8/common-utilities';

export type Registration = {
    checkNumber: string;
    fiscalNumber: string;
    dateOfBirth: CPDate;
};

export enum RegistrationError {
    CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
    NO_CONTRACTS_FOR_CUSTOMER = 'NO_CONTRACTS_FOR_CUSTOMER',
    CUSTOMER_BLOCKED = 'CUSTOMER_BLOCKED',
    CUSTOMER_ALREADY_REGISTERED = 'CUSTOMER_ALREADY_REGISTERED',
    OTHER = 'OTHER',
}
