import { Brand } from './enums';

export const capitalizeWord = (word: string): string => {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};

export const getBrand = (brandModelType?: string): Brand | undefined => {
    if (!brandModelType) {
        return;
    }
    const availableBrands = ['AUDI', 'SEAT', 'SKODA', 'ŠKODA', 'VW', 'VOLKSWAGEN'];
    const currentBrand = availableBrands.find((brand) => brandModelType.toUpperCase().includes(brand));
    switch (currentBrand) {
        case 'AUDI':
            return Brand.AUDI;
        case 'SEAT':
            return Brand.SEAT;
        case 'SKODA':
        case 'ŠKODA':
            return Brand.SKODA;
        case 'VW':
        case 'VOLKSWAGEN':
            return Brand.VW;
        default:
            return;
    }
};
