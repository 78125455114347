import moment from 'moment-timezone';

import 'moment/locale/pt';
import 'numeral/locales/pt-pt';
import { CPDate, formatCpDate } from '@cp-shared-8/common-utilities';
import numeral from 'numeral';

// Check out https://momentjs.com/timezone/
export const PT_TIMEZONE = 'Europe/Lisbon';

moment.tz.setDefault(PT_TIMEZONE);

export const DATE_FORMAT = 'DD-MM-YYYY';

const locale = 'pt';
export const numberFormat = '0,0';
export const numberFormatRoundedOne = '0,0.0';
const numeralFormat = '0o';
const currencyFormat = '0,0.00';
const longDateFormat = 'DD/MMM/YYYY';
export const dateFormatPt = 'DD-MM-YYYY';
const percentage = '0,0[.]00';

/**
 * 'pt-pt' uses wrong thousands delimiter
 */
numeral.locales['pt-pt'].delimiters.thousands = '.';

export function changeLocale(locale: 'en' | 'pt'): void {
    moment.locale(locale);
    numeral.locale(locale === 'pt' ? 'pt-pt' : locale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(currencyFormat)} €` : '';
}

export function formatAsDate(date?: CPDate): string {
    const dateFormat = moment.locale() === 'en' ? longDateFormat : dateFormatPt;
    return date ? formatCpDate(date).format(dateFormat) : '';
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsPercentage(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(percentage)}%` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}

export function formatAsFileSize(bytes: number, decimal: number): string {
    const unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(decimal) + ' ' + unit[i];
}
