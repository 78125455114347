import React from 'react';
import { CpDataApi } from 'cp-xhr';
import { Contract, getContractDetailsEndpoint } from '../../../common';
import { DefaultBusinessMarketApiError, DefaultMarketApiErrors } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { ContractsMock } from './contractsMock';
import { ContractDetailsWithAllValues, ContractDetailsWithErrors } from './ExampleData';
import {
    ActiveFinanceContractWithAllValues,
    ActiveFinanceContractWithMissingValues,
    ActiveMaintenanceContractWithAllValues,
    ActiveMaintenanceContractWithMissingValues,
    InactiveContractExpiredEndDate,
    InactiveFinanceContractWithAllValues,
    InactiveFinanceContractWithMissingValues,
} from '../ExampleData';
import { AmortizationTableMocks } from './ui/ContractDetailsUIResponseMocks';

const mockOptions: MockOptions<DefaultMarketApiErrors> = {
    Success: {
        status: 200,
        responseBody: ContractDetailsWithAllValues,
        delay: 1000,
    },
    'With errors': {
        status: 200,
        responseBody: ContractDetailsWithErrors,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractDetailsMock = (contract: Contract): EndpointMockDescription<DefaultMarketApiErrors> => {
    return {
        name: 'Contract Details Response',
        method: 'get',
        defaultMockOption: 'Success',
        mockOptions,
        url: getContractDetailsEndpoint(contract.contractId || ''),
    };
};

function mockContractDetailsResponse(): void {
    const ContractDetailsMocks = [
        ContractDetailsMock(ActiveFinanceContractWithAllValues),
        ContractDetailsMock(ActiveFinanceContractWithMissingValues),
        ContractDetailsMock(ActiveMaintenanceContractWithAllValues),
        ContractDetailsMock(ActiveMaintenanceContractWithMissingValues),
        ContractDetailsMock(InactiveContractExpiredEndDate),
        ContractDetailsMock(InactiveFinanceContractWithAllValues),
        ContractDetailsMock(InactiveFinanceContractWithMissingValues),
    ];
    setupMockResponses(CpDataApi, [...AmortizationTableMocks, ContractsMock, ...ContractDetailsMocks]);
}

export const ContractDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    mockContractDetailsResponse();
    return <div>{storyFn()}</div>;
};
