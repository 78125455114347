import React from 'react';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingHandler } from '../integration-wrapper';
import { PaymentDetailsUi } from './ui';
import { useContract } from '../contracts/useContract';
import { usePaymentDetails } from './usePaymentDetails';

export const PaymentDetails: React.FC<{ contractId: string }> = ({ contractId }) => {
    const {
        data: contract,
        isLoading: isContractLoading,
        loadingError: contractLoadingError,
    } = useContract(contractId);
    const {
        data: paymentDetails,
        isLoading: isPaymentDetailsLoading,
        loadingError: paymentDetailsLoadingError,
    } = usePaymentDetails(contractId);

    useAnalyticsPageViewTracker('unpaidDetails', !!contract && !!paymentDetails);

    const loadingError = !!contractLoadingError || !!paymentDetailsLoadingError;
    const isLoading = isContractLoading || isPaymentDetailsLoading;
    const PaymentDetailsWithHandlers = withLoadingHandler(PaymentDetailsUi);

    return (
        <PaymentDetailsWithHandlers
            isLoading={isLoading}
            contract={contract}
            paymentDetails={paymentDetails}
            loadingError={loadingError}
        />
    );
};
