import { PaymentDetails } from 'common';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultMarketApiErrors } from '@cp-shared-8/common-utilities';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<PaymentDetails, DefaultMarketApiErrors>({
    dataName: 'paymentDetails',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then((response) => response.data);
    },
});

export default reducer;
export const fetchPaymentDetails = fetchData;
