import React, { useState } from 'react';
import { ConsultView } from './consult-view';
import { EditStatus, FinancialDetails, ProductTypeEN } from '../../../../../common';
import { Hr } from '@vwfs-bronson/bronson-react';
import { changePaymentDayPath } from '../../../../navigation/paths';
import { EditView } from './edit-view/EditView';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';

type PaymentDaySectionProps = {
    details?: FinancialDetails;
    isActive: boolean;
    contractId: string;
    productTypeEN?: ProductTypeEN;
};

export const PaymentDaySection: React.FC<PaymentDaySectionProps> = ({
    details,
    isActive,
    contractId,
    productTypeEN,
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();

    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    const { onAction: trackOnStart } = useAnalyticsActionTracker('financialDetailsEditDueDateSection');
    const { onAction: trackOnCancel } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionCancel');

    if (!details || !isActive) {
        return null;
    }

    const startEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changePaymentDayPath(contractId));
        trackOnStart();
    };

    const cancelEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
        trackOnCancel();
    };

    const finishEditing = (newEditStatus: EditStatus): void => {
        setLastEditStatus(newEditStatus);
        history.push(path);
    };

    return (
        <>
            <Hr className="u-mb-none" />
            <Switch>
                <Route exact path={changePaymentDayPath(contractId)}>
                    <EditView
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        monthlyDueDay={details.monthlyDueDay}
                        contractId={contractId}
                    />
                </Route>
                <Route path={path}>
                    <ConsultView
                        monthlyDueDay={details.monthlyDueDay}
                        startEditing={startEditing}
                        lastEditStatus={lastEditStatus}
                        productTypeEN={productTypeEN}
                    />
                </Route>
            </Switch>
        </>
    );
};
