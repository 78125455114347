import {
    Contract,
    dateFormatPt,
    getPartialEarlySettlementEndpoint,
    PartialEarlySettlement,
    PartialEarlySettlementOptions,
} from '../../../../common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { uploadFilesToRequestedUrls } from '@cp-shared-8/frontend-integration';
import {
    CleaveInput,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    ValidatedDate,
    ValidatedRadioButtonGroup,
} from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout, Modal } from '@vwfs-bronson/bronson-react';
import { FileUpload } from 'components/file-upload';
import { dashboardPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dotFormattedStringToNumber } from 'utils';
import { ContractInformationHeader } from '../../../contract-information-header';
import { getInitialValues, InitialValues } from './initialValues';
import { getToday, validationSchema } from './validationSchema';

type PartialEarlySettlementUi = {
    contract?: Contract;
};

export const PartialEarlySettlementUi: React.FC<PartialEarlySettlementUi> = ({ contract }) => {
    const { t, i18n } = useTranslation('early-settlement');
    const translationPrefix = 'partial-early-settlement';
    const language = i18n.languages[0];
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [modalStatus, setModalStatus] = useState<'success' | 'error' | undefined>();

    useAnalyticsPageViewTracker('earlySettlement', !!contract);
    const { onAction: onSuccess } = useAnalyticsActionTracker('onEditEarlySettlementSummaryLayerSuccess');
    const { onAction: onFail } = useAnalyticsActionTracker('onEarlySettlementSummarySubmitFailed');

    if (!contract || contract.outstandingBalance === undefined) return null;

    const minDate = formatCpDate(getToday().add(1, 'day')).toCpDate();

    const onSubmit = (values: InitialValues) => {
        setIsSubmitting(true);

        const doAfterSuccessfulUpload = (fileIds: string[]): void => {
            const dateFormat = language === 'pt' ? dateFormatPt : 'DD/MM/YYYY';
            const body: PartialEarlySettlement = {
                requestDate: formatCpDate(values.requestDate, dateFormat).toCpDate(),
                amount: dotFormattedStringToNumber(values.amount),
                option: values.option as PartialEarlySettlementOptions,
                fileIds,
            };

            CpDataApi.put(getPartialEarlySettlementEndpoint(contract.contractId), body)
                .then(() => {
                    setModalStatus('success');
                    onSuccess();
                })
                .catch((error) => {
                    setModalStatus('error');
                    onFail(error);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        const doAfterUploadError = (): void => {
            setIsSubmitting(false);
            setModalStatus('error');
        };

        uploadFilesToRequestedUrls([...values.contractAmendment, ...values.paymentReceipt], CpDataApi)
            .then((filesIds: string[]) => {
                doAfterSuccessfulUpload(filesIds);
            })
            .catch((error) => {
                doAfterUploadError();
                onFail(error);
            });
    };

    const redirectToDashboard = (): void => history.push(dashboardPagePath());

    const closeModal = (): void => {
        modalStatus === 'success' ? redirectToDashboard() : setModalStatus(undefined);
    };

    const settlementOptions = [
        {
            label: t(`${translationPrefix}.form.option.reduce-terms`),
            value: PartialEarlySettlementOptions.REDUCE_TERMS,
        },
        {
            label: t(`${translationPrefix}.form.option.reduce-installment`),
            value: PartialEarlySettlementOptions.REDUCE_INSTALLMENT,
        },
        {
            label: t(`${translationPrefix}.form.option.both`),
            value: PartialEarlySettlementOptions.BOTH,
        },
    ];

    return (
        <>
            <ContractInformationHeader contract={contract} />
            <p dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.description`) }} className={'u-mb u-mt'} />
            <UiBlockingSpinner isBlocking={isSubmitting}>
                <Formik
                    initialValues={getInitialValues()}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema(t, language, contract.outstandingBalance)}
                >
                    {({ submitForm }) => (
                        <Form data-testid="partial-early-settlement-form" onSubmit={preventSubmit}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default={'1/2'} s={'1/1'}>
                                            <CleaveInput
                                                cleaveOptions={{
                                                    numeral: true,
                                                    numeralDecimalMark: ',',
                                                    delimiter: '.',
                                                    numeralThousandsGroupStyle: 'thousand',
                                                    numericOnly: true,
                                                    numeralDecimalScale: 0,
                                                    numeralPositiveOnly: true,
                                                    stripLeadingZeroes: true,
                                                }}
                                                name={'amount'}
                                                label={t(`${translationPrefix}.form.amount.label`)}
                                                inputMode={'numeric'}
                                                addonText={t('translation:currency')}
                                                maxLength={7}
                                                isMandatory
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/3" s="1/1">
                                            <ValidatedDate
                                                name={'requestDate'}
                                                testId={'requestDate'}
                                                language={language}
                                                outputDateFormat={language === 'pt' ? 'd-m-Y' : 'd/m/Y'}
                                                label={t(`${translationPrefix}.form.request-date.label`)}
                                                tooltip={t(`${translationPrefix}.form.request-date.tooltip`)}
                                                isMandatory
                                                minDate={minDate}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/1" s="1/1">
                                            <ValidatedRadioButtonGroup
                                                label={t(`${translationPrefix}.form.option.label`)}
                                                radioButtons={settlementOptions}
                                                name={'option'}
                                                testId={'option'}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/1" s="1/1">
                                            <FileUpload
                                                name="paymentReceipt"
                                                label={t(`${translationPrefix}.form.payment-receipt.label`)}
                                                isMandatory={false}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            secondary
                                            onClick={redirectToDashboard}
                                            testId="edit-payment-day-cancel"
                                        >
                                            {t('translation:editable-section-nav.cancel')}
                                        </Button>
                                        <Button onClick={submitForm} testId="submit" type={'submit'}>
                                            {t('translation:editable-section-nav.submit')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
                <Modal
                    shown={!!modalStatus}
                    status={modalStatus}
                    buttonConfirmText={
                        modalStatus === 'success'
                            ? t('translation:editable-section-nav.dashboard')
                            : t('translation:editable-section-nav.back')
                    }
                    onConfirm={closeModal}
                    onClickOutside={closeModal}
                    onClose={closeModal}
                    testId={`confirmModal-${modalStatus}`}
                    title={
                        modalStatus === 'success'
                            ? t(`${translationPrefix}.modal.success.title`)
                            : t(`${translationPrefix}.modal.error.title`)
                    }
                >
                    {modalStatus === 'success' && t(`${translationPrefix}.modal.success.text`)}
                </Modal>
            </UiBlockingSpinner>
        </>
    );
};
