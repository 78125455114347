import React from 'react';
import { EditStatus, ProductTypeEN } from '../../../../../../common';
import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { Button, Heading } from '@vwfs-bronson/bronson-react';

type ConsultViewProps = {
    monthlyDueDay?: number;
    startEditing: () => void;
    lastEditStatus: EditStatus;
    productTypeEN?: ProductTypeEN;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    monthlyDueDay = '-',
    startEditing,
    lastEditStatus,
    productTypeEN,
}) => {
    const { t } = useTranslation('financial-details');

    const translationPrefix = 'payment-day-section.edit-view.notification';
    const itemList: DefinitionListItem[] = [
        {
            label: t('payment-day-section.monthly-due-day.label'),
            tooltip: t('payment-day-section.monthly-due-day.tooltip'),
            value: monthlyDueDay,
            testId: 'monthly-due-day',
        },
    ];

    const notificationProps = () => {
        switch (lastEditStatus) {
            case EditStatus.ERROR:
                return {
                    status: NotificationStatus.error,
                    testId: 'notification-error',
                    text: t(`${translationPrefix}.error.text`),
                };
            case EditStatus.SUCCESS:
                return {
                    status: NotificationStatus.success,
                    testId: 'notification-success',
                    headline: t(`${translationPrefix}.success.headline`),
                    text: t(`${translationPrefix}.success.text`),
                };
            case EditStatus.WARNING:
                return {
                    status: NotificationStatus.warning,
                    testId: 'notification-warning',
                    headline: t(`${translationPrefix}.warning.headline`),
                    text: t(`${translationPrefix}.warning.text`),
                };
        }
    };

    return (
        <>
            <Heading className={`c-data-overview__header u-pt`} level={'6'}>
                {t('payment-day-section.headline')}
                {productTypeEN === ProductTypeEN.FINANCIAL && (
                    <Button
                        element="a"
                        link
                        small
                        className="c-data-overview__button u-mb-none u-mt-none u-mr-small"
                        icon="true"
                        iconReversed
                        onClick={startEditing}
                        testId={'edit-button'}
                    >
                        {t('translation:editable-section-nav.edit')}
                    </Button>
                )}
            </Heading>
            <DefinitionListHorizontal list={itemList} />
            {lastEditStatus !== EditStatus.NOT_PERFORMED && (
                <Notification {...notificationProps()} className={'u-mb'} />
            )}
        </>
    );
};
