import { Button, ButtonContainer, Heading, ErrorMessage } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { EditViewNames } from '../../enums';
import { saveAs as downloadFileAs } from 'file-saver';
import { DownloadLink, UiBlockingSpinner, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { CpDataApi } from '../../../../../../../cp-xhr';
import { getSepaMandateDownloadEndpoint, SepaMandateResponse } from '../../../../../../../common';
import base64ToBlob from 'b64-to-blob';
import { NoConnectionNotification } from '../../../../../../notifications/no-connection/NoConnectionNotification';
import { stripWhitespaces } from '../iban-view/validated-iban-input/ibanValidationsUtils';

export type SepaDownloadViewProps = {
    setCurrentView: (newEditView: EditViewNames) => void;
    contractId: string;
    iban: string;
};

enum FileDownloadStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    NOT_PERFORMED = 'NOT_PERFORMED',
}

export const testIds = {
    backButton: 'back-to-iban-view',
    continueButton: 'continue-to-sepa-view',
    sepaDownloadLink: 'sepa-download-link',
    notificationError: 'continue-to-sepa-view',
    validationError: 'validation-error',
};
export const SepaDownloadView: React.FC<SepaDownloadViewProps> = ({ setCurrentView, contractId, iban }) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const [isDownloading, setIsDownloading] = useState(false);
    const [fileDownloadStatus, setFileDownloadStatus] = useState<FileDownloadStatus>(FileDownloadStatus.NOT_PERFORMED);
    const [hasClickedOnSubmit, setHasClickedOnSubmit] = useState<boolean>(false);
    const { onAction: onDownload } = useAnalyticsActionTracker('onSepaMandateDownload');
    const { onAction: onDownloadError } = useAnalyticsActionTracker('sepaMandateDownloadError');

    const translationPrefix = 'iban-section.edit.sepa';

    const onBackClick = (): void => {
        setCurrentView(EditViewNames.IBAN_VIEW);
    };
    const onContinueClick = (): void => {
        setHasClickedOnSubmit(true);
        if (fileDownloadStatus === FileDownloadStatus.SUCCESS) {
            setCurrentView(EditViewNames.SUBMIT_VIEW);
        }
    };
    const downloadFile = (): void => {
        setIsDownloading(true);
        CpDataApi.get(getSepaMandateDownloadEndpoint(contractId), { params: { iban: stripWhitespaces(iban) } })
            .then(({ data }) => {
                const { fileName, fileContent }: SepaMandateResponse = data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                onDownload();
                setFileDownloadStatus(FileDownloadStatus.SUCCESS);
            })
            .catch((error) => {
                setHasClickedOnSubmit(false);
                setFileDownloadStatus(FileDownloadStatus.ERROR);
                onDownloadError(error);
            })
            .finally(() => {
                setIsDownloading(false);
            });
    };

    return (
        <>
            <Heading className="u-pt" level={'6'}>
                {t(`${translationPrefix}.title`)}
            </Heading>
            <div className={'c-labeled-content-section u-pt-none u-pb'}>
                <UiBlockingSpinner isBlocking={isDownloading}>
                    <div className={'u-mb'} dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.info`) }} />
                    <DownloadLink
                        label={t(`${translationPrefix}.download-link`)}
                        onClick={downloadFile}
                        testId={testIds.sepaDownloadLink}
                    />
                    {hasClickedOnSubmit && fileDownloadStatus !== FileDownloadStatus.SUCCESS && (
                        <ErrorMessage testId={testIds.validationError}>
                            {t(`${translationPrefix}.validation-error`)}
                        </ErrorMessage>
                    )}
                    {fileDownloadStatus === FileDownloadStatus.ERROR && (
                        <NoConnectionNotification testId={testIds.notificationError} className={'u-mt-small'} />
                    )}
                    <ButtonContainer className={'u-mt'} center>
                        <Button secondary onClick={onBackClick} testId={testIds.backButton}>
                            {t('translation:editable-section-nav.back')}
                        </Button>
                        <Button onClick={onContinueClick} testId={testIds.continueButton} type="submit">
                            {t('translation:editable-section-nav.continue')}
                        </Button>
                    </ButtonContainer>
                </UiBlockingSpinner>
            </div>
        </>
    );
};
