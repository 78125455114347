export function getMyDocumentsEndpoint(): string {
    return `/my-documents`;
}

export function getMyDocumentDownloadEndpoint(documentId: string): string {
    return `${getMyDocumentsEndpoint()}/${documentId}`;
}

export function getDigitalInvoiceEndpoint(): string {
    return `${getMyDocumentsEndpoint()}/digital-invoice`;
}
