import React from 'react';
// import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { FormView } from './form-view/FormView';
import { useContract } from '../../contracts';
import { useMyProfile } from '../../my-profile';
import { useIsInvalidContractId } from 'utils';

export const RequestAppointmentForCarReturn: React.FC<{ contractId: string }> = ({ contractId }) => {
    const {
        data: contract,
        isLoading: isLoadingContracts,
        loadingError: contractLoadingError,
    } = useContract(contractId);
    const { data: myProfile, isLoading: isLoadingMyProfile } = useMyProfile();
    const isInvalidContractId = useIsInvalidContractId(isLoadingContracts, contract);

    // useAnalyticsPageViewTracker('requests', !!contracts);
    const RequestWithHandlers = withLoadingAndNoConnectionHandler(FormView);

    return (
        <RequestWithHandlers
            contract={contract}
            defaultPhoneNumber={myProfile?.contactDetails?.mobilePhone}
            isLoading={isLoadingContracts || isLoadingMyProfile}
            hasError={!!contractLoadingError || isInvalidContractId}
        />
    );
};
