import React, { useState } from 'react';
import { Button, ButtonContainer, DataOverview, Fieldset } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { EditStatus, getIdentificationEndpoint } from 'common';
import { EditStep } from '../enums';
import { UiBlockingSpinner, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { CpDataApi } from 'cp-xhr';

type ConfirmationViewType = {
    updatedSurname: string;
    finishEditing: (newEditStatus: EditStatus, updatedSurname?: string) => void;
    setStep: (arg: EditStep) => void;
};

export const ConfirmationView: React.FC<ConfirmationViewType> = ({ updatedSurname, finishEditing, setStep }) => {
    const { t } = useTranslation('my-profile');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { onAction: onFail } = useAnalyticsActionTracker('onEditProfileIdentificationSubmitFailed');

    const translationPrefix = 'identification.edit.confirmation-view';

    const onSubmit = () => {
        setIsSubmitting(true);
        CpDataApi.put(getIdentificationEndpoint(), { surname: updatedSurname })
            .then(() => {
                finishEditing(EditStatus.SUCCESS, updatedSurname);
            })
            .catch(() => {
                onFail();
                finishEditing(EditStatus.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <DataOverview title={t(`${translationPrefix}.headline`)}>
            <UiBlockingSpinner isBlocking={isSubmitting}>
                <Fieldset>
                    <Fieldset.Row>
                        <div dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.text`) }} />
                    </Fieldset.Row>
                    <Fieldset.Row>
                        <ButtonContainer center>
                            <Button
                                secondary
                                onClick={() => setStep(EditStep.FORM)}
                                testId="cancel-button"
                                type="button"
                            >
                                {t('translation:editable-section-nav.back')}
                            </Button>
                            <Button onClick={onSubmit} testId="submit-button" type="submit">
                                {t('translation:editable-section-nav.submit')}
                            </Button>
                        </ButtonContainer>
                    </Fieldset.Row>
                </Fieldset>
            </UiBlockingSpinner>
        </DataOverview>
    );
};
