import React from 'react';
import { Faq as FaqShared, HeroImage, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { Faq } from 'common';
import { ContentSection } from '@vwfs-bronson/bronson-react';

type FaqUiProps = {
    faq?: Faq;
};

export const FaqUi: React.FC<FaqUiProps> = ({ faq }) => {
    const { onAction } = useAnalyticsActionTracker('onFaqQuestion');
    if (!faq) {
        return null;
    }
    const { teaser, content } = faq;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} inverted={true} />
    ) : null;

    let openItems = [];
    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        onAction(refs[refs.length - 1]);
    };

    const contentComponent = <FaqShared onChange={handleOnChange} header={content.header} items={content.items} />;

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">{contentComponent}</ContentSection>
        </>
    );
};
