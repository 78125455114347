import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
    Accordion,
    AccordionItem,
    DefinitionListHorizontal,
    DefinitionListItem,
    Skeleton,
} from '@cp-shared-8/frontend-ui';
import { compact } from 'lodash';
import { Layout } from '@vwfs-bronson/bronson-react';
import { VehicleServiceResponse } from '@cp-shared-8/apis';
import { capitalizeWord } from '../utils';

export const VehicleDetailsPlaceholder: React.FC = () => (
    <Layout>
        <Layout.Item>
            <Skeleton height={40} />
        </Layout.Item>
        <Layout.Item>
            <Skeleton height={40} />
        </Layout.Item>
    </Layout>
);

const translateFuelType = (fuelType: string, t: TFunction): string => {
    if (fuelType.toLowerCase() === 'gasoline') {
        return t('petrol');
    }
    return capitalizeWord(fuelType);
};

export const VehicleDetailsUi: React.FC<{
    details?: VehicleServiceResponse;
    isLoading: boolean;
}> = ({ details, isLoading }) => {
    const { t } = useTranslation('vehicle-details-and-image');
    const [accordionOpen, setAccordionOpen] = useState(false);

    const getVehicleDetails = useCallback((t: TFunction, details?: VehicleServiceResponse): DefinitionListItem[] => {
        const { engineSize, enginePower, fuelType = '' } = details || {};
        return compact([
            engineSize && engineSize.value && engineSize.unit
                ? {
                      label: t('engine'),
                      value: `${engineSize.value} ${engineSize.unit} ${translateFuelType(fuelType, t)}`,
                      testId: 'vehicle-details-engine-size',
                  }
                : undefined,
            enginePower && enginePower.value && enginePower.unit
                ? {
                      label: t('engine-power'),
                      value: `${enginePower.value} ${enginePower.unit}`,
                      testId: 'vehicle-details-engine-power',
                  }
                : undefined,
        ]);
    }, []);

    const itemList: DefinitionListItem[] = useMemo(
        () => getVehicleDetails(t, details),
        [getVehicleDetails, t, details],
    );

    const detailsComponent = useMemo(() => {
        return (
            <>
                {isLoading && <VehicleDetailsPlaceholder />}
                {!isLoading && <DefinitionListHorizontal list={itemList} />}
            </>
        );
    }, [isLoading, itemList]);

    const handleOnChange = (refs: string[]): void => {
        if (refs.length) {
            setAccordionOpen(true);
        } else {
            setAccordionOpen(false);
        }
    };

    return (
        <>
            <Accordion className={'u-hide@s-up'} lazyRender={true} onChange={handleOnChange}>
                <AccordionItem
                    title={t(accordionOpen ? 'details-button.collapse' : 'details-button.expand')}
                    defaultOpen={accordionOpen}
                >
                    {detailsComponent}
                </AccordionItem>
            </Accordion>
            <div className={'u-hide@s'}>{detailsComponent}</div>
        </>
    );
};
