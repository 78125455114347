import {
    BaseBundledProduct,
    BundledProduct,
    InsuranceAuto,
    InsuranceCredit,
    Maintenance,
    ProductType,
    ServiceTyres,
} from '../../../../../common';
import { TFunction } from 'i18next';
import { FormatterFunction, TranslationFormat } from '../../../../../localization/useTranslationWithFormatting';
import { DefinitionListItem } from '@cp-shared-8/frontend-ui';

const getListItem = (
    fieldName: string,
    value: string | string[],
    t: TFunction,
    f: FormatterFunction,
): DefinitionListItem | null => {
    const items: {
        listItem: DefinitionListItem;
        key: string;
        format?: 'date' | 'percentage' | 'currency' | 'number' | 'deductible';
    }[] = [
        {
            key: 'productSupplier',
            listItem: {
                label: t('product-supplier'),
                value: '',
                testId: 'product-supplier',
            },
        },
        {
            key: 'productStateDescription',
            listItem: {
                label: t('product-state-description'),
                value: '',
                testId: 'product-state-description',
            },
        },
        {
            key: 'endDate',
            format: 'date',
            listItem: {
                label: t('end-date'),
                value: '',
                testId: 'end-date',
            },
        },
        {
            key: 'monthlyAmount',
            format: 'currency',
            listItem: {
                label: t('monthly-amount'),
                value: '',
                testId: 'monthly-amount',
            },
        },
        {
            key: 'productAmount',
            format: 'currency',
            listItem: {
                label: t('product-amount'),
                value: '',
                testId: 'product-amount',
            },
        },
        {
            key: 'sumInsured',
            format: 'currency',
            listItem: {
                label: t('sum-insured.label'),
                tooltip: t('sum-insured.tooltip'),
                value: '',
                testId: 'sum-insured',
            },
        },
        {
            key: 'policyNumber',
            listItem: {
                label: t('policy-number'),
                value: '',
                testId: 'policy-number',
            },
        },
        {
            key: 'deductible',
            format: 'deductible',
            listItem: {
                label: t('deductible-amount.label'),
                tooltip: t('deductible-amount.tooltip'),
                value: '',
                testId: 'deductible-amount',
            },
        },
        {
            key: 'tyresType',
            listItem: {
                label: t('tyres-type'),
                value: '',
                testId: 'tyres-type',
            },
        },
        {
            key: 'tyresOffer',
            listItem: {
                label: t('tyres-offer'),
                value: '',
                testId: 'tyres-offer',
            },
        },
    ];
    const item = items.find(({ key }) => key === fieldName);

    let formattedValue;
    switch (item?.format) {
        case 'date':
            formattedValue = value ? f(value, TranslationFormat.DATE) : '-';
            break;
        case 'percentage':
            formattedValue = Number(value) >= 0 ? f(Number(value), TranslationFormat.PERCENTAGE) : '-';
            break;
        case 'currency':
            formattedValue = Number(value) ? f(Number(value), TranslationFormat.CURRENCY) : '-';
            break;
        case 'number':
            formattedValue = Number(value) ? f(Number(value), TranslationFormat.NUMBER) : '-';
            break;
        case 'deductible':
            formattedValue = value.length
                ? `${f(Number(value[0]), TranslationFormat.PERCENTAGE)} / ${f(
                      Number(value[1]),
                      TranslationFormat.CURRENCY,
                  )}`
                : '-';
            break;
        default:
            formattedValue = value || '-';
    }
    return item ? { ...item.listItem, value: formattedValue } : null;
};

const getListItems = (
    fields: string[],
    values: Array<string | string[]>,
    t: TFunction,
    f: FormatterFunction,
): DefinitionListItem[] => {
    return fields.reduce((acc: DefinitionListItem[], field: string, index: number): DefinitionListItem[] => {
        // ToDo - clarify the empty-item behaviour
        // if (values[index]) {
        const item = getListItem(field, values[index], t, f);
        if (item) acc.push(item);
        // }
        return acc;
    }, []);
};

export const getListItemsForProduct = (
    t: TFunction,
    f: FormatterFunction,
    product: BundledProduct,
): DefinitionListItem[] => {
    switch (product.productType) {
        case ProductType.INSURANCE_CREDIT: {
            type validField =
                | 'productSupplier'
                | 'productStateDescription'
                | 'endDate'
                | 'monthlyAmount'
                | 'productAmount'
                | 'sumInsured';
            const fields: validField[] = [
                'productSupplier',
                'productStateDescription',
                'endDate',
                'monthlyAmount',
                'productAmount',
                'sumInsured',
            ];
            const details = product as InsuranceCredit;
            const values: string[] = fields.map((field: validField) => (details[field] ? `${details[field]}` : ''));
            return getListItems(fields, values, t, f);
        }
        case ProductType.INSURANCE_AUTO: {
            type validField =
                | 'productSupplier'
                | 'policyNumber'
                | 'productStateDescription'
                | 'endDate'
                | 'monthlyAmount'
                | 'productAmount'
                | 'deductible'
                | 'deductibleAmount'
                | 'sumInsured';
            const fields: validField[] = [
                'productSupplier',
                'policyNumber',
                'productStateDescription',
                'endDate',
                'monthlyAmount',
                'productAmount',
                'deductible',
                'sumInsured',
            ];
            const details = product as InsuranceAuto;
            const values: Array<string | string[]> = fields.map((field: validField) => {
                switch (field) {
                    case 'deductible':
                        return details['deductible'] && details['deductibleAmount']
                            ? [`${details['deductible']}`, `${details['deductibleAmount']}`]
                            : [];
                    default:
                        return details[field] ? `${details[field]}` : '';
                }
            });
            return getListItems(fields, values, t, f);
        }
        case ProductType.SERVICE_TYRES: {
            type validField = 'tyresType' | 'tyresOffer' | 'productStateDescription' | 'endDate';
            const fields: validField[] = ['tyresType', 'tyresOffer', 'productStateDescription', 'endDate'];
            const details = product as ServiceTyres;
            const values: string[] = fields.map((field: validField) => (details[field] ? `${details[field]}` : ''));
            return getListItems(fields, values, t, f);
        }
        case ProductType.SERVICE_CAR_INSPECTION:
        case ProductType.SERVICE_ROAD_TAX:
        case ProductType.SERVICE_REPLACEMENT_VEHICLE:
        case ProductType.SERVICE_ROAD_ASSISTANCE:
        case ProductType.SERVICE_SUPPORT_HOTLINE:
        case ProductType.SERVICE_MAINTENANCE: {
            type validField = 'productStateDescription' | 'endDate';
            const fields: validField[] = ['productStateDescription', 'endDate'];
            const details = product as BaseBundledProduct;
            const values: string[] = fields.map((field: validField) => (details[field] ? `${details[field]}` : ''));
            return getListItems(fields, values, t, f);
        }
        case ProductType.MAINTENANCE: {
            type validField = 'productStateDescription' | 'endDate' | 'monthlyAmount';
            const fields: validField[] = ['productStateDescription', 'endDate', 'monthlyAmount'];
            const details = product as Maintenance;
            const values: string[] = fields.map((field: validField) => (details[field] ? `${details[field]}` : ''));
            return getListItems(fields, values, t, f);
        }
        default:
            return [];
    }
};

export const isMandatoryFieldMissing = (product: BundledProduct): boolean => {
    const { productType, displayedProductType } = product;
    return (
        !productType ||
        !displayedProductType ||
        ((productType === ProductType.INSURANCE_CREDIT || productType === ProductType.INSURANCE_AUTO) &&
            !product.productDescription)
    );
};

export const getCoveragesList = (bundledProduct: BundledProduct): string[] | undefined => {
    return (bundledProduct.productType === ProductType.INSURANCE_CREDIT ||
        bundledProduct.productType === ProductType.INSURANCE_AUTO) &&
        bundledProduct.coverages
        ? bundledProduct.coverages
        : undefined;
};
