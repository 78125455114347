import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractHandover } from '../../components/contract-handover';

export const ContractHandoverPage: React.FC = () => {
    const { t } = useTranslation('contract-handover');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('title')}</Heading>
            <ContractHandover />
        </ContentSection>
    );
};
