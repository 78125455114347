import { ErrorPage, getForbiddenErrorPageEndpoint } from '../../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ErrorPage>({
    contentName: 'forbiddenErrorPage',
    contentEndpoint: getForbiddenErrorPageEndpoint,
});

export default reducer;
export const fetchForbiddenErrorPage = fetchContent;
