import React, { useState } from 'react';
import { IbanView } from './iban-view/IbanView';
import { EditStatus, EditViewNames } from '../enums';
import {
    getInitialValues as getIbanInitialValues,
    InitialValues as IbanInitialValues,
} from './iban-view/InitialValues';
import { SepaDownloadView } from './sepa-download-view/SepaDownloadView';
import { SubmitView } from './submit-view/SubmitView';

type EditViewProps = {
    contractId: string;
    iban?: string;
    onSuccess: (lastEditStatus: EditStatus, effectDate?: string) => void;
    onFail: (lastEditStatus: EditStatus) => void;
};

export const EditView: React.FC<EditViewProps> = ({ contractId, iban, onSuccess, onFail }) => {
    const [currentView, setCurrentView] = useState(EditViewNames.IBAN_VIEW);

    const [ibanViewValues, setIbanViewValues] = useState<IbanInitialValues>(getIbanInitialValues(iban));

    return (
        <div className={'u-mb'}>
            {currentView === EditViewNames.IBAN_VIEW && (
                <IbanView
                    setCurrentView={setCurrentView}
                    ibanViewValues={ibanViewValues}
                    setIbanViewValues={setIbanViewValues}
                />
            )}
            {currentView === EditViewNames.SEPA_MANDATE_DOWNLOAD_VIEW && (
                <SepaDownloadView setCurrentView={setCurrentView} contractId={contractId} iban={ibanViewValues.iban} />
            )}
            {currentView === EditViewNames.SUBMIT_VIEW && (
                <SubmitView
                    contractId={contractId}
                    iban={ibanViewValues.iban}
                    setCurrentView={setCurrentView}
                    onSuccess={onSuccess}
                    onFail={onFail}
                />
            )}
        </div>
    );
};
