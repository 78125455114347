import React from 'react';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { FaqUi } from './ui';
import { useFaq } from './useFaq';

export const Faq: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useFaq();
    useAnalyticsPageViewTracker('faq', !!faq);
    const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqUi);

    return <FaqWithHandlers isLoading={isLoading} faq={faq} hasError={!!loadingError} />;
};
