import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import iconFooterReducer from 'components/footer/icon-footer/IconFooterSlice';
import legalFooterReducer from 'components/footer/legal-footer/LegalFooterSlice';
import myProfileReducer from 'components/my-profile/MyProfileSlice';
import greetingReducer from 'components/greeting/GreetingSlice';
import forbiddenErrorPageReducer from 'components/error-pages/forbidden/ForbiddenErrorPageSlice';
import notFoundErrorPageReducer from 'components/error-pages/not-found/NotFoundErrorPageSlice';
import unauthorizedErrorPageReducer from 'components/error-pages/unauthorized/UnauthorizedErrorPageSlice';
import cookiePolicyReducer from 'components/cookie-policy/CookiePolicySlice';
import legalNoticeReducer from 'components/legal-notice/LegalNoticeSlice';
import faqReducer from 'components/faq/FaqSlice';
import privacyPolicyReducer from 'components/privacy-policy/PrivacyPolicySlice';
import contractsReducer from 'components/contracts/ContractsSlice';
import contractDetailsReducer from 'components/contracts/contract-details/ContractDetailsSlice';
import requestReducer from 'components/request/RequestSlice';
import myDocumentsReducer from 'components/my-documents/MyDocumentsSlice';
import paymentDetailsReducer from 'components/payment-details/PaymentDetailsSlice';
import dashboardMarketingCardReducer from 'components/dashboard-marketing-card/DashboardMarketingCardSlice';
import dashboardMarketingCardReducerInsurance from 'components/dashboard-marketing-card-insurance/DashboardMarketingCardSliceInsurance';
import landingPageTeaserReducer from 'components/landing-page-teaser/LandingPageTeaserSlice';
import landingPageMarketingCardsReducer from 'components/landing-page-marketing-cards/LandingPageMarketingCardsSlice';
import logoutSuccessReducer from 'components/logout-success/LogoutSuccessSlice';
import genericErrorReducer from 'components/error-pages/generic/GenericErrorPageSlice';
import vehicleDetailsReducer from 'components/contracts/vehicle-details-and-image/VehicleDetailsSlice';
import vehicleImageReducer from 'components/contracts/vehicle-details-and-image/VehicleImageSlice';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    myProfile: myProfileReducer,
    contracts: contractsReducer,
    contractDetails: contractDetailsReducer,
    paymentDetails: paymentDetailsReducer,
    request: requestReducer,
    vehicleDetailsAndImage: combineReducers({
        vehicleImages: vehicleImageReducer,
        vehicleDetails: vehicleDetailsReducer,
    }),
    greeting: greetingReducer,
    myDocuments: myDocumentsReducer,
    content: combineReducers({
        iconFooter: iconFooterReducer,
        legalFooter: legalFooterReducer,
        notFoundErrorPage: notFoundErrorPageReducer,
        forbiddenErrorPage: forbiddenErrorPageReducer,
        unauthorizedErrorPage: unauthorizedErrorPageReducer,
        genericError: genericErrorReducer,
        cookiePolicy: cookiePolicyReducer,
        legalNotice: legalNoticeReducer,
        faq: faqReducer,
        privacyPolicy: privacyPolicyReducer,
        dashboardMarketingCard: dashboardMarketingCardReducer,
        dashboardMarketingCardInsurance: dashboardMarketingCardReducerInsurance,
        landingPageTeaser: landingPageTeaserReducer,
        landingPageMarketingCards: landingPageMarketingCardsReducer,
        logoutSuccess: logoutSuccessReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction): RootState => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
