import React from 'react';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-8/frontend-ui';
import { EditStatus, Identification } from 'common';
import { ConsultView } from './consult-view';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeIdentificationPath } from '../../navigation/paths';
import { FormView } from './edit-view/form-view';
import { NoConnectionView } from './edit-view/no-connection-view';
import { useUpdateMyProfile } from '../useUpdateMyProfile';
import { useGreeting } from '../../greeting';

export const IdentificationSection: React.FC<{ identification: Identification }> = ({ identification }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { data: greeting } = useGreeting();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();
    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileIdentification');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onSuccess } = useAnalyticsFormTracker({
        confirmSuccess: 'onEditProfileIdentificationSuccess',
    });

    const setLastEditStatus = (newEditStatus: EditStatus, updatedSurname?: string): void => {
        updateMyProfile({
            ...myProfileData,
            identification: updatedSurname
                ? {
                      ...identification,
                      fullName: `${greeting?.firstName} ${updatedSurname}`,
                  }
                : identification,
            lastEditStatus: { ...myProfileData?.lastEditStatus, identification: newEditStatus },
        });
    };

    const startEditing = (): void => {
        onStart();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeIdentificationPath());
    };

    const cancelEditing = (): void => {
        onCancel('Identification');
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus, updatedSurname?: string): void => {
        if (newEditStatus === EditStatus.SUCCESS) onSuccess();
        setLastEditStatus(newEditStatus, updatedSurname);
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeIdentificationPath()}>
                {greeting?.surname && greeting?.firstName ? (
                    <FormView
                        firstName={greeting.firstName}
                        surname={greeting.surname}
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                    />
                ) : (
                    <NoConnectionView cancelEditing={cancelEditing} />
                )}
            </Route>
            <Route path={path}>
                <ConsultView
                    identification={identification}
                    startEditing={startEditing}
                    lastEditStatus={myProfileData?.lastEditStatus?.identification}
                />
            </Route>
        </Switch>
    );
};
