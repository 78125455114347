import React from 'react';
import { MyDocumentsLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { MyDocumentsUi } from './ui';
import { useMyDocuments } from './useMyDocuments';
import { useContracts, useContract } from '../contracts';
import { useTranslation } from 'react-i18next';

export const MyDocuments: React.FC<{ contractId?: string }> = ({ contractId }) => {
    const { t } = useTranslation('my-documents');

    const {
        data: myDocumentsData,
        isLoading: isLoadingDocuments,
        loadingError: loadingDocumentsError,
    } = useMyDocuments();
    const { data: contracts, isLoading: isLoadingContracts, loadingError: loadingContractsError } = useContracts();
    const {
        data: contract,
        isLoading: isLoadingContract,
        loadingError: loadingContractError,
    } = useContract(contractId || '');

    const isLoading = isLoadingDocuments || isLoadingContracts || isLoadingContract;
    const hasError = !!loadingDocumentsError || !!loadingContractsError || !!loadingContractError;

    useAnalyticsPageViewTracker('postbox', !hasError);

    const MyDocumentsWithHandlers = withLoadingAndNoConnectionHandler(MyDocumentsUi);

    return (
        <MyDocumentsWithHandlers
            isLoading={isLoading}
            loadingPlaceholder={
                <>
                    <p className="u-text-center">{t('loading-placeholder')}</p>
                    <MyDocumentsLoadingPlaceholder />
                </>
            }
            myDocumentsData={myDocumentsData}
            contracts={contracts}
            defaultContractNumber={contract?.contractNumber}
            hasError={hasError}
        />
    );
};
