import { useMemo } from 'react';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-8/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';
import { Contract } from '../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';

type ContractTransformer = (contracts?: Contract[]) => Contract | undefined;

function getFilterForContractNumber(contractId?: string): ContractTransformer {
    return (contracts?: Contract[]): Contract | undefined =>
        contracts ? contracts.filter((contract) => contract.contractId === contractId)[0] : undefined;
}

/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId
 */
export function useContract(
    contractId: string,
): AbstractDataState<Contract | undefined, DefaultBusinessMarketApiErrorCode> {
    const contractFilter = useMemo(() => getFilterForContractNumber(contractId), [contractId]);
    return useGetSimpleApiDataWithTransformer(fetchContracts, ContractsDataSelector, contractFilter);
}
