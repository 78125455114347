import React from 'react';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-8/frontend-ui';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { BaseAddress, EditStatus, AddressType } from 'common';
import { ConsultView } from './consult-view';
import { changePostalAddressPath, changeFiscalAddressPath } from '../../navigation/paths';
import { EditView } from './edit-view';
import { useUpdateMyProfile } from '../useUpdateMyProfile';

export const AddressSection: React.FC<{ address?: BaseAddress; addressType: AddressType }> = ({
    address,
    addressType,
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();
    const reduxFieldName = addressType === AddressType.POSTAL ? 'postalAddress' : 'fiscalAddress';
    const lastEditStatus = myProfileData?.lastEditStatus?.addresses?.[reduxFieldName];

    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileAddress');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onSuccess } = useAnalyticsFormTracker({
        confirmSuccess: 'onEditProfileAddressSuccess',
    });

    const setLastEditStatus = (newEditStatus: EditStatus, updatedAddress?: BaseAddress): void => {
        updateMyProfile({
            ...myProfileData,
            addresses: {
                ...myProfileData.addresses,
                [reduxFieldName]: updatedAddress ?? address,
            },
            lastEditStatus: {
                ...myProfileData?.lastEditStatus,
                addresses: {
                    ...myProfileData?.lastEditStatus?.addresses,
                    [reduxFieldName]: newEditStatus,
                },
            },
        });
    };

    const startEditing = (): void => {
        onStart();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        if (addressType === AddressType.POSTAL) history.push(changePostalAddressPath());
        else history.push(changeFiscalAddressPath());
    };

    const cancelEditing = (): void => {
        onCancel('Address');
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus, updatedAddress?: BaseAddress): void => {
        if (newEditStatus === EditStatus.SUCCESS) onSuccess();
        setLastEditStatus(newEditStatus, updatedAddress);
        history.push(path);
    };

    return (
        <Switch>
            {addressType === AddressType.POSTAL && (
                <Route exact path={changePostalAddressPath()}>
                    <EditView
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        address={address}
                        addressType={addressType}
                    />
                </Route>
            )}
            {addressType === AddressType.FISCAL && (
                <Route exact path={changeFiscalAddressPath()}>
                    <EditView
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        address={address}
                        addressType={addressType}
                    />
                </Route>
            )}
            <Route path={path}>
                <ConsultView
                    address={address}
                    addressType={addressType}
                    startEditing={startEditing}
                    lastEditStatus={lastEditStatus}
                />
            </Route>
        </Switch>
    );
};
