import React from 'react';
import { Button, DataOverview } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus, withAutoScroll } from '@cp-shared-8/frontend-ui';

export const NoConnectionView: React.FC<{ cancelEditing: () => void }> = withAutoScroll(({ cancelEditing }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'identification.edit';
    const closeButtonProps = {
        onClick: cancelEditing,
        className: 'c-icon  c-icon--[semantic-close]',
        testId: 'x-button',
    };
    return (
        <DataOverview
            title={t(`${translationPrefix}.form-view.headline`)}
            buttonLabel={' '}
            buttonProps={closeButtonProps}
        >
            <Notification
                status={NotificationStatus.error}
                testId={'no-connection'}
                text={t(`${translationPrefix}.notification.no-connection-error.text`)}
            />

            <Button secondary onClick={cancelEditing} testId="cancel-button" type="button" className={'u-mt'}>
                {t('translation:editable-section-nav.back')}
            </Button>
        </DataOverview>
    );
}, 'IdentificationNoConnectionEditView');
