import React from 'react';
import { VehicleDetails } from '../../../../../common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import {
    TranslationFormat,
    useTranslationWithFormatting,
} from '../../../../../localization/useTranslationWithFormatting';

export const VehicleDetailsUi: React.FC<{ vehicleDetailsData?: VehicleDetails }> = ({ vehicleDetailsData }) => {
    const { t, f } = useTranslationWithFormatting('vehicle-details');

    if (!vehicleDetailsData) {
        return null;
    }

    const { brand, model, modelDescription, licensePlate, registrationDate, vinCode } = vehicleDetailsData;
    const noValuePlaceholder = '-';

    const itemList: DefinitionListItem[] = [
        {
            label: t('model'),
            value: !!brand ? `${brand} ${model || ''}` : noValuePlaceholder,
            testId: `vehicle-details-model`,
        },
        {
            label: t('version'),
            value: !!brand && !!modelDescription ? modelDescription : noValuePlaceholder,
            testId: `vehicle-details-version`,
        },
        {
            label: t('license-plate'),
            value: licensePlate?.toUpperCase() || noValuePlaceholder,
            testId: `vehicle-details-license-plate`,
        },
        {
            label: t('registration-date'),
            value: !!registrationDate ? f(registrationDate, TranslationFormat.DATE) : noValuePlaceholder,
            testId: `vehicle-details-registration-date`,
        },
        {
            label: t('vin-code'),
            value: vinCode || noValuePlaceholder,
            testId: `vehicle-details-vin-code`,
        },
    ];
    return itemList && <DefinitionListHorizontal list={itemList} />;
};
