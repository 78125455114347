import React from 'react';
import { Redirect } from 'react-router-dom';
import {
    createAuthenticationComponent,
    createAuthorization,
    createDecoratedRoute,
    Spinner,
} from '@cp-shared-8/frontend-ui';
import { landingPagePath, registrationPagePath, errorPagePath } from '../components/navigation/paths';
import { getUserRegistryStatusEndpoint, UserRegistryStatus } from 'common';
import { CpDataApi } from '../cp-xhr';
import { getUrlSearchParams } from '../utils/getUrlSearchParams';

const errorTypeParam = getUrlSearchParams().get('errorType');

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <Redirect to={landingPagePath()} />,
});

const authDataProvider = async (): Promise<{ isAuthorized: boolean }> => {
    try {
        const { data: registrationData } = await CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
        return { isAuthorized: registrationData.isRegistered };
    } catch (e) {
        return { isAuthorized: false };
    }
};

const { Authorization: UserRegistrationAuthorization } = createAuthorization({
    displayName: 'UserRegistrationAuthorization',
    authorizationDataProvider: authDataProvider,
    onMissingAuthorization: !errorTypeParam ? (
        <Redirect to={registrationPagePath()} />
    ) : (
        <Redirect to={`${errorPagePath()}?errorType=${errorTypeParam}`} />
    ),
    onLoading: <Spinner center />,
    onError: !errorTypeParam ? (
        <Redirect to={landingPagePath()} />
    ) : (
        <Redirect to={`${errorPagePath()}?errorType=${errorTypeParam}`} />
    ),
});

export const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
export const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', Authentication, UserRegistrationAuthorization);
