import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { EditStatus } from '../enums';
import { useTranslation } from 'react-i18next';

type IbanChangeNotificationProps = {
    lastEditStatus: EditStatus;
};

type NotificationProps = {
    testId: string;
    status: NotificationStatus;
    headline?: string;
    text: string;
};

export const IbanChangeNotification: React.FC<IbanChangeNotificationProps> = ({ lastEditStatus }) => {
    const { t } = useTranslation('financial-details');
    if (lastEditStatus === EditStatus.NOT_PERFORMED) {
        return null;
    }

    const prefix = 'iban-section.notification';

    const getNotificationProps = (): NotificationProps => {
        switch (lastEditStatus) {
            case EditStatus.ERROR:
                return {
                    testId: 'iban-change-error-notification',
                    status: NotificationStatus.error,
                    text: t(`${prefix}.error.text`),
                };
            case EditStatus.SUCCESS:
                return {
                    testId: 'iban-change-success-notification',
                    status: NotificationStatus.warning,
                    headline: t(`${prefix}.success.headline`),
                    text: t(`${prefix}.success.text`),
                };
        }
    };

    return <Notification {...getNotificationProps()} />;
};
