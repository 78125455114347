import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { MyDocuments } from '../../components/my-documents';

export const MyDocumentsPage: React.FC = () => {
    const { t } = useTranslation('my-documents');
    const { contractId } = useParams<{ contractId: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <MyDocuments contractId={contractId} />
        </ContentSection>
    );
};
