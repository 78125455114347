import React, { useState } from 'react';
import { EditStatus, FinancialDetails, ProductTypeEN } from '../../../../../common';
import { Hr } from '@vwfs-bronson/bronson-react';
import { changeMileagePath } from '../../../../navigation/paths';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ConsultView } from './consult-view';
import { EditView } from './edit-view/EditView';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';

type MileageSectionProps = {
    details?: FinancialDetails;
    isActive: boolean;
    productTypeEN?: ProductTypeEN;
    contractId: string;
};

export const MileageSection: React.FC<MileageSectionProps> = ({ details, isActive, productTypeEN, contractId }) => {
    const history = useHistory();
    const { path } = useRouteMatch();

    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    const { onAction: trackOnStart } = useAnalyticsActionTracker('financialDetailsEditMileageSection');
    const { onAction: trackOnCancel } = useAnalyticsActionTracker('financialDetailsEditMileageSectionCancel');

    if (!details?.lastMileage || !productTypeEN || productTypeEN === ProductTypeEN.FINANCIAL || !isActive) {
        return null;
    }

    const startEditing = (): void => {
        trackOnStart();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeMileagePath(contractId));
    };

    const cancelEditing = (): void => {
        trackOnCancel();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus): void => {
        setLastEditStatus(newEditStatus);
        history.push(path);
    };

    const isEditable = productTypeEN === ProductTypeEN.OPERATIVE_LEASING;

    return (
        <>
            <Hr className="u-mb-none" />
            <Switch>
                <Route exact path={changeMileagePath(contractId)}>
                    <EditView
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        contractId={contractId}
                        lastMileage={details.lastMileage}
                    />
                </Route>
                <Route path={path}>
                    <ConsultView
                        details={details}
                        startEditing={startEditing}
                        lastEditStatus={lastEditStatus}
                        isEditable={isEditable}
                    />
                </Route>
            </Switch>
        </>
    );
};
