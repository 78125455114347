import React, { useState } from 'react';
import { FinancialDetails } from '../../../../../common';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ConsultView } from './consult-view';
import { EditView } from './edit-view';
import { EditStatus } from './enums';
import { changeIbanPath } from '../../../../navigation/paths';

type IbanSectionProps = {
    details?: FinancialDetails;
    isActive?: boolean;
    contractId: string;
    isFirstHolder: boolean;
};

export const IbanSection: React.FC<IbanSectionProps> = ({ details, isActive, contractId, isFirstHolder }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const { onAction } = useAnalyticsActionTracker('financialDetailsEditIbanSection');
    const { onAction: onEditSuccess } = useAnalyticsActionTracker('financialDetailsEditIbanSectionSuccess');
    const { onAction: onEditFail } = useAnalyticsActionTracker('financialDetailsEditIbanSectionSubmitFailed');

    if (!details) {
        return null;
    }

    const isChangeEnabled = isActive && isFirstHolder;

    const startEditing = (): void => {
        if (isChangeEnabled) {
            setLastEditStatus(EditStatus.NOT_PERFORMED);
            onAction();
            history.push(changeIbanPath(contractId));
        }
    };

    const finishEditing = (lastEditStatus: EditStatus): void => {
        setLastEditStatus(lastEditStatus);
        history.push(path);
    };

    const onSuccess = (lastEditStatus: EditStatus): void => {
        onEditSuccess();
        finishEditing(lastEditStatus);
    };

    const onFail = (lastEditStatus: EditStatus): void => {
        onEditFail();
        finishEditing(lastEditStatus);
    };

    return (
        <Switch>
            {isChangeEnabled && (
                <Route exact path={changeIbanPath(contractId)}>
                    <EditView iban={details.iban} onSuccess={onSuccess} onFail={onFail} contractId={contractId} />
                </Route>
            )}
            <Route path={path}>
                <ConsultView
                    details={details}
                    startEditing={startEditing}
                    lastEditStatus={lastEditStatus}
                    isChangeEnabled={isChangeEnabled}
                />
            </Route>
        </Switch>
    );
};
