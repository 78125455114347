import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { phoneValidator } from '../../../../utils';

export const validationSchema = (t: TFunction) => {
    const translationPrefix = 'contact-details.edit.form-view.validation';

    const phoneValidation = Yup.string()
        .trim()
        .test('is valid phone number', t(`${translationPrefix}.phone.invalid-format`), (value?: string) =>
            phoneValidator(value),
        );

    return Yup.object().shape({
        email: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.email.empty`))
            .email(t(`${translationPrefix}.email.invalid-format`)),
        mobilePhone: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.phone.empty`))
            .concat(phoneValidation),
        otherPhone: phoneValidation,
    });
};
