import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestCategories, RequestCategory } from 'common';
import { kebabCase } from 'lodash';
import { ContractSelection } from '../initialValues';
import { Combobox, FormField, FormFieldLabel } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import { ComboboxOption } from '../../../../types/bronson-react';

type CategorySelectionProps = {
    contractSelection: ContractSelection;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    resetForm: (...args: any[]) => any;
    currentCategorySelection: string;
    isForContact?: boolean;
    dirty?: boolean;
};

export const CategorySelection: React.FC<CategorySelectionProps> = () => {
    const [, , helpers] = useField('categorySelection');
    const { t } = useTranslation('request');
    const mandatoryFieldsInfo = t('category-selection.mandatory-fields-info');

    const setValueChange = useCallback((selectedContractsOptions: ComboboxOption[]) => {
        return helpers.setValue(selectedContractsOptions[0].key);
    }, []);

    const options = RequestCategories.map(({ categoryId, categoryDescription }: RequestCategory) => {
        return (
            <Combobox.Item
                testId={`category-selection-item-${categoryId}`}
                optionValue={t(`category-selection.options.${kebabCase(categoryDescription)}`)}
                optionKey={`${categoryId}`}
                key={`${categoryId}`}
            >
                <>{t(`category-selection.options.${kebabCase(categoryDescription)}`)}</>
            </Combobox.Item>
        );
    });

    const mandatoryLabel = `${t('category-selection.label')} *`;
    return (
        <>
            <p>{mandatoryFieldsInfo}</p>
            <FormField type="select" testId={'category-selection'} id={'category-selection'} notion>
                <FormFieldLabel>{mandatoryLabel}</FormFieldLabel>
                <Combobox
                    name={'categorySelection'}
                    testId={'custom-category-select'}
                    placeholder={t('category-selection.placeholder')}
                    key={'categorySelection'}
                    onChange={setValueChange}
                >
                    {options}
                </Combobox>
            </FormField>
        </>
    );
};
