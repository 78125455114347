import React from 'react';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useRequest } from './useRequest';
import { FormView } from './form-view/FormView';

export const Request: React.FC<{ isForContact?: boolean }> = () => {
    const { data: contracts, isLoading: isContractsLoading, loadingError: contractsLoadingError } = useRequest();
    useAnalyticsPageViewTracker('requests', !!contracts);
    const RequestWithHandlers = withLoadingAndNoConnectionHandler(FormView);

    return (
        <RequestWithHandlers isLoading={isContractsLoading} contracts={contracts} hasError={!!contractsLoadingError} />
    );
};
