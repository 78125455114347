import React, { useState, useCallback } from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useContracts } from './useContracts';
import { ContractsUi } from './ui';

export const Contracts: React.FC = () => {
    const { data: contracts, isLoading, loadingError } = useContracts();
    const [unpaids, setUnpaids] = useState<Record<string, boolean>>({});

    const allUnpaidsSet = Object.values(unpaids).length === contracts?.length;
    const hasUnpaid = Object.values(unpaids).some((value) => value === true);

    const setUnpaid = useCallback(
        (contractId: string, unpaid: boolean) => {
            if (unpaids[contractId] !== unpaid) setUnpaids({ ...unpaids, [contractId]: unpaid });
        },
        [unpaids],
    );

    const ContractsWithHandlers = withLoadingAndNoConnectionHandler(ContractsUi);

    return (
        <ContractsWithHandlers
            isLoading={isLoading}
            contracts={contracts}
            hasError={!!loadingError}
            allUnpaidsSet={allUnpaidsSet}
            hasUnpaid={hasUnpaid}
            setUnpaid={setUnpaid}
        />
    );
};
