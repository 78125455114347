import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    BundledProducts,
    Contract,
    ContractDetailsData,
    isErrorDetailsData,
    isSuccessDetailsData,
} from '../../../../common';
import { AccordionItem } from '@cp-shared-8/frontend-ui';
import { BundledProductsUi } from './ui/BundledProductsUi';
import { isEmpty } from 'lodash';
import { withNoConnectionHandler } from '../../../integration-wrapper';

type BundledProductsProps = {
    contract?: Contract;
    bundledProducts?: ContractDetailsData<BundledProducts>;
};

export const BundledProductsDetails: React.FC<BundledProductsProps> = ({ bundledProducts }) => {
    const { t } = useTranslation('bundled-products');
    if (!bundledProducts || (isSuccessDetailsData(bundledProducts) && isEmpty(bundledProducts.data))) return null;

    const hasError = !!bundledProducts && isErrorDetailsData(bundledProducts);

    const BundledProductsWithHandlers = withNoConnectionHandler(BundledProductsUi);

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <BundledProductsWithHandlers bundledProducts={bundledProducts?.data} hasError={hasError} />
        </AccordionItem>
    );
};
