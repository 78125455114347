import { createCmsContentSlice } from 'cms-integration';
import { getDashboardMarketingCardEndpointInsurance, MarketingCard } from '../../common';

const { reducer, fetchContent } = createCmsContentSlice<MarketingCard>({
    contentName: 'dashboardMarketingCardInsurance',
    contentEndpoint: getDashboardMarketingCardEndpointInsurance,
});

export default reducer;
export const fetchDashboardMarketingCardInsurance = fetchContent;
