import React from 'react';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { FinancialDetails, IndexDescriptionType, InterestRateType, ProductTypeEN } from '../../../../../common';
import { Hr } from '@vwfs-bronson/bronson-react';

type FinancialSectionProps = {
    details?: FinancialDetails;
    isActive: boolean;
    productTypeEN?: ProductTypeEN;
};

export const FinancialSection: React.FC<FinancialSectionProps> = ({ details, isActive, productTypeEN }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');

    if (!details || !isActive || !productTypeEN || productTypeEN === ProductTypeEN.MAINTENANCE) {
        return null;
    }
    const {
        nominalAmount,
        interestRate,
        interestRateType,
        indexDescription,
        nextIndexingDate,
        amortizedCapital,
        pendingCapital,
    } = details;

    const noValuePlaceholder = '-';

    const translatedInterestRateType = !interestRateType
        ? noValuePlaceholder
        : interestRateType === InterestRateType.FIXED
        ? t('financial-section.interest-rate-type.fixed')
        : t('financial-section.interest-rate-type.variable');

    const operativeLeasingItemList: DefinitionListItem[] =
        interestRateType === InterestRateType.VARIABLE
            ? [
                  {
                      label: t('financial-section.interest-rate'),
                      value: interestRate ? f(interestRate, TranslationFormat.PERCENTAGE) : noValuePlaceholder,
                      testId: 'interest-rate',
                  },
                  {
                      label: t('financial-section.interest-rate-type.label'),
                      value: translatedInterestRateType,
                      testId: 'interest-rate-type',
                  },
              ]
            : [];

    const financialItemList: DefinitionListItem[] = [
        {
            label: t('financial-section.nominal-amount.label'),
            tooltip: t('financial-section.nominal-amount.tooltip'),
            value: nominalAmount ? f(nominalAmount, TranslationFormat.CURRENCY) : noValuePlaceholder,
            testId: 'nominal-amount',
        },
        {
            label: t('financial-section.index-description.label'),
            value:
                indexDescription === IndexDescriptionType.EUR3M
                    ? t('financial-section.index-description.EUR3M')
                    : noValuePlaceholder,
            testId: 'index-description',
        },
        {
            label: t('financial-section.next-indexing-date'),
            value: nextIndexingDate ? f(nextIndexingDate, TranslationFormat.DATE) : noValuePlaceholder,
            testId: 'next-indexing-date',
        },
        ...operativeLeasingItemList,
        {
            label: t('financial-section.amortized-capital'),
            value: amortizedCapital ? f(amortizedCapital, TranslationFormat.CURRENCY) : noValuePlaceholder,
            testId: 'amortized-capital',
        },
        {
            label: t('financial-section.pending-capital'),
            value: pendingCapital ? f(pendingCapital, TranslationFormat.CURRENCY) : noValuePlaceholder,
            testId: 'pending-capital',
        },
    ];

    const itemList: DefinitionListItem[] =
        productTypeEN === ProductTypeEN.FINANCIAL ? financialItemList : operativeLeasingItemList;

    const allValuesMissing = !itemList.filter(({ value }) => value !== noValuePlaceholder).length;

    return !!itemList.length || !allValuesMissing ? (
        <>
            <Hr className="u-mb-small" />
            <DefinitionListHorizontal list={itemList} />
        </>
    ) : null;
};
