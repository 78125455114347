import { PaymentDetails } from 'common';
import { formatCpDate } from '@cp-shared-8/common-utilities';

export const PaymentDetailsWithAllValues: PaymentDetails = {
    totalUnpaidAmount: 123123.15,
    paymentDetailsEntity: 12345,
    paymentDetailsReference: '023456',
    nominalAmount: 234.5,
    arrearsAmount: 123,
    returnAmount: 45,
    interestArrearsRate: 78.9,
    pendingDocuments: 3,
    nextDueDate: formatCpDate('2021-03-11T09:44:57.299').toCpDate(),
};

export const PaymentDetailsWithMissingValues: PaymentDetails = {
    totalUnpaidAmount: 123123.15,
    paymentDetailsEntity: 12345,
    returnAmount: 45,
    interestArrearsRate: 78.9,
    pendingDocuments: 3,
};
