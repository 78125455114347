import { Addresses, BaseAddress, ContactDetails, Identification, MyProfileData } from 'common';

export const IdentificationWithAllValues: Identification = {
    fullName: 'Maria Aranzazu',
    fiscalNumber: '123456789',
    idCardNumber: '10593256',
};

export const IdentificationWithMissingValues: Identification = {
    fiscalNumber: '123456789',
};

export const MyProfileWithMissingValues: MyProfileData = {
    identification: IdentificationWithMissingValues,
};
export const ContactDetailsWithAllValues: ContactDetails = {
    email: 'oliver.prager@abc.io',
    mobilePhone: '0123123123',
    otherPhone: '0903474706',
};

export const ContactDetailsWithOnlyMobilePhone: ContactDetails = {
    mobilePhone: '0123123123',
};

export const ContactDetailsWithNoValues: ContactDetails = {};

export const BaseAddressWithAllValues: BaseAddress = {
    roadType: 'RUA',
    streetName: 'DA MARTHINA',
    houseNumber: '10',
    block: 'C',
    floor: '1',
    door: 'B',
    place: 'botequim',
    zipCode: '2850-150',
    localityCity: 'LISBOA',
    country: 'PORTUGAL',
};

export const BaseAddressWithMissingValues: BaseAddress = {
    roadType: 'rua',
    streetName: 'Da Marthina',
    door: 'B',
    zipCode: '2850-150',
};

export const BaseAddressWithMissingValuesForOneLine: BaseAddress = {
    roadType: 'RUA',
    streetName: 'DA MARTHINA',
    houseNumber: '10',
    block: 'C',
    floor: '1',
    door: 'B',
    place: 'botequim',
};

export const AddressesWithAllValues: Addresses = {
    postalAddress: BaseAddressWithAllValues,
    fiscalAddress: BaseAddressWithAllValues,
};

export const AddressesWithMissingValues: Addresses = {
    postalAddress: BaseAddressWithMissingValues,
};

export const MyProfileWithAllValues: MyProfileData = {
    identification: IdentificationWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
    addresses: AddressesWithAllValues,
};

export const MyProfileWithNoData: MyProfileData = {
    identification: {},
};
