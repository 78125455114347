import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { Button } from '@vwfs-bronson/bronson-react';
import { useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { useHistory } from 'react-router-dom';
import { requestAppointmentForCarReturnPath } from '../../navigation/paths';

export const RequestAppointmentForCarReturnNotification: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { t } = useTranslationWithFormatting('contracts');
    const history = useHistory();

    return (
        <Notification
            status={NotificationStatus.warning}
            headline={t('request-appointment-for-car-return.headline')}
            text={t('request-appointment-for-car-return.text')}
            testId={'request-appointment-for-car-return-alert'}
            className={'u-mb-small'}
        >
            <Button
                type={'button'}
                secondary
                className="u-bg-white u-mt "
                testId={'request-appointment-for-car-return-button'}
                onClick={() => {
                    history.push(requestAppointmentForCarReturnPath(contractId));
                }}
            >
                {t('request-appointment-for-car-return.button')}
            </Button>
        </Notification>
    );
};
