import React from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';

export type NoConnectionNotificationProps = {
    testId?: string;
    className?: string;
};

export const NoConnectionNotification: React.FC<NoConnectionNotificationProps> = ({ testId, className }) => {
    const { t } = useTranslation();
    return (
        <Notification
            status={NotificationStatus.error}
            headline={t('error.no-connection.title')}
            text={t('error.no-connection.text')}
            testId={testId}
            className={className}
        />
    );
};
