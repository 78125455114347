import { FinancialDetails } from './financial-details';
import { VehicleDetails } from './vehicle-details';
import { ContractParties } from './contract-parties';
import { BundledProducts } from './bundled-products';

export enum FetchStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export type ContractDetails = {
    financialDetails: ContractDetailsData<FinancialDetails>;
    vehicleDetails: ContractDetailsData<VehicleDetails>;
    contractParties: ContractDetailsData<ContractParties>;
    bundledProducts: ContractDetailsData<BundledProducts>;
};
export interface ContractDetailsData<T> {
    data?: T;
    status: FetchStatus;
}
export interface SuccessDetailsData<T> extends ContractDetailsData<T> {
    data: T;
    status: FetchStatus.SUCCESS;
}
export interface ErrorDetailsData<T> extends ContractDetailsData<T> {
    data: undefined;
    status: FetchStatus.ERROR;
}

export function isSuccessDetailsData<T>(arg: ContractDetailsData<T>): arg is SuccessDetailsData<T> {
    return arg.data !== undefined && arg.status === FetchStatus.SUCCESS;
}
export function isErrorDetailsData<T>(arg: ContractDetailsData<T>): arg is ErrorDetailsData<T> {
    return arg.data === undefined && arg.status === FetchStatus.ERROR;
}
