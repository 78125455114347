import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { getDealersForBrand, phoneNumberRequiredValidator } from 'common';

export const validationSchema = (t: TFunction, brand: string) => {
    return Yup.object().shape({
        dealer: Yup.string()
            .trim()
            .required(t('validation-error.dealer-missing'))
            .test('validDealer', t('validation-error.dealer-invalid'), (value?: string): boolean => {
                return getDealersForBrand(brand).some((dealer: string) => dealer === value);
            }),
        date: Yup.string().trim().required(t('validation-error.date-missing')),
        time: Yup.string().trim().required(t('validation-error.time-missing')),
        phoneNumber: phoneNumberRequiredValidator({
            required: t('validation-error.phone-number-missing'),
            valid: t('validation-error.phone-number-invalid'),
        }),
        comment: Yup.string().trim().max(1000, t('validation-error.comment-to-long')),
    });
};
