import { CPDate } from '@cp-shared-8/common-utilities';

export enum ProductTypeEN {
    FINANCIAL = 'FINANCIAL',
    MAINTENANCE = 'MAINTENANCE',
    OPERATIVE_LEASING = 'OPERATIVE_LEASING',
}

export enum CustomerRole {
    FIRST_HOLDER = 'First Holder',
    SECOND_HOLDER = 'Second Holder',
    GUARANTOR = 'Guarantor',
}

export type Contract = {
    isActive: boolean;
    contractId: string;
    contractNumber?: string;
    productType?: string;
    productTypeEN?: ProductTypeEN;
    brand?: string;
    model?: string;
    licensePlate?: string;
    vin?: string;
    encryptedVin?: string;
    nextDueDate?: CPDate;
    nextDueAmount?: number;
    outstandingBalance?: number;
    endDate?: CPDate;
    terminationType?: string;
    customerRole?: CustomerRole;
    hasDigitalInvoice?: boolean;
    litigationState?: boolean;
    amountsInOverdue?: number;
};

export type IbanError = 'BFF_API_ERROR' | 'CHECK_FAILURE';
