import React, { useState } from 'react';
import { Button, ButtonContainer, DataOverview, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { CpDataApi } from 'cp-xhr';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
    preventSubmit,
    ValidatedInput,
    withAutoScroll,
    UiBlockingSpinner,
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { EditStatus, BaseAddress, AddressType, getAddressEndpoint, UpdateAddress } from 'common';
import { validationSchema } from './validationSchema';

type EditViewProps = {
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedAddress?: BaseAddress) => void;
    address?: BaseAddress;
    addressType: AddressType;
};

type FormValues = {
    roadType: string;
    streetName: string;
    houseNumber: string;
    block: string;
    floor: string;
    door: string;
    place: string;
    zipCode: string;
    localityCity: string;
    country: string;
};

export const EditView: React.FC<EditViewProps> = withAutoScroll(
    ({ cancelEditing, finishEditing, address = {}, addressType }) => {
        const [isSubmitting, setIsSubmitting] = useState(false);

        const { t } = useTranslation('my-profile');
        const translationPrefix = 'addresses.edit.form-view';
        const translationSuffix = addressType === AddressType.FISCAL ? 'fiscal-address' : 'postal-address';

        const { onAction: onStartTyping } = useAnalyticsActionTracker('onEditProfileAddressTypedIn');
        const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileAddressValidationError');
        const { onAction: onFail } = useAnalyticsActionTracker('onEditProfileAddressSubmitFailed');
        useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, 'Address');

        const {
            roadType = '',
            streetName = '',
            houseNumber = '',
            block = '',
            floor = '',
            door = '',
            place = '',
            zipCode = '',
            localityCity = '',
            country = '',
        } = address;

        const initialValues: FormValues = {
            roadType,
            streetName,
            houseNumber,
            block,
            floor,
            door,
            place,
            zipCode,
            localityCity,
            country,
        };

        const onSubmit = (values: FormValues) => {
            setIsSubmitting(true);
            const body: UpdateAddress = {
                addressType,
                address: values,
            };
            CpDataApi.put(getAddressEndpoint(), body)
                .then(() => {
                    finishEditing(EditStatus.SUCCESS, values);
                })
                .catch(() => {
                    onFail();
                    finishEditing(EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        return (
            <DataOverview title={t(`${translationPrefix}.headline.${translationSuffix}`)}>
                <Notification
                    status={NotificationStatus.info}
                    text={t(`${translationPrefix}.info`)}
                    testId={'address-change-info'}
                    className="u-mb"
                />
                <Formik initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={onSubmit}>
                    {({ submitForm, errors, touched }) => (
                        <Form
                            onSubmit={preventSubmit}
                            data-testid="edit-form"
                            onChange={() => {
                                onStartTyping();
                            }}
                        >
                            <UiBlockingSpinner isBlocking={isSubmitting}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/3" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.road-type.label`)}
                                                    tooltip={t(`${translationPrefix}.road-type.tooltip`)}
                                                    name="roadType"
                                                    testId="roadType"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/3" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.street-name.label`)}
                                                    tooltip={t(`${translationPrefix}.street-name.tooltip`)}
                                                    name="streetName"
                                                    testId="streetName"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/3" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.house-number.label`)}
                                                    tooltip={t(`${translationPrefix}.house-number.tooltip`)}
                                                    name="houseNumber"
                                                    testId="houseNumber"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/4" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.block.label`)}
                                                    tooltip={t(`${translationPrefix}.block.tooltip`)}
                                                    name="block"
                                                    testId="block"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/4" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.floor.label`)}
                                                    tooltip={t(`${translationPrefix}.floor.tooltip`)}
                                                    name="floor"
                                                    testId="floor"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/4" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.door.label`)}
                                                    tooltip={t(`${translationPrefix}.door.tooltip`)}
                                                    name="door"
                                                    testId="door"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/4" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.place.label`)}
                                                    tooltip={t(`${translationPrefix}.place.tooltip`)}
                                                    name="place"
                                                    testId="place"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/4" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.zip-code.label`)}
                                                    name="zipCode"
                                                    testId="zipCode"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/2" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.locality-city.label`)}
                                                    name="localityCity"
                                                    testId="localityCity"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/4" s="1/1">
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.country.label`)}
                                                    name="country"
                                                    testId="country"
                                                    type="text"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer center>
                                            <Button
                                                secondary
                                                onClick={cancelEditing}
                                                testId="cancel-button"
                                                type="button"
                                            >
                                                {t(`${translationPrefix}.cancel`)}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    if (isEmpty(touched) && isEmpty(errors))
                                                        onValidationError({ address: 'address' });
                                                    if (!isEmpty(errors)) onValidationError(errors);
                                                    submitForm();
                                                }}
                                                testId="submit-button"
                                                type="submit"
                                            >
                                                {t(`${translationPrefix}.submit`)}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </UiBlockingSpinner>
                        </Form>
                    )}
                </Formik>
            </DataOverview>
        );
    },
    'AddressEditView',
);
