export function getMyProfileEndpoint(): string {
    return `/my-profile`;
}

export function getIdentificationEndpoint(): string {
    return `${getMyProfileEndpoint()}/identification`;
}

export function getAddressEndpoint(): string {
    return `${getMyProfileEndpoint()}/address`;
}

export function getContactEndpoint(): string {
    return `${getMyProfileEndpoint()}/contact`;
}
