import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { LogoutSuccessUi } from './ui';
import { useLogoutSuccess } from './useLogoutSuccess';

const LogoutWithHandlers = withLoadingAndNoConnectionHandler(LogoutSuccessUi);

export const LogoutSuccess: React.FC = () => {
    const { cmsContent: logoutSuccess, isLoading, loadingError } = useLogoutSuccess();

    return <LogoutWithHandlers logoutSuccess={logoutSuccess} isLoading={isLoading} hasError={!!loadingError} />;
};
