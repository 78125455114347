import { ProductTypeEN, ContractInfo } from 'common';

export const ContractsInfoWithAllValues: ContractInfo[] = [
    {
        isActive: true,
        contractId: '23456789',
        contractNumber: '234567',
        productType: 'Contrato de Auto Crédito',
        productTypeEN: ProductTypeEN.FINANCIAL,
        brand: 'VW',
        model: 'Golf',
        licensePlate: '12-AB-34',
    },
    {
        isActive: true,
        contractId: '123456789',
        contractNumber: '1234567',
        productType: 'Contrato de Manutenção',
        productTypeEN: ProductTypeEN.MAINTENANCE,
        brand: 'VW',
        model: 'Golf',
        licensePlate: '56-AB-78',
    },
];

export const ContractsInfoWithMissingValues: ContractInfo[] = [
    {
        isActive: true,
        contractId: '23456789',
        contractNumber: '234567',
    },
    {
        isActive: true,
        contractId: '123456789',
        contractNumber: '1234567',
        productType: 'Contrato de Manutenção',
        licensePlate: '56-AB-78',
    },
];

export const ContractsInfoWithExpiredContract: ContractInfo[] = [
    {
        isActive: false,
        contractId: '23456789',
        contractNumber: '234567',
        productType: 'Contrato de Auto Crédito',
        productTypeEN: ProductTypeEN.FINANCIAL,
        brand: 'VW',
        model: 'Golf',
        licensePlate: '56-AB-78',
    },
];
