import React from 'react';
import { SimplePage } from '../../../common';
import { CookiePolicy as CookiePolicyShared, HeroImage } from '@cp-shared-8/frontend-ui';
import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';

export const CookiePolicyUi: React.FC<{ cookiePolicy?: SimplePage }> = ({ cookiePolicy }) => {
    if (!cookiePolicy) {
        return null;
    }

    const { teaser, content } = cookiePolicy;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={false} />
    ) : null;

    return (
        <>
            {teaserComponent}
            <ContentSection>
                <PageWrap size={'medium'}>
                    <CookiePolicyShared textAboveTable={content.text} title={content.title} />
                </PageWrap>
            </ContentSection>
        </>
    );
};
