import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { Contract } from '../../../common';
import { ContractDetailsUi } from './ui/ContractDetailsUi';
import { useContractDetails } from './useContractDetails';

export const ContractDetails: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { data: contractDetails, isLoading, loadingError } = useContractDetails(contract.contractId);

    const ContractDetailsWithHandlers = withLoadingAndNoConnectionHandler(ContractDetailsUi);

    return (
        <div className="u-mt-small">
            <ContractDetailsWithHandlers
                isLoading={isLoading}
                contract={contract}
                contractDetails={contractDetails}
                hasError={!!loadingError}
            />
        </div>
    );
};
