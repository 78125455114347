// The following URLs needs to be whitelisted within IDK (including domain and port)
export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function faqPagePath(): string {
    return '/faq';
}

export function privacyPolicyPath(): string {
    return '/privacy-policy';
}

export function cookiePolicyPath(): string {
    return '/cookie-policy';
}

export function civicToolCookiePolicyPath(): string {
    return '/page/cookiePolicy';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function landingPagePath(): string {
    return '/';
}

export function loginRedirectPagePath(): string {
    return '/login-redirect';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function myDocumentsPagePath(): string {
    return '/my-documents';
}

export function myDocumentsWithContractIdPagePath(contractId = ':contractId'): string {
    return `${myDocumentsPagePath()}/${contractId}`;
}

export function myRequestsPagePath(): string {
    return '/my-requests';
}

export function changeIdentificationPath(): string {
    return `${myProfilePagePath()}/change-identification`;
}

export function changePaymentDayPath(contractId: string): string {
    return `${dashboardPagePath()}/${contractId}/change-payment-day`;
}

export function changeMileagePath(contractId: string): string {
    return `${dashboardPagePath()}/${contractId}/change-mileage`;
}

export function changePostalAddressPath(): string {
    return `${myProfilePagePath()}/change-postal-address`;
}

export function changeFiscalAddressPath(): string {
    return `${myProfilePagePath()}/change-fiscal-address`;
}

export function changeContactDetailsPath(): string {
    return `${myProfilePagePath()}/change-contact-details`;
}

export function changeIbanPath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-iban`;
}

export function paymentDetailsPath(contractId = ':contractId'): string {
    return `/payment-details/${contractId}`;
}

export function contractHandoverPath(): string {
    return `/contract-handover`;
}

export function requestAppointmentForCarReturnPath(contractId = ':contractId'): string {
    return `/request-appointment-car-return/${contractId}`;
}

export function requestAppointmentForMaintenancePath(contractId = ':contractId'): string {
    return `/request-appointment-maintenance/${contractId}`;
}

export function partialEarlySettlementPath(contractId = ':contractId'): string {
    return `/partial-early-settlement/${contractId}`;
}

export function logoutPath(): string {
    return '/logout';
}

export function errorPagePath(): string {
    return '/error';
}
