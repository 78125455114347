import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useDashboardMarketingCard } from './useDashboardMarketingCard';
import { MarketingCardUi } from './ui';

const DashboardMarketingCardWithHandlers = withLoadingAndNoConnectionHandler(MarketingCardUi);

export const DashboardMarketingCard: React.FC = () => {
    const { cmsContent: dashboardMarketingCard, isLoading, loadingError } = useDashboardMarketingCard();

    return (
        <DashboardMarketingCardWithHandlers
            isLoading={isLoading}
            marketingCard={dashboardMarketingCard}
            hasError={!!loadingError}
        />
    );
};
