import { Button, ButtonContainer, Heading } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { EditStatus, EditViewNames } from '../../enums';
import { CpDataApi } from '../../../../../../../cp-xhr';
import { getIbanEndpoint, UpdateIban } from '../../../../../../../common';
import { stripWhitespaces } from '../iban-view/validated-iban-input/ibanValidationsUtils';
import { UiBlockingSpinner } from '@cp-shared-8/frontend-ui';

export type SubmitViewProps = {
    contractId: string;
    iban: string;
    setCurrentView: (newEditView: EditViewNames) => void;
    onSuccess: (lastEditStatus: EditStatus) => void;
    onFail: (lastEditStatus: EditStatus) => void;
};

export const SubmitView: React.FC<SubmitViewProps> = ({ contractId, iban, setCurrentView, onSuccess, onFail }) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const translationPrefix = 'iban-section.edit.submit';

    const onBackClick = (): void => {
        setCurrentView(EditViewNames.SEPA_MANDATE_DOWNLOAD_VIEW);
    };

    const onSubmit = (): void => {
        const data: UpdateIban = { iban: stripWhitespaces(iban) };
        setIsSubmitting(true);
        CpDataApi.put(getIbanEndpoint(contractId), data)
            .then(() => {
                onSuccess(EditStatus.SUCCESS);
            })
            .catch(() => {
                onFail(EditStatus.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <>
            <Heading className="u-pt" level={'6'}>
                {t(`${translationPrefix}.title`)}
            </Heading>
            <div className={'c-labeled-content-section u-pt-none u-pb'}>
                <UiBlockingSpinner isBlocking={isSubmitting}>
                    <div dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.info`) }} />
                    <ButtonContainer className={'u-mt'} center>
                        <Button secondary onClick={onBackClick} testId="back-to-sepa-view">
                            {t('translation:editable-section-nav.back')}
                        </Button>
                        <Button onClick={onSubmit} testId="submit-iban" type="submit">
                            {t('translation:editable-section-nav.submit')}
                        </Button>
                    </ButtonContainer>
                </UiBlockingSpinner>
            </div>
        </>
    );
};
