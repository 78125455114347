import React from 'react';
import { Greeting as GreetingShared } from '@cp-shared-8/frontend-ui';
import { Gender, Greeting } from 'common';
import { useTranslation } from 'react-i18next';

export const GreetingUi: React.FC<{ greeting?: Greeting }> = ({ greeting }) => {
    const { t } = useTranslation('greeting');
    const { firstName, surname, gender } = { ...greeting };

    const getGenderText = (gender?: Gender) =>
        gender === 'Male' || gender === 'Female' ? gender.toLowerCase() : 'empty';

    if (!firstName || !surname) {
        return <GreetingShared fullGreetingText={t('empty')} />;
    }

    const message = `${t(getGenderText(gender))} ${firstName} ${surname}`;

    return <GreetingShared fullGreetingText={message} />;
};
