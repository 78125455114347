import { useMemo } from 'react';
import { DefaultMarketApiErrors } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-8/frontend-integration';
import { ContractDetails, getContractDetailsEndpoint } from '../../../common';
import { useContract } from 'components/contracts/useContract';
import { ContractDetailsDataSelector } from './ContractDetailsDataSelector';
import { fetchContractDetails } from './ContractDetailsSlice';

export function useContractDetails(contractId: string): AbstractDataState<ContractDetails, DefaultMarketApiErrors> {
    const contractState = useContract(contractId);
    const contractDetailsState = useGetContractBasedApiData(
        contractId,
        fetchContractDetails,
        ContractDetailsDataSelector,
        getContractDetailsEndpoint(contractId),
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || contractDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && contractDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? contractDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            data: contractDetailsState.data as any,
            loadingError: hasFetchedSuccessfully(contractState)
                ? contractDetailsState.loadingError
                : //eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (contractState.loadingError as any),
        }),
        [contractState, contractDetailsState],
    );
}
