import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PaymentDetails } from '../../components/payment-details';

type ParamTypes = {
    contractId: string;
};

export const PaymentDetailsPage: React.FC = () => {
    const { t } = useTranslation('payment-details');

    const { contractId } = useParams<ParamTypes>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('title')}</Heading>
            <PaymentDetails contractId={contractId} />
        </ContentSection>
    );
};
