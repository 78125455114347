export enum EditStatus {
    NOT_PERFORMED = 'NOT_PERFORMED',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum EditViewNames {
    IBAN_VIEW = 'IBAN_VIEW',
    SEPA_MANDATE_DOWNLOAD_VIEW = 'SEPA_MANDATE_DOWNLOAD_VIEW',
    SUBMIT_VIEW = 'SUBMIT_VIEW',
}
