import React from 'react';
import { UnpaidAlertNotificationUi } from './ui';
import { usePaymentDetails } from '../../payment-details';

export type UnpaidAlertNotificationProps = {
    contractId: string;
    setUnpaid: (contractId: string, unpaid: boolean) => void;
};

export const UnpaidAlertNotification: React.FC<UnpaidAlertNotificationProps> = ({ contractId, setUnpaid }) => {
    const { data: paymentDetails, loadingError } = usePaymentDetails(contractId);

    if (!!loadingError || !paymentDetails) {
        return null;
    }

    const { totalUnpaidAmount, paymentDetailsEntity, paymentDetailsReference } = paymentDetails;

    return (
        <UnpaidAlertNotificationUi
            contractId={contractId}
            totalUnpaidAmount={totalUnpaidAmount}
            paymentDetailsEntity={paymentDetailsEntity}
            paymentDetailsReference={paymentDetailsReference}
            setUnpaid={setUnpaid}
        />
    );
};
