import { formatCpDate } from '@cp-shared-8/common-utilities';
import { Contract, ProductTypeEN } from '../../common';

export const daysLimitForRenting = 30;
export const daysLimitForAutoCredit = 60;

export const getDaysForContractToRequestCarReturn = (productType: string): number => {
    return productType === 'Contrato de Renting' ? daysLimitForRenting : daysLimitForAutoCredit;
};

export const withRequestAppointmentForCarReturn = ({ isActive, productType, endDate }: Contract): boolean => {
    return (
        isActive &&
        (productType === 'Contrato de Renting' || productType === 'Contrato de Auto Crédito') &&
        !!endDate &&
        formatCpDate(endDate)
            .toMoment()
            .startOf('day')
            .isSameOrBefore(
                formatCpDate()
                    .toMoment()
                    .startOf('day')
                    .clone()
                    .add(getDaysForContractToRequestCarReturn(productType), 'days'),
            )
    );
};

export const withRequestAppointmentForMaintenance = ({ isActive, productTypeEN }: Contract): boolean => {
    return (
        isActive && (productTypeEN === ProductTypeEN.MAINTENANCE || productTypeEN === ProductTypeEN.OPERATIVE_LEASING)
    );
};
