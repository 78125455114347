import React from 'react';
import { HeroImage } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useGenericErrorPage } from './useGenericErrorPage';
import { GenericErrorType } from './utils/enums';
import { useLogin } from '../../../auth/useLogin';
import { Redirect } from 'react-router-dom';
import { dashboardPagePath, notAuthorizedPagePath } from '../../navigation/paths';
import qs from 'qs';

export const GenericErrorPage: React.FC = () => {
    const { cmsContent: errorPageContents, isLoading, loadingError } = useGenericErrorPage();
    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);
    const login = useLogin();

    const { search } = window.location;
    const searchParams = qs.parse(search, { ignoreQueryPrefix: true });
    const errorTypeParam = searchParams['errorType']?.toString();

    const loginHandler = (): void => {
        login();
    };

    let errorPageContent = errorPageContents?.general;

    if (errorTypeParam === GenericErrorType.CONSENT_DENIED_ERR) {
        errorPageContent = errorPageContents?.consentDenied;
    }

    if (errorTypeParam === GenericErrorType.ALREADY_LOGIN_ERR) {
        return <Redirect to={dashboardPagePath()} />;
    }

    if (errorTypeParam === GenericErrorType.INVALID_SESSION_ERR) {
        return <Redirect to={notAuthorizedPagePath()} />;
    }

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={errorPageContent?.title || ''}
            subTitle={errorPageContent?.subTitle}
            buttonText={errorPageContent?.buttonText}
            clickHandler={loginHandler}
        />
    );
};
