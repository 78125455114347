import React from 'react';
import moment from 'moment-timezone';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { useLegalFooter } from './useLegalFooter';
import { LegalFooter as LegalFooterUi } from '@cp-shared-8/frontend-ui';

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterUi);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useLegalFooter();

    const copyrightText = legalFooter?.copyrightText ? `${legalFooter?.copyrightText} ${moment().year()}` : undefined;

    return (
        <LegalFooterWithHandlers
            copyrightText={copyrightText}
            legalText={legalFooter?.legalText}
            isLoading={isLoading}
            links={legalFooter?.links || []}
            hasError={!!loadingError}
        />
    );
};
