import React from 'react';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { EditStatus, Identification } from 'common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { SubmitStatusNotification } from '../../submit-status-notification';

type ConsultViewProps = {
    identification: Identification;
    startEditing: () => void;
    lastEditStatus?: EditStatus;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ identification, startEditing, lastEditStatus }) => {
    const { t } = useTranslation('my-profile');
    const noValuePlaceholder = '-';
    const translationPrefix = 'identification';
    const { fullName, fiscalNumber, idCardNumber } = identification;

    const identificationList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.name`),
            value: fullName ?? noValuePlaceholder,
            testId: 'identification-details-name',
        },
        {
            label: t(`${translationPrefix}.fiscal-number.label`),
            value: fiscalNumber ?? noValuePlaceholder,
            tooltip: t(`${translationPrefix}.fiscal-number.tooltip`),
            testId: 'identification-details-fiscal-number',
        },
        {
            label: t(`${translationPrefix}.id-card.label`),
            value: idCardNumber ?? noValuePlaceholder,
            tooltip: t(`${translationPrefix}.id-card.tooltip`),
            testId: 'identification-details-id-card',
        },
    ];

    return (
        <DataOverview
            title={t('identification.title')}
            buttonLabel={t('translation:editable-section-nav.edit')}
            buttonProps={{
                onClick: startEditing,
                testId: 'edit-button',
            }}
        >
            <DefinitionListHorizontal list={identificationList} />
            <SubmitStatusNotification lastEditStatus={lastEditStatus} section="identification" />
        </DataOverview>
    );
};
