import React from 'react';
import { HeroImage } from '@cp-shared-8/frontend-ui';
import { useLogin } from '../../../auth/useLogin';
import { LogoutSuccess } from 'common';

type LogoutSuccessUiProps = {
    logoutSuccess?: LogoutSuccess;
};

export const LogoutSuccessUi: React.FC<LogoutSuccessUiProps> = ({ logoutSuccess }) => {
    const login = useLogin();

    return (
        <>
            <HeroImage
                title={logoutSuccess?.teaser.title}
                subTitle={logoutSuccess?.teaser.pretitle}
                imageUrl={logoutSuccess?.teaser.imageUrl}
                buttonText={logoutSuccess?.button}
                clickHandler={login}
            />
        </>
    );
};
