export type Addresses = {
    postalAddress?: BaseAddress;
    fiscalAddress?: BaseAddress;
};

export type BaseAddress = {
    roadType?: string;
    streetName?: string;
    houseNumber?: string;
    block?: string;
    floor?: string;
    door?: string;
    place?: string;
    zipCode?: string;
    localityCity?: string;
    country?: string;
};

export enum AddressType {
    POSTAL = 'POSTAL',
    FISCAL = 'FISCAL',
}

export type UpdateAddress = {
    addressType: AddressType;
    address: BaseAddress;
};

export type UpdateAddressErrors = 'WRONG_ADDRESS_TYPE';
