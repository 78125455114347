import * as Yup from 'yup';
import { defaultPhoneNumberError } from '../messages';
import { PhoneNumberError } from '../types';

const validCharacters = /^[\d ()+/-]{9,20}$/;
const nineDigits = /^[\d]{9}$/;
const spaces = /\s/g;

const isWithoutCountryCode = (value: string): boolean => !value.startsWith('0') && !value.startsWith('+');

export const phoneNumberValidator = (errors: PhoneNumberError = defaultPhoneNumberError): Yup.StringSchema =>
    Yup.string()
        .trim()
        .notRequired()
        .test('valid', errors.valid, (value?: string): boolean => {
            if (!value) return true;
            if (isWithoutCountryCode(value) && !value.replace(spaces, '').match(nineDigits)) return false;
            return !!value.match(validCharacters);
        });

export const phoneNumberRequiredValidator = (errors: PhoneNumberError = defaultPhoneNumberError): Yup.StringSchema =>
    phoneNumberValidator(errors).required(errors.required);
