export function getContractsEndpoint(): string {
    return '/contracts';
}

export function getContractDetailsEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}`;
}

export function getPaymentDayEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/payment-day`;
}

export function getIbanEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/iban`;
}

export function getMileageEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/mileage`;
}

export function getSepaMandateDownloadEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/sepa-mandate-download`;
}

export function getPaymentDetailsEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/payment-details`;
}

export function getRequestAppointmentForCarReturnEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/request-appointment-for-car-return`;
}

export function getRequestAppointmentForMaintenanceEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/request-appointment-for-maintenance`;
}
