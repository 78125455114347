import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useDashboardMarketingCard } from './useDashboardMarketingCardInsurance';
import { MarketingCardUi } from '../dashboard-marketing-card/ui';

const DashboardMarketingCardWithHandlers = withLoadingAndNoConnectionHandler(MarketingCardUi);

export const DashboardMarketingCardInsurance: React.FC = () => {
    const { cmsContent: DashboardMarketingCardInsurance, isLoading, loadingError } = useDashboardMarketingCard();

    return (
        <div className="u-mb">
            <DashboardMarketingCardWithHandlers
                isLoading={isLoading}
                marketingCard={DashboardMarketingCardInsurance}
                hasError={!!loadingError}
            />
        </div>
    );
};
