export type RequestAppointmentForm = {
    dealer: string;
    mileage: string;
    reason: string;
    date: string;
    time: string;
    phoneNumber: string;
    comment: string;
    files: File[];
};

export const getInitialValues = (defaultPhoneNumber?: string): RequestAppointmentForm => ({
    dealer: '',
    mileage: '',
    reason: '',
    date: '',
    time: '',
    phoneNumber: defaultPhoneNumber || '',
    comment: '',
    files: [],
});
