import React from 'react';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-8/frontend-ui';
import { ContactDetails, EditStatus } from 'common';
import { ConsultView } from './consult-view/ConsultView';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { changeContactDetailsPath } from '../../navigation/paths';
import { EditView } from './edit-view';
import { useUpdateMyProfile } from '../useUpdateMyProfile';

export const ContactSection: React.FC<{ contactDetails?: ContactDetails }> = ({ contactDetails }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();
    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onSuccess } = useAnalyticsFormTracker({
        confirmSuccess: 'onEditProfileContactSuccess',
    });

    const setLastEditStatus = (newEditStatus: EditStatus, updatedContactDetails?: ContactDetails): void => {
        updateMyProfile({
            ...myProfileData,
            contactDetails: updatedContactDetails ?? contactDetails,
            lastEditStatus: { ...myProfileData?.lastEditStatus, contactDetails: newEditStatus },
        });
    };

    const startEditing = (): void => {
        onStart();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeContactDetailsPath());
    };

    const cancelEditing = (): void => {
        onCancel('Contact');
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus, updatedContactDetails?: ContactDetails): void => {
        if (newEditStatus === EditStatus.SUCCESS) onSuccess();
        setLastEditStatus(newEditStatus, updatedContactDetails);
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeContactDetailsPath()}>
                <EditView cancelEditing={cancelEditing} finishEditing={finishEditing} contactDetails={contactDetails} />
            </Route>
            <Route path={path}>
                <ConsultView
                    contactDetails={contactDetails}
                    startEditing={startEditing}
                    lastEditStatus={myProfileData?.lastEditStatus?.contactDetails}
                />
            </Route>
        </Switch>
    );
};
