import React from 'react';
import { CpDataApi } from 'cp-xhr';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getMyProfileEndpoint } from 'common';

import { MyProfileWithAllValues, MyProfileWithMissingValues } from './examples/ExampleData';
import { GreetingMock } from '../greeting';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MyProfileWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: MyProfileWithMissingValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'My Profile',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptions,
    url: getMyProfileEndpoint(),
};

export const MyProfileResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyProfileMock, GreetingMock]);

    return <div>{storyFn()}</div>;
};
