import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetails } from 'common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';

export type ContactListProps = {
    contactDetails?: ContactDetails;
};

export const ContactList: React.FC<ContactListProps> = ({ contactDetails }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'contact-details.consult-view';
    const { mobilePhone = '-', otherPhone = '-', email = '-' } = contactDetails || {};

    const contactList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.email.label`),
            tooltip: t(`${translationPrefix}.email.tooltip`),
            value: email,
            testId: 'contact-details-email',
        },
        {
            label: t(`${translationPrefix}.mobile-phone.label`),
            tooltip: t(`${translationPrefix}.mobile-phone.tooltip`),
            value: mobilePhone,
            testId: 'contact-details-mobile-phone',
        },
        {
            label: t(`${translationPrefix}.other-phone.label`),
            value: otherPhone,
            testId: 'contact-details-other-phone',
        },
    ];

    return <DefinitionListHorizontal list={contactList} />;
};
