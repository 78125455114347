import { CPDate } from '@cp-shared-8/common-utilities';

export enum ProductType {
    INSURANCE_CREDIT = 'INSURANCE_CREDIT', // ProductTypeId: 66
    INSURANCE_AUTO = 'INSURANCE_AUTO', // ProductTypeId: 71,79,80,81,83
    SERVICE_TYRES = 'SERVICE_TYRES', // ProductTypeId: 86
    SERVICE_CAR_INSPECTION = 'SERVICE_CAR_INSPECTION', // ProductTypeId: 83
    SERVICE_ROAD_TAX = 'SERVICE_ROAD_TAX', // ProductTypeId: 84
    SERVICE_REPLACEMENT_VEHICLE = 'SERVICE_REPLACEMENT_VEHICLE', // ProductTypeId: 87
    SERVICE_ROAD_ASSISTANCE = 'SERVICE_ROAD_ASSISTANCE', // ProductTypeId: 88
    SERVICE_SUPPORT_HOTLINE = 'SERVICE_SUPPORT_HOTLINE', // ProductTypeId: 85
    SERVICE_MAINTENANCE = 'SERVICE_MAINTENANCE', // ProductTypeId: 82
    MAINTENANCE = 'MAINTENANCE', // ProductTypeId: 56,62,74,92,93,94,95,96,97
}

export const ProductTypeIds: Readonly<Map<ProductType, number[]>> = Object.freeze(
    new Map([
        [ProductType.INSURANCE_CREDIT, [66]],
        [ProductType.INSURANCE_AUTO, [71, 79, 80, 81]],
        [ProductType.SERVICE_TYRES, [86]],
        [ProductType.SERVICE_CAR_INSPECTION, [83]],
        [ProductType.SERVICE_ROAD_TAX, [84]],
        [ProductType.SERVICE_REPLACEMENT_VEHICLE, [87]],
        [ProductType.SERVICE_ROAD_ASSISTANCE, [88]],
        [ProductType.SERVICE_SUPPORT_HOTLINE, [85]],
        [ProductType.SERVICE_MAINTENANCE, [82]],
        [ProductType.MAINTENANCE, [56, 62, 74, 92, 93, 94, 95, 96, 97]],
    ]),
);

export type BaseBundledProduct = {
    productType?: ProductType;
    displayedProductType?: string;
    productDescription?: string;
    productStateDescription?: string;
    endDate?: CPDate;
};

export type InsuranceCredit = BaseBundledProduct & {
    productType?: ProductType.INSURANCE_CREDIT;
    productSupplier?: string;
    monthlyAmount?: number;
    productAmount?: number;
    sumInsured?: number;
    coverages?: string[];
};

export type InsuranceAuto = BaseBundledProduct & {
    productType?: ProductType.INSURANCE_AUTO;
    productSupplier?: string;
    policyNumber?: string;
    monthlyAmount?: number;
    productAmount?: number;
    deductible?: number;
    deductibleAmount?: number;
    sumInsured?: number;
    coverages?: string[];
};

export type ServiceTyres = BaseBundledProduct & {
    productType?: ProductType.SERVICE_TYRES;
    tyresType?: string;
    tyresOffer?: string;
};

export type ServiceCarInspection = BaseBundledProduct & { productType?: ProductType.SERVICE_CAR_INSPECTION };

export type ServiceRoadTax = BaseBundledProduct & { productType?: ProductType.SERVICE_ROAD_TAX };

export type ServiceReplacementVehicle = BaseBundledProduct & { productType?: ProductType.SERVICE_REPLACEMENT_VEHICLE };

export type ServiceRoadAssistance = BaseBundledProduct & { productType?: ProductType.SERVICE_ROAD_ASSISTANCE };

export type ServiceSupportHotline = BaseBundledProduct & { productType?: ProductType.SERVICE_SUPPORT_HOTLINE };

export type ServiceMaintenance = BaseBundledProduct & { productType?: ProductType.SERVICE_MAINTENANCE };

export type Maintenance = BaseBundledProduct & {
    productType?: ProductType.MAINTENANCE;
    monthlyAmount?: number;
};

export type BundledProduct =
    | InsuranceCredit
    | InsuranceAuto
    | ServiceTyres
    | ServiceCarInspection
    | ServiceRoadTax
    | ServiceReplacementVehicle
    | ServiceRoadAssistance
    | ServiceSupportHotline
    | ServiceMaintenance
    | Maintenance;

export type BundledProducts = BundledProduct[];

export type BundledProductError = 'NOT_FOUND_PRODUCT_TYPE_FOR_ID';
