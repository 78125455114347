import { Contract } from '../../common';
import { DividedContractHeader } from '@cp-shared-8/frontend-ui';
import { ProductTable, DescriptionList } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';
import { LicensePlate } from '../license-plate';

export type ContractInformationHeaderProps = {
    contract: Contract;
};
export const ContractInformationHeader: React.FC<ContractInformationHeaderProps> = ({ contract }) => {
    const { t, f } = useTranslationWithFormatting('contracts');

    const { nextDueDate, outstandingBalance, licensePlate, brand, model } = contract;

    const contractDetails = (
        <ProductTable>
            <ProductTable.Section title={t('contract-terms-header')}>
                <DescriptionList horizontal>
                    <DescriptionList.Group termText={t('next-due-date')}>
                        <DescriptionList.Detail>
                            {nextDueDate ? f(nextDueDate, TranslationFormat.DATE) : '-'}
                        </DescriptionList.Detail>
                    </DescriptionList.Group>
                    <DescriptionList.Group termText={t('outstanding-balance')}>
                        <DescriptionList.Detail>
                            {outstandingBalance ? f(outstandingBalance, TranslationFormat.CURRENCY) : '-'}
                        </DescriptionList.Detail>
                    </DescriptionList.Group>
                </DescriptionList>
            </ProductTable.Section>
        </ProductTable>
    );

    const licensePlateComponent = licensePlate && <LicensePlate registrationNumber={licensePlate} />;

    const carInformation = brand ? `${brand} ${model || ''}` : '';

    return (
        <DividedContractHeader
            carInformation={carInformation}
            contractNumber={contract.contractNumber || ''}
            contractCategory={contract.productType || ''}
            rightSideContent={contractDetails}
            contractIdentifier={licensePlateComponent}
        />
    );
};
