import { PartialEarlySettlementError } from '../types';
import { PartialEarlySettlementOptions } from '../../apis';

export const defaultPartialEarlySettlementError: PartialEarlySettlementError = {
    amount: { moreThan: 'Amount must be more than 0.', required: 'Amount is required.' },
    fileIds: {
        required: 'File ids is required.',
        id: { required: 'File id is required.' },
        min: 'Not enough file IDs, must be 2 or more.',
        max: 'Too many file IDs, must be 6 or less.',
    },
    option: {
        oneOf: `Option must be one of: ${Object.values(PartialEarlySettlementOptions)}.`,
        required: 'Option is required.',
    },
    requestDate: {
        required: 'Request date is required.',
        valid: 'Request date is invalid.',
        min: 'Request date must be in the future.',
    },
};
