import React from 'react';
import { MyProfileData, AddressType } from 'common';
import { IdentificationSection } from '../identification-section';
import { ContactSection } from '../contact-section';
import { AddressSection } from '../address-section';

export const MyProfileUi: React.FC<{ myProfileData?: MyProfileData }> = ({ myProfileData }) => {
    if (!myProfileData) {
        return null;
    }
    const { identification, contactDetails, addresses } = myProfileData;
    return (
        <>
            <IdentificationSection identification={identification} />
            <ContactSection contactDetails={contactDetails} />
            <AddressSection addressType={AddressType.POSTAL} address={addresses?.postalAddress} />
            <AddressSection addressType={AddressType.FISCAL} address={addresses?.fiscalAddress} />
        </>
    );
};
