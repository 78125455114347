import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';

export type InactiveUnpaidAlertNotificationProps = {
    amountsInOverdue?: number;
    litigationState?: boolean;
    contractId: string;
    setUnpaid: (contractId: string, unpaid: boolean) => void;
};

export const InactiveUnpaidAlertNotification: React.FC<InactiveUnpaidAlertNotificationProps> = ({
    amountsInOverdue,
    litigationState,
    contractId,
    setUnpaid,
}) => {
    const { t } = useTranslation('contracts');
    const shouldRender = contractId && amountsInOverdue && amountsInOverdue > 0 && litigationState === false;

    useEffect(() => {
        if (shouldRender) setUnpaid(contractId, true);
        else setUnpaid(contractId, false);
    }, [shouldRender, setUnpaid, contractId]);

    if (!shouldRender) {
        return null;
    }

    return (
        <Notification
            status={NotificationStatus.warning}
            headline={t('unpaid-alert.inactive.headline')}
            text={t('unpaid-alert.inactive.text')}
            testId={'inactive-contract-unpaid-alert'}
            className={'u-mb-small'}
        />
    );
};
