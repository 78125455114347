import { CleaveInput, UiBlockingSpinner } from '@cp-shared-8/frontend-ui';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React, { useEffect } from 'react';
import { IbanStateHandler } from '../types';
import { FormikProps, useFormikContext } from 'formik';
import { InitialValues } from '../InitialValues';
import { getIbanValidation } from '../validationSchema';

export type ValidatedIbanInputProps = {
    isValidating: boolean;
    ibanStateHandler: IbanStateHandler;
    ibanViewValues: InitialValues;
};

const translationPrefix = 'iban-section.edit.iban';
const cleaveOptionsIban = {
    delimiter: ' ',
    blocks: [2, 4, 4, 4, 4, 4, 4, 4, 4],
    numericOnly: false,
};

export const ValidatedIbanInput: React.FC<ValidatedIbanInputProps> = ({
    isValidating,
    ibanStateHandler,
    ibanViewValues,
}) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const { signedBankData } = ibanStateHandler;
    const formik: FormikProps<InitialValues> = useFormikContext();

    useEffect(() => {
        if (
            formik.values.iban &&
            !formik.touched.iban &&
            !ibanViewValues.iban &&
            getIbanValidation(t, formik.values.currentIban).isValidSync(formik.values.iban)
        ) {
            formik.setFieldTouched('iban', true, true);
        }
        if (ibanViewValues.iban && !formik.values.iban && !formik.touched.iban) {
            formik.setFieldValue('iban', ibanViewValues.iban, false);
            formik.setFieldTouched('iban', true, false);
        }
    }, [t, formik, ibanViewValues.iban]);

    return (
        <div className={isValidating ? 'u-pt u-pb' : ''}>
            <UiBlockingSpinner isBlocking={isValidating}>
                <CleaveInput
                    cleaveOptions={cleaveOptionsIban}
                    label={t(`${translationPrefix}.label`)}
                    name="iban"
                    testId="iban"
                    stateIcon
                    tooltip={t(`${translationPrefix}.tooltip`)}
                />
                {signedBankData?.data.isValid && (
                    <p className="u-text-success">{signedBankData.data.bankDetails?.bankName}</p>
                )}
            </UiBlockingSpinner>
        </div>
    );
};
