import React from 'react';
import { useTranslation } from 'react-i18next';
import { changeLocale } from 'common';
import { LanguageDescriptionList, LanguageSwitcher as LanguageSwitcherShared } from '@cp-shared-8/frontend-ui';

export const LanguageSwitcher: React.FC = () => {
    const { t, i18n } = useTranslation('navigation');

    const languageSwitchHandler = (newLocale: string, currentLocale: string): void => {
        if (newLocale === currentLocale) {
            return;
        }
        i18n.changeLanguage(newLocale);
        if (newLocale === 'en' || newLocale === 'pt') {
            changeLocale(newLocale);
        }
    };

    const supportedLanguages: LanguageDescriptionList = [
        {
            locale: 'pt',
            dropdownLabel: t('language-switcher.portuguese.long-label'),
            activeLabel: t('language-switcher.portuguese.short-label'),
        },
        {
            locale: 'en',
            dropdownLabel: t('language-switcher.english.long-label'),
            activeLabel: t('language-switcher.english.short-label'),
        },
    ];

    const currentLanguage =
        supportedLanguages.find((language) => language.locale === i18n.languages[0]) || supportedLanguages[0];

    return (
        <LanguageSwitcherShared
            currentLanguage={currentLanguage}
            selectableLanguages={supportedLanguages}
            onClick={languageSwitchHandler}
        />
    );
};
