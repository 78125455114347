import React, { useState } from 'react';
import { Button, ButtonContainer, Fieldset, Form, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import {
    CleaveInput,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
} from '@cp-shared-8/frontend-ui';
import { EditStatus, getMileageEndpoint, UpdateMileage } from '../../../../../../common';
import { CpDataApi } from '../../../../../../cp-xhr';
import { validationSchema } from './validationSchema';
import {
    TranslationFormat,
    useTranslationWithFormatting,
} from '../../../../../../localization/useTranslationWithFormatting';
import { dotFormattedStringToNumber } from 'utils';
import { isEmpty } from 'lodash';

export type EditViewProps = {
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus) => void;
    contractId: string;
    lastMileage?: number;
};

type InitialValues = {
    newMileage: string;
    currentMileage: string;
};

export const EditView: React.FC<EditViewProps> = ({ cancelEditing, finishEditing, contractId, lastMileage }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');
    const translationPrefix = 'mileage-section.edit-view';
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        onError: trackOnError,
        onSuccess: trackOnSuccess,
        onTyping,
    } = useAnalyticsFormTracker({
        confirmError: 'financialDetailsEditMileageSectionSubmitFailed',
        confirmSuccess: 'financialDetailsEditMileageSectionSuccess',
        startTyping: 'financialDetailsEditMileageSectionStartTyping',
    });

    const { onAction: onValidationError } = useAnalyticsActionTracker(
        'financialDetailsEditMileageSectionValidationError',
    );

    const initialErrors = 'newMileage, currentMileage';
    const getErrors = (errors: { [k: string]: string | undefined }): string => Object.keys(errors).join(`, `);

    const initialValues: InitialValues = {
        newMileage: '',
        currentMileage: '',
    };

    const itemList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.contracted-mileage.label`),
            tooltip: t(`${translationPrefix}.contracted-mileage.tooltip`),
            value: lastMileage ? f(lastMileage, TranslationFormat.DISTANCE) : '-',
            testId: 'last-mileage',
        },
    ];

    const onSubmit = (data: InitialValues) => {
        setIsSubmitting(true);
        const { currentMileage, newMileage } = data;

        const formattedData: UpdateMileage = {
            currentMileage: dotFormattedStringToNumber(currentMileage),
            newMileage: dotFormattedStringToNumber(newMileage),
        };

        CpDataApi.put(getMileageEndpoint(contractId), formattedData)
            .then(() => {
                trackOnSuccess();
                finishEditing(EditStatus.SUCCESS);
            })
            .catch(() => {
                trackOnError();
                finishEditing(EditStatus.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div className={'c-labeled-content-section u-pt-none'}>
            <Heading className={`c-data-overview__header u-mt-xsmall u-mb-small`} level={'6'}>
                {t(`${translationPrefix}.headline`)}
                <button
                    className="c-icon c-btn__icon  c-icon--[semantic-crossed-out] u-pb-small u-pr-small"
                    onClick={cancelEditing}
                    id={'x-button'}
                />
            </Heading>
            <UiBlockingSpinner isBlocking={isSubmitting}>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema(t)}>
                    {({ submitForm, errors, touched }) => (
                        <Form
                            data-testid="mileage-form"
                            onSubmit={preventSubmit}
                            onChange={() => {
                                onTyping(errors, touched);
                            }}
                        >
                            <Fieldset>
                                <Fieldset.Row>
                                    <span>{t(`${translationPrefix}.info`)}</span>
                                </Fieldset.Row>
                                <Fieldset.Row className={'u-pb-small'}>
                                    <DefinitionListHorizontal list={itemList} />
                                </Fieldset.Row>
                                <Fieldset.Row className={'u-pb-small'}>
                                    <span>{t(`${translationPrefix}.new-mileage.headline`)}</span>
                                </Fieldset.Row>
                                <Fieldset.Row className={'u-pb-none'}>
                                    <Layout>
                                        <Layout.Item default={'1/2'} s={'1/1'}>
                                            <CleaveInput
                                                className="u-mt"
                                                cleaveOptions={{
                                                    numeral: true,
                                                    numeralDecimalMark: ',',
                                                    delimiter: '.',
                                                    numeralThousandsGroupStyle: 'thousand',
                                                    numericOnly: true,
                                                    numeralDecimalScale: 0,
                                                    numeralPositiveOnly: true,
                                                    stripLeadingZeroes: true,
                                                }}
                                                name={'newMileage'}
                                                label={t(`${translationPrefix}.new-mileage.label`)}
                                                tooltip={t(`${translationPrefix}.new-mileage.tooltip`)}
                                                inputMode={'numeric'}
                                                addonText={t('translation:metrics.distance-unit')}
                                                maxLength={7}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default={'1/2'} s={'1/1'} className="u-mt">
                                            <CleaveInput
                                                className="u-mt"
                                                cleaveOptions={{
                                                    numeral: true,
                                                    numeralDecimalMark: ',',
                                                    delimiter: '.',
                                                    numeralThousandsGroupStyle: 'thousand',
                                                    numericOnly: true,
                                                    numeralDecimalScale: 0,
                                                    numeralPositiveOnly: true,
                                                    stripLeadingZeroes: true,
                                                }}
                                                name={'currentMileage'}
                                                label={t(`${translationPrefix}.current-mileage.label`)}
                                                tooltip={t(`${translationPrefix}.current-mileage.tooltip`)}
                                                inputMode={'numeric'}
                                                addonText={t('translation:metrics.distance-unit')}
                                                maxLength={7}
                                                helpText={t(`${translationPrefix}.new-mileage.help-block`)}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Notification
                                        status={NotificationStatus.info}
                                        testId={'notification-info'}
                                        headline={t(`${translationPrefix}.notification.info.headline`)}
                                        text={t(`${translationPrefix}.notification.info.text`)}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button secondary onClick={cancelEditing} testId="edit-payment-day-cancel">
                                            {t('translation:editable-section-nav.back')}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (!isEmpty(errors)) {
                                                    const errorsList = getErrors(errors);
                                                    onValidationError(errorsList);
                                                } else if (isEmpty(touched)) {
                                                    onValidationError(initialErrors);
                                                }
                                                submitForm();
                                            }}
                                            testId="submit"
                                            type={'submit'}
                                        >
                                            {t(`${translationPrefix}.submit-button`)}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </UiBlockingSpinner>
        </div>
    );
};
