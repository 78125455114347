const validCharacters = /^[\d ()+/-]{9,20}$/;
const nineDigits = /^[\d]{9}$/;
const spaces = /\s/g;

export const isWithoutCountryCode = (value: string) => !value.startsWith('0') && !value.startsWith('+');

export const phoneValidator = (value?: string) => {
    if (!value) return true;
    if (isWithoutCountryCode(value) && !value.replace(spaces, '').match(nineDigits)) return false;
    if (value.match(validCharacters)) return true;
    return false;
};
