import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractParties, CustomerRole } from '../../../../../common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';

export const ContractPartiesUi: React.FC<{ contractPartiesData?: ContractParties }> = ({ contractPartiesData }) => {
    const { t } = useTranslation('contract-parties');

    if (!contractPartiesData) {
        return null;
    }

    const getRoleTranslation = (role?: CustomerRole): string => {
        switch (role) {
            case CustomerRole.FIRST_HOLDER:
                return t('first-holder');
            case CustomerRole.SECOND_HOLDER:
                return t('second-holder');
            case CustomerRole.GUARANTOR:
                return t('guarantor');
            default:
                return '';
        }
    };

    const itemList: DefinitionListItem[] = contractPartiesData
        .map(({ role, name = '' }) => ({
            label: getRoleTranslation(role),
            value: name,
            testId: `contract-party-${name}`,
        }))
        .filter((item: DefinitionListItem) => !!item.label && !!item.value);

    return itemList && <DefinitionListHorizontal list={itemList} />;
};
