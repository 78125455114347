import React from 'react';
import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { EditStatus, FinancialDetails } from '../../../../../../common';
import { Button, Heading } from '@vwfs-bronson/bronson-react';

type MileageSectionProps = {
    details: FinancialDetails;
    lastEditStatus: EditStatus;
    startEditing: () => void;
    isEditable: boolean;
};

export const ConsultView: React.FC<MileageSectionProps> = ({ details, lastEditStatus, startEditing, isEditable }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');

    const itemList: DefinitionListItem[] = [
        {
            label: t('mileage-section.last-mileage.label'),
            tooltip: t('mileage-section.last-mileage.tooltip'),
            value: f(details.lastMileage, TranslationFormat.DISTANCE),
            testId: 'last-mileage',
        },
    ];

    const notificationProps = () => {
        const notificationTranslationPrefix = 'mileage-section.edit-view.notification';
        switch (lastEditStatus) {
            case EditStatus.ERROR:
                return {
                    status: NotificationStatus.error,
                    testId: 'notification-error',
                    text: t(`${notificationTranslationPrefix}.error.text`),
                };
            case EditStatus.SUCCESS:
                return {
                    status: NotificationStatus.success,
                    testId: 'notification-success',
                    headline: t(`${notificationTranslationPrefix}.success.headline`),
                    text: t(`${notificationTranslationPrefix}.success.text`),
                };
        }
    };

    return (
        <>
            <Heading className={'c-data-overview__header u-pt'} level={'6'}>
                {t('mileage-section.headline')}
                {isEditable && (
                    <Button
                        element="a"
                        link
                        small
                        className="c-data-overview__button u-mb-none u-mt-none u-mr-small"
                        icon="true"
                        iconReversed
                        onClick={startEditing}
                        testId={'edit-button'}
                    >
                        {t('translation:editable-section-nav.edit')}
                    </Button>
                )}
            </Heading>
            <DefinitionListHorizontal list={itemList} />
            {lastEditStatus !== EditStatus.NOT_PERFORMED && (
                <Notification {...notificationProps()} className={'u-mb'} />
            )}
        </>
    );
};
