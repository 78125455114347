import React from 'react';
import { ContractIdentifierFilterItem, DocumentTypeFilterItem, TimeFrameFilterItem } from '@cp-shared-8/frontend-ui';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { Contract, CustomerRole, DocumentType, MyDocument } from 'common';
import { TFunction } from 'i18next';
import { uniqBy } from 'lodash';

export type ValidMyDocument = MyDocument & { documentType: DocumentType };

export const documentTypeTranslationMapping: Record<DocumentType, string> = {
    INVOICE: 'invoice',
    CREDIT_NOTE: 'credit-note',
    DEBIT_NOTE: 'debit-note',
    AVISO_10: 'aviso-10',
    FINANCIAL_PLAN: 'financial-plan',
    RENTING_REPORT: 'renting-report',
    WELCOME_LETTER: 'welcome-letter',
    CASH_FLOW: 'cash-flow',
};

export const getDocumentTypeTranslation = (documentType: DocumentType, t: TFunction): string =>
    t(`filters.document-type.${documentTypeTranslationMapping[documentType]}`);

export const isInvoiceDocument = (document: MyDocument): boolean => document.group === 'invoice';
export const isEntityDocument = (document: MyDocument): boolean => document.group === 'entity';
export const isContractDocument = (document: MyDocument): boolean => document.group === 'contract';

const isExpiredAndOlderThanAYear = (contract: Contract | undefined) =>
    !contract?.isActive &&
    formatCpDate(contract?.endDate).toMoment().isBefore(formatCpDate().subtract(1, 'years').toMoment());

const shouldDocumentBeDisplayed = (document: MyDocument, contracts: Contract[]): boolean => {
    const contract = contracts.find(({ contractNumber }) => contractNumber === document.contractNumber);
    const isFirstOrSecondHolder =
        contract?.customerRole === CustomerRole.FIRST_HOLDER || contract?.customerRole === CustomerRole.SECOND_HOLDER;

    if (isInvoiceDocument(document) && (!isFirstOrSecondHolder || isExpiredAndOlderThanAYear(contract))) return false;
    else if (isContractDocument(document) && !isFirstOrSecondHolder) return false;
    return true;
};

export const getValidMyDocuments = (documents: MyDocument[] = [], contracts: Contract[] = []): ValidMyDocument[] =>
    documents.filter(
        (document: MyDocument) => document.documentType && shouldDocumentBeDisplayed(document, contracts),
    ) as ValidMyDocument[];

export const sortDocuments = (myDocumentsData: ValidMyDocument[]): ValidMyDocument[] => {
    return [...myDocumentsData].sort(
        (a, b) => formatCpDate(b.creationDate).unix() - formatCpDate(a.creationDate).unix(),
    );
};

export const getContractFilterItems = (
    contracts: Contract[],
    contractItemGetter: (contract: Contract) => React.ReactNode,
): ContractIdentifierFilterItem[] => {
    return contracts.reduce(
        (acc: ContractIdentifierFilterItem[], contract: Contract): ContractIdentifierFilterItem[] => {
            const { contractNumber, productType } = contract;
            if (contractNumber && productType && !acc.some((filterItem) => filterItem.value === contractNumber)) {
                acc.push({
                    contractIdentifier: contractNumber,
                    value: contractNumber,
                    reactNode: contractItemGetter(contract),
                });
            }
            return acc;
        },
        [],
    );
};

export const getDocumentTypeFilterItems = (documents: ValidMyDocument[], t: TFunction): DocumentTypeFilterItem[] => {
    return uniqBy(documents, 'documentType')
        .map(({ documentType }) => ({
            documentType,
            value: getDocumentTypeTranslation(documentType, t),
        }))
        .sort((a, b) => a.value.localeCompare(b.value));
};

export const defaultTimeFrameFilterKey = '12m';

export const getTimeFrameFilterItems = (t: TFunction): TimeFrameFilterItem[] => {
    const translationPrefix = 'filters.time-frame';
    const currentDate = formatCpDate().toMoment();
    return [
        {
            from: formatCpDate().subtract(3, 'months').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.last-3-months`),
            key: '3m',
        },
        {
            from: formatCpDate().subtract(6, 'months').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.last-6-months`),
            key: '6m',
        },
        {
            from: formatCpDate().subtract(12, 'months').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.last-12-months`),
            key: '12m',
        },
        {
            from: formatCpDate().subtract(2, 'years').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.last-2-years`),
            key: '2y',
        },
        {
            from: formatCpDate().subtract(3, 'years').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.last-3-years`),
            key: '3y',
        },
        {
            from: formatCpDate().subtract(4, 'years').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.last-4-years`),
            key: '4y',
        },
    ];
};
