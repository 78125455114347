import React, { useState } from 'react';
import {
    Button,
    ButtonContainer,
    DataOverview,
    Fieldset,
    Form,
    FormField,
    Input,
    Layout,
} from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
    InfoIcon,
    preventSubmit,
    ValidatedInput,
    withAutoScroll,
    useAnalyticsPageViewTracker,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import { validationSchema } from './validationSchema';
import { ConfirmationView } from './confirmation-view';
import { EditStatus } from 'common';
import { EditStep } from './enums';

type FormViewProps = {
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedSurname?: string) => void;
    firstName: string;
    surname: string;
};
type InitialValues = { surname: string };

export const FormView: React.FC<FormViewProps> = withAutoScroll(
    ({ cancelEditing, finishEditing, firstName, surname }) => {
        const { t } = useTranslation('my-profile');
        const [step, setStep] = useState<EditStep>(EditStep.FORM);
        const [updatedSurname, setUpdatedSurname] = useState<string>('');

        const { onAction: onStartTyping } = useAnalyticsActionTracker('onEditProfileIdentificationTypedIn');
        const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileIdentificationValidationError');
        useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, 'Identification');

        const translationPrefix = 'identification.edit.form-view';

        const initialValues: InitialValues = { surname: !!updatedSurname ? updatedSurname : surname };

        const onSubmit = (value: InitialValues) => {
            setStep(EditStep.CONFIRMATION);
            setUpdatedSurname(value.surname);
        };

        const tooltip = <InfoIcon text={t(`${translationPrefix}.first-name.tooltip`)} />;

        return step === EditStep.CONFIRMATION ? (
            <ConfirmationView updatedSurname={updatedSurname} finishEditing={finishEditing} setStep={setStep} />
        ) : (
            <DataOverview title={t(`${translationPrefix}.headline`)}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema(t, surname)}
                    onSubmit={onSubmit}
                >
                    {({ submitForm, errors, touched }) => (
                        <Form
                            onSubmit={preventSubmit}
                            data-testid="edit-form"
                            onChange={() => {
                                onStartTyping();
                            }}
                        >
                            <Fieldset>
                                <Fieldset.Row>{t(`${translationPrefix}.text`)}</Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/2" s="1/1">
                                            <FormField
                                                type="input"
                                                labelText={t(`${translationPrefix}.first-name.label`)}
                                                infoIcon={tooltip}
                                            >
                                                <Input disabled={true} placeholder={firstName} />
                                            </FormField>
                                        </Layout.Item>
                                        <Layout.Item default="1/2" s="1/1">
                                            <ValidatedInput
                                                label={t(`${translationPrefix}.surname.label`)}
                                                tooltip={t(`${translationPrefix}.surname.tooltip`)}
                                                name="surname"
                                                testId="surname"
                                                type="text"
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button secondary onClick={cancelEditing} testId="cancel-button" type="button">
                                            {t('translation:editable-section-nav.back')}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (isEmpty(touched) && isEmpty(errors))
                                                    onValidationError({ surname: 'surname' });
                                                if (!isEmpty(errors)) onValidationError(errors);
                                                submitForm();
                                            }}
                                            testId="continue-button"
                                            type="button"
                                        >
                                            {t('translation:editable-section-nav.continue')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </DataOverview>
        );
    },
    'IdentificationEditView',
);
