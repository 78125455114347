import { LandingPageTeaser, getLandingPageTeaserEndpoint } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LandingPageTeaser>({
    contentName: 'landingPageTeaser',
    contentEndpoint: getLandingPageTeaserEndpoint,
});

export default reducer;
export const fetchLandingPageTeaser = fetchContent;
