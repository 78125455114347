import React from 'react';
import { Contract, PaymentDetails } from 'common';
import { Card, Layout, Fieldset, Heading, Button } from '@vwfs-bronson/bronson-react';
import {
    ContractHeader,
    DefinitionListHorizontal,
    DefinitionListItem,
    NotificationStatus,
    Notification,
} from '@cp-shared-8/frontend-ui';
import { LicensePlate } from '../../license-plate';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { dashboardPagePath } from '../../navigation/paths';
import { useHistory } from 'react-router-dom';

export type PaymentDetailsUiProps = {
    contract?: Contract;
    paymentDetails?: PaymentDetails;
    loadingError?: boolean;
};

export const PaymentDetailsUi: React.FC<PaymentDetailsUiProps> = ({ contract, paymentDetails, loadingError }) => {
    const { t, f } = useTranslationWithFormatting('payment-details');
    const history = useHistory();

    if (loadingError || !contract || !paymentDetails) {
        return (
            <Notification
                status={NotificationStatus.error}
                headline={t('notification-error.headline')}
                text={t('notification-error.text')}
            />
        );
    }

    const {
        totalUnpaidAmount,
        paymentDetailsEntity,
        paymentDetailsReference,
        nominalAmount,
        arrearsAmount,
        returnAmount,
        interestArrearsRate,
        pendingDocuments,
        nextDueDate,
    } = paymentDetails;

    const { brand, model, contractNumber, productType, licensePlate } = contract;
    const noValuePlaceholder = '-';

    const leftSideItemList: DefinitionListItem[] = [
        {
            label: t('nominal-amount'),
            value: nominalAmount ? f(nominalAmount, TranslationFormat.CURRENCY) : noValuePlaceholder,
            testId: 'start-date',
        },
        {
            label: t('arreas-amount'),
            value: arrearsAmount ? f(arrearsAmount, TranslationFormat.CURRENCY) : noValuePlaceholder,
            testId: 'arreas-amount',
        },
        {
            label: t('return-amount'),
            value: returnAmount ? f(returnAmount, TranslationFormat.CURRENCY) : noValuePlaceholder,
            testId: 'return-amount',
        },
        {
            label: t('interest-arrears-rate'),
            value: interestArrearsRate ? f(interestArrearsRate, TranslationFormat.PERCENTAGE) : noValuePlaceholder,
            testId: 'interest-arrears-rate',
        },
    ];

    const rightSideItemList: DefinitionListItem[] = [
        {
            label: t('pending-documents'),
            value: pendingDocuments ? pendingDocuments : noValuePlaceholder,
            testId: 'pending-documents',
        },
        {
            label: t('next-due-day'),
            value: nextDueDate ? f(nextDueDate, TranslationFormat.DATE) : noValuePlaceholder,
            testId: 'next-due-day',
        },
        {
            label: t('payment-details-entity.label'),
            tooltip: t('payment-details-entity.tooltip'),
            value: paymentDetailsEntity ? paymentDetailsEntity : noValuePlaceholder,
            testId: 'payment-details-entity',
        },
        {
            label: t('payment-details-reference.label'),
            tooltip: t('payment-details-reference.tooltip'),
            value: paymentDetailsReference ? paymentDetailsReference : noValuePlaceholder,
            testId: 'payment-details-reference',
        },
    ];

    return (
        <>
            <Card element="article">
                <Layout equalHeight divider>
                    <Layout.Item default="1/2" s="1/1">
                        <Fieldset>
                            <Fieldset.Row className={'u-mb-small'}>
                                <ContractHeader
                                    carInformation={`${brand} ${model}`}
                                    contractCategory={productType || ''}
                                    contractNumber={contractNumber || ''}
                                    contractIdentifier={
                                        licensePlate && (
                                            <LicensePlate
                                                className={'u-m-small'}
                                                registrationNumber={licensePlate.toUpperCase()}
                                            />
                                        )
                                    }
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <DefinitionListHorizontal list={leftSideItemList} />
                            </Fieldset.Row>
                            <div className={'c-dl-horizontal c-dl-horizontal--66-to-33'}>
                                <Heading level={5} className="c-dl-horizontal__title u-mb-none">
                                    {t('total-unpaid-amount')}
                                </Heading>
                                <Heading level={5} className="c-dl-horizontal__item">
                                    {totalUnpaidAmount
                                        ? f(totalUnpaidAmount, TranslationFormat.CURRENCY)
                                        : noValuePlaceholder}
                                </Heading>
                            </div>
                        </Fieldset>
                    </Layout.Item>
                    <Layout.Item default="1/2" s="1/1">
                        <Fieldset>
                            <Fieldset.Row>
                                <Heading level={5} className={'u-mt-xsmall'}>
                                    {t('additional-headline')}
                                </Heading>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <DefinitionListHorizontal list={rightSideItemList} />
                            </Fieldset.Row>
                        </Fieldset>
                    </Layout.Item>
                </Layout>
            </Card>
            <Button
                type={'button'}
                secondary
                onClick={(): void => history.push(dashboardPagePath())}
                className={'u-mt'}
            >
                {t('translation:editable-section-nav.back')}
            </Button>
        </>
    );
};
