import React from 'react';
import { Contract, CustomerRole, FinancialDetails } from '../../../../../common';
import { GeneralSection } from '../general-section';
import { IbanSection } from '../iban-section';
import { PaymentDaySection } from '../payment-day-section';
import { FinancialSection } from '../financial-section';
import { MileageSection } from '../mileage-section';

export type FinancialDetailsUiProps = {
    contract: Contract;
    financialDetailsData?: FinancialDetails;
};

export const FinancialDetailsUi: React.FC<FinancialDetailsUiProps> = ({ contract, financialDetailsData }) => {
    if (!financialDetailsData) {
        return null;
    }

    const { isActive, productTypeEN, contractId, customerRole } = contract;

    return (
        <>
            <GeneralSection details={financialDetailsData} isActive={isActive} />
            <IbanSection
                details={financialDetailsData}
                isActive={isActive}
                contractId={contractId}
                isFirstHolder={customerRole === CustomerRole.FIRST_HOLDER}
            />
            <PaymentDaySection
                details={financialDetailsData}
                isActive={isActive}
                contractId={contractId}
                productTypeEN={contract.productTypeEN}
            />
            <FinancialSection details={financialDetailsData} isActive={isActive} productTypeEN={productTypeEN} />
            <MileageSection
                details={financialDetailsData}
                isActive={isActive}
                productTypeEN={productTypeEN}
                contractId={contractId}
            />
        </>
    );
};
