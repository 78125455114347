import React from 'react';
import { Modal, IconList } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { getDigitalInvoiceEndpoint } from 'common';
import { CpDataApi } from 'cp-xhr';

type DigitalInvoiceModalProps = {
    shown: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const DigitalInvoiceModal: React.FC<DigitalInvoiceModalProps> = ({ shown, onClose, onConfirm }) => {
    const { t } = useTranslation('my-documents');
    const translationPrefix = 'digital-invoice-modal';
    const handleConfirm = () => {
        onConfirm();
        CpDataApi.put(getDigitalInvoiceEndpoint());
    };

    return (
        <Modal
            shown={shown}
            title={t(`${translationPrefix}.headline`)}
            buttonConfirmText={t(`${translationPrefix}.sign-up`)}
            buttonCancelText={t(`${translationPrefix}.cancel`)}
            onClose={onClose}
            onCancel={onClose}
            onConfirm={handleConfirm}
            testId={'digital-invoice-modal'}
        >
            <p>{t(`${translationPrefix}.subheadline`)}</p>
            <IconList>
                <IconList.Item icon="checkmark" success>
                    {t(`${translationPrefix}.list-item-1`)}
                </IconList.Item>
                <IconList.Item icon="checkmark" success>
                    {t(`${translationPrefix}.list-item-2`)}
                </IconList.Item>
                <IconList.Item icon="checkmark" success>
                    {t(`${translationPrefix}.list-item-3`)}
                </IconList.Item>
            </IconList>
            <div dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.legal-text`) }} />
        </Modal>
    );
};
