import React from 'react';
import { HeroImage, useAnalyticsErrorPageTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useForbiddenErrorPage } from './useForbiddenErrorPage';
import { useHistory } from 'react-router-dom';

export const ForbiddenErrorPage: React.FC = () => {
    const { cmsContent: forbiddenErrorPage, isLoading, loadingError } = useForbiddenErrorPage();

    const history = useHistory();

    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    useAnalyticsErrorPageTracker('accessDeniedError', !!forbiddenErrorPage);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={forbiddenErrorPage?.pretitle}
            title={forbiddenErrorPage?.title || ''}
            subTitle={forbiddenErrorPage?.subTitle}
            buttonText={forbiddenErrorPage?.buttonText}
            clickHandler={() => history.goBack()}
        />
    );
};
