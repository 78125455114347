import { BankData, getIbanValidationEndpoint, SignedData } from '@cp-shared-8/apis';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-8/common-utilities';
import { parseErrorResponse } from '@cp-shared-8/frontend-integration';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { CpDataApi } from '../../../../../../../cp-xhr';
import { IbanStateHandler, SetIsValidating } from './types';
import { IbanValidationError } from './validated-iban-input/IbanValidationError';
import { isValidCountryCode, isValidLength, stripWhitespaces } from './validated-iban-input/ibanValidationsUtils';

const translationPrefix = 'iban-section.edit.iban.validation';

const ibanTranslationPrefix = `${translationPrefix}.iban`;

export const getIbanValidation = (t: TFunction, currentIban: string) =>
    Yup.string()
        .required(t(`${ibanTranslationPrefix}.required`))
        .test('sameIban', t(`${ibanTranslationPrefix}.same-iban`), (str) => stripWhitespaces(str) !== currentIban)
        .test('validCountryCode', t(`${ibanTranslationPrefix}.invalid-iban`), isValidCountryCode)
        .matches(RegExp('^[A-Z]{2}[a-zA-Z0-9_ ]*$'), t(`${ibanTranslationPrefix}.invalid-iban`))
        .test('ibanLength', t(`${ibanTranslationPrefix}.invalid-iban`), isValidLength);

export const getValidationSchema = (
    t: TFunction,
    setIsValidating: SetIsValidating,
    ibanStateHandler: IbanStateHandler,
    currentIban: string,
) => {
    const { savedIban, setSavedIban, setSignedBankData } = ibanStateHandler;

    const getIbanError = (errorCode: string, iban: string): Yup.ValidationError => {
        switch (errorCode) {
            case 'INCORRECT_IBAN':
                return new Yup.ValidationError(t(`${ibanTranslationPrefix}.invalid-iban`), iban, 'iban');
            default:
                return new Yup.ValidationError(t(`${ibanTranslationPrefix}.iban-validator-unavailable`), iban, 'iban');
        }
    };

    const ibanValidation = getIbanValidation(t, currentIban);

    const isIbanFormatted = (iban?: string): boolean => {
        return !!(iban && iban.indexOf(' ') > 0);
    };

    return Yup.object().shape({
        iban: ibanValidation.test(
            'asyncIban',
            t(`${ibanTranslationPrefix}.invalid-iban`),
            async (iban?: string | null) => {
                if (!ibanValidation.isValidSync(iban) || !isIbanFormatted(iban)) {
                    setSignedBankData(undefined);
                    setSavedIban({});
                    return true;
                }
                if (savedIban.iban === stripWhitespaces(iban)) {
                    if (!savedIban.error) {
                        return true;
                    }
                    return getIbanError(savedIban.error, savedIban.iban);
                }
                await setIsValidating(true);
                return await CpDataApi.post(getIbanValidationEndpoint(), { iban: stripWhitespaces(iban) })
                    .then(({ data }: { data: SignedData<BankData> }) => {
                        const { isValid } = data.data;
                        setSignedBankData(isValid ? data : undefined);
                        setSavedIban({ iban: stripWhitespaces(iban), error: isValid ? undefined : 'INCORRECT_IBAN' });
                        return isValid;
                    })
                    .catch((error) => {
                        const errorCode =
                            parseErrorResponse<WithDefaultBusinessMarketApiError<IbanValidationError>>(error).code;
                        setSavedIban({ iban: stripWhitespaces(iban), error: errorCode });
                        return getIbanError(errorCode, iban);
                    })
                    .finally(async () => {
                        await setIsValidating(false);
                    });
            },
        ),
    });
};
