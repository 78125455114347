import { DefaultBusinessMarketApiError, DefaultMarketApiErrors } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { ContractsMock } from './contractsMock';
import { PaymentDetailsWithAllValues, PaymentDetailsWithMissingValues } from './ExampleData';
import { Contract, getPaymentDetailsEndpoint } from 'common';
import { ContractsWithAllValues } from '../contracts/ExampleData';

const mockOptions: MockOptions<DefaultMarketApiErrors> = {
    Success: {
        status: 200,
        responseBody: PaymentDetailsWithAllValues,
        delay: 1000,
    },
    'With missing values': {
        status: 200,
        responseBody: PaymentDetailsWithMissingValues,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const PaymentDetailsMock = (contract: Contract): EndpointMockDescription<DefaultMarketApiErrors> => {
    return {
        name: 'Payment Details Response',
        method: 'get',
        defaultMockOption: 'Success',
        mockOptions,
        url: getPaymentDetailsEndpoint(contract.contractId),
    };
};

function mockPaymentDetailsResponse(): void {
    const PaymentDetailsMocks = [PaymentDetailsMock(ContractsWithAllValues[0])];
    setupMockResponses(CpDataApi, [ContractsMock, ...PaymentDetailsMocks]);
}

export const PaymentDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    mockPaymentDetailsResponse();
    return <div>{storyFn()}</div>;
};
