export type RequestAppointmentForm = {
    dealer: string;
    date: string;
    time: string;
    phoneNumber: string;
    comment: string;
};

export const getInitialValues = (defaultPhoneNumber?: string): RequestAppointmentForm => ({
    dealer: '',
    date: '',
    time: '',
    phoneNumber: defaultPhoneNumber || '',
    comment: '',
});
