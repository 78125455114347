import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLastLocation } from 'react-router-last-location';
import { ExpandableContent, FormSectionGroup } from '@vwfs-bronson/bronson-react';
import { CPDate, formatCpDate } from '@cp-shared-8/common-utilities';
import { Notification, NotificationStatus, useAnalyticsDashboardPageTracker } from '@cp-shared-8/frontend-ui';
import { Contract } from '../../../common';
import { ContractComponent } from '../contract';
import { VehicleDetailsAndImage } from '../vehicle-details-and-image/VehicleDetailsAndImage';

type ContractsUiProps = {
    contracts?: Contract[];
    allUnpaidsSet: boolean;
    hasUnpaid: boolean;
    setUnpaid: (contractId: string, unpaid: boolean) => void;
};

export const ContractsUi: React.FC<ContractsUiProps> = ({ contracts, allUnpaidsSet, hasUnpaid, setUnpaid }) => {
    const { t } = useTranslation('contracts');

    const lastLocation = useLastLocation();
    const lastPathname = lastLocation === null ? '/authentication/callback' : lastLocation?.pathname;

    useAnalyticsDashboardPageTracker(!!contracts?.length && allUnpaidsSet, hasUnpaid, lastPathname);

    const isContractEndDateOlderThanAYear = (endDate?: CPDate): boolean => {
        return formatCpDate(endDate).toMoment().isBefore(formatCpDate().subtract(1, 'years').toMoment());
    };

    const getListContracts = useCallback(
        (isActive: boolean) => {
            const filteredContracts = (contracts || []).filter((contract: Contract) => contract.isActive === isActive);
            const onlyValidContracts = filteredContracts.filter(
                ({ model, brand, contractNumber, productType }) =>
                    (!isActive && model && brand && contractNumber) ||
                    (isActive && productType && model && brand && contractNumber),
            );
            const areAllContractsValid = filteredContracts.length === onlyValidContracts.length;
            const contractsNotOlderThanAYear = onlyValidContracts.filter(
                (contract) => !isContractEndDateOlderThanAYear(contract.endDate),
            );
            if (!isActive && !contractsNotOlderThanAYear.length && areAllContractsValid) return null;

            return (
                <FormSectionGroup>
                    {!areAllContractsValid && (
                        <Notification
                            status={NotificationStatus.error}
                            headline={t('error.unavailable-contract.headline')}
                            text={t('error.unavailable-contract.text')}
                            className={'u-mb'}
                            testId={'unavailable-contract'}
                        />
                    )}
                    {contractsNotOlderThanAYear.map((contract, index) => {
                        const defaultExpanded = index === 0;
                        return (
                            <React.Fragment key={contract.contractId}>
                                {index === 0 && (
                                    <VehicleDetailsAndImage
                                        vin={contract.vin}
                                        encryptedVin={contract.encryptedVin}
                                        brand={contract.brand}
                                        model={contract.model}
                                        licensePlate={contract.licensePlate}
                                        key={`${contract.contractId}-details`}
                                    />
                                )}
                                <ContractComponent
                                    key={contract.contractId}
                                    contract={contract}
                                    defaultExpanded={isActive && defaultExpanded}
                                    setUnpaid={setUnpaid}
                                />
                            </React.Fragment>
                        );
                    })}
                </FormSectionGroup>
            );
        },
        [contracts, t, setUnpaid],
    );

    const activeContracts = useMemo(() => getListContracts(true), [getListContracts]);
    const inactiveContracts = useMemo(() => getListContracts(false), [getListContracts]);

    if (!contracts) {
        return null;
    }

    if (!contracts.length) {
        return (
            <Notification
                status={NotificationStatus.error}
                headline={t('error.no-contracts.headline')}
                testId={'no-contracts'}
            />
        );
    }

    return (
        <>
            {activeContracts}
            {inactiveContracts && (
                <div className={'u-mt-large'}>
                    <ExpandableContent
                        lazyRender
                        testId="inactive-contracts-expandable"
                        className={'u-mt'}
                        noBackground
                        pageWrapClassName={'u-pl-none u-pr-none'}
                        triggerLabel={t('expand.trigger')}
                    >
                        <h4 className="u-text-center">{t('expand.headline')}</h4>
                        {inactiveContracts}
                    </ExpandableContent>
                </div>
            )}
        </>
    );
};
