import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAuthentication,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';

import { LanguageSwitcher } from './language-switcher/LanguageSwitcher';
import {
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    landingPagePath,
    legalNoticePath,
    logoutPath,
    myDocumentsPagePath,
    myProfilePagePath,
    myRequestsPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    privacyPolicyPath,
    registrationPagePath,
} from './paths';
import { useLogin } from '../../auth/useLogin';

const pagesWithoutNavbar = [registrationPagePath()];

const pagesWithoutStaticHeader = [
    landingPagePath(),
    faqPagePath(),
    cookiePolicyPath(),
    legalNoticePath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    registrationPagePath(),
    faqPagePath(),
    privacyPolicyPath(),
    logoutPath(),
];

function publicNavigationLinks(t: TFunction, login: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: landingPagePath(),
            label: t('navigation.home'),
        },
        {
            label: t('navigation.login'),
            onClick: login,
        },
    ];
}

function privateNavigationLinks(t: TFunction, logout: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: dashboardPagePath(),
            label: t('navigation.dashboard'),
        },
        {
            url: myProfilePagePath(),
            label: t('navigation.profile'),
        },
        {
            url: myDocumentsPagePath(),
            label: t('navigation.my-documents'),
        },
        {
            url: myRequestsPagePath(),
            label: t('navigation.requests'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.logout'),
            onClick: logout,
        },
    ];
}

export const NavigationBar: React.FC = () => {
    const { t } = useTranslation('navigation');
    const history = useHistory();
    const location = useLocation();
    const { isAuthenticated, logout } = useAuthentication();
    const login = useLogin();
    const { onAction: onLoginAction } = useAnalyticsActionTracker('login');
    const { onAction: onLogoutAction } = useAnalyticsActionTracker('logout');
    const currentPageName = location.pathname.split('/')[1];

    const loginFn = useCallback(() => {
        onLoginAction(currentPageName);
        return login();
    }, [login, onLoginAction, currentPageName]);
    const logoutFn = useCallback(() => {
        onLogoutAction(currentPageName);
        return logout({ redirectUri: window.location.origin + logoutPath() });
    }, [logout, onLogoutAction, currentPageName]);

    const navigationItems = useMemo(
        () =>
            (isAuthenticated ? privateNavigationLinks(t, logoutFn) : publicNavigationLinks(t, loginFn)).map(
                (navItem) => ({
                    ...{ isActive: location.pathname === navItem.url },
                    ...navItem,
                }),
            ),
        [isAuthenticated, location.pathname, logoutFn, loginFn, t],
    );

    const currentPathName = location.pathname;

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);
    const navigation: SiteNavProps = {
        navigationItems,
    };
    const onLogoClick = useCallback(
        () =>
            isAuthenticated ? (): void => history.push(dashboardPagePath()) : (): void => history.push(logoutPath()),
        [isAuthenticated, history],
    );
    return (
        <NavigationBarShared
            onLogoClick={onLogoClick()}
            languageSwitcher={<LanguageSwitcher />}
            logoAltText={t(`navigation.${isAuthenticated ? 'dashboard' : 'home'}`)}
            navigation={navigation}
            withStaticHeader={withStaticHeader}
            hidden={hidden}
        />
    );
};
