import React from 'react';
import { FinancialDetails, formatAsIban } from '../../../../../../common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { Button, Heading } from '@vwfs-bronson/bronson-react';
import { EditStatus } from '../enums';
import { IbanChangeNotification } from '../iban-change-notification/IbanChangeNotification';

type ConsultViewProps = {
    details: FinancialDetails;
    isChangeEnabled?: boolean;
    lastEditStatus: EditStatus;
    startEditing: () => void;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ details, isChangeEnabled, lastEditStatus, startEditing }) => {
    const { t } = useTranslation('financial-details');

    const { iban } = details;

    const noValuePlaceholder = '-';
    const itemList: DefinitionListItem[] = [
        {
            label: t('iban-section.iban.label'),
            tooltip: t('iban-section.iban.tooltip'),
            value: iban ? formatAsIban(iban) : noValuePlaceholder,
            testId: 'iban',
        },
    ];

    return (
        <>
            <Heading className={`c-data-overview__header u-pt `} level={'6'}>
                {t('iban-section.headline')}
                {isChangeEnabled && (
                    <Button
                        element="a"
                        link
                        small
                        className="c-data-overview__button u-mb-none u-mt-none u-mr-small"
                        icon="true"
                        iconReversed
                        onClick={startEditing}
                        testId={'edit-button'}
                    >
                        {t('translation:editable-section-nav.edit')}
                    </Button>
                )}
            </Heading>
            <DefinitionListHorizontal list={itemList} />
            <IbanChangeNotification lastEditStatus={lastEditStatus} />
        </>
    );
};
