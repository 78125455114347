import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { ContactDetails, EditStatus } from 'common';
import { ContactList } from './ContactList';
import { SubmitStatusNotification } from '../../submit-status-notification';

export type ConsultViewProps = {
    contactDetails?: ContactDetails;
    startEditing: () => void;
    lastEditStatus?: EditStatus;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ contactDetails, startEditing, lastEditStatus }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'contact-details.consult-view';

    return (
        <DataOverview
            title={t(`${translationPrefix}.title`)}
            buttonLabel={t('translation:editable-section-nav.edit')}
            buttonProps={{
                onClick: startEditing,
                testId: 'edit-button',
            }}
        >
            <>
                <Notification
                    status={NotificationStatus.info}
                    text={t(`${translationPrefix}.info-alert`)}
                    testId="contact-datails-info-alert"
                    className="u-mb-small"
                />
                <ContactList contactDetails={contactDetails} />
            </>
            <SubmitStatusNotification lastEditStatus={lastEditStatus} section="contact" />
        </DataOverview>
    );
};
