import { ProductTypeEN } from '../contracts';

export type ContractInfo = {
    isActive: boolean;
    contractId: string;
    contractNumber: string;
    productType?: string;
    productTypeEN?: ProductTypeEN;
    brand?: string;
    model?: string;
    licensePlate?: string;
};

export type CategoryId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 17 | 18 | 19 | 20 | 21;

export type CategoryDescription =
    | 'AltDadosPessoais'
    | 'AltDataRenda'
    | 'AltIBAN'
    | 'AssSegAuto'
    | 'AssPPC'
    | 'CedPosiContratual'
    | 'DesvioKmRecal'
    | 'PedDocDeclara'
    | 'ETAmort'
    | 'EoT'
    | 'EntregaViatura'
    | 'OutrosAss';

export type RequestCategory = {
    categoryId: CategoryId;
    categoryDescription: CategoryDescription;
    title: string;
};

export const RequestCategories: RequestCategory[] = [
    { categoryId: 1, categoryDescription: 'AltDadosPessoais', title: 'Alteração de dados pessoais' },
    { categoryId: 2, categoryDescription: 'AltDataRenda', title: 'Alteração de data de renda' },
    { categoryId: 3, categoryDescription: 'AltIBAN', title: 'Alteração de IBAN' },
    { categoryId: 4, categoryDescription: 'AssSegAuto', title: 'Assuntos relacionados com Seguro Auto' },
    { categoryId: 5, categoryDescription: 'AssPPC', title: 'Assuntos relacionados com Seguro de proteção ao crédito' },
    { categoryId: 6, categoryDescription: 'CedPosiContratual', title: 'Cedência de posição contratual' },
    { categoryId: 7, categoryDescription: 'DesvioKmRecal', title: 'Desvio de km e recálculos' },
    { categoryId: 8, categoryDescription: 'PedDocDeclara', title: 'Pedidos de documentação e declarações' },
    { categoryId: 9, categoryDescription: 'ETAmort', title: 'Revogação antecipada e amortização parcial' },
    { categoryId: 10, categoryDescription: 'EoT', title: 'Terminação de contrato' },
    { categoryId: 11, categoryDescription: 'OutrosAss', title: 'Outros pedidos' },
];

export interface Request {
    categoryId: CategoryId;
    contractId?: string;
    requestTitle?: string;
    requestDescription: string;
}
