import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../navigation/paths';
import { useAuthentication } from '@cp-shared-8/frontend-ui';
import { useLogin } from '../../auth/useLogin';

export const LoginRedirect: React.FC = () => {
    const { isAuthenticated } = useAuthentication();
    const login = useLogin();
    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated) login();
        else history.push(dashboardPagePath());
    }, [history, isAuthenticated, login]);

    return null;
};
