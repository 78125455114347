import React from 'react';
import { BundledProduct, BundledProducts } from '../../../../../common';
import { Card, Layout } from '@vwfs-bronson/bronson-react';
import { getCoveragesList, getListItemsForProduct, isMandatoryFieldMissing } from './utils';
import { useTranslationWithFormatting } from '../../../../../localization/useTranslationWithFormatting';
import { DefinitionListHorizontal } from '@cp-shared-8/frontend-ui';
import { ErrorNotification } from '../../../../notifications/error/ErrorNotification';
import { CoveragesList } from './CoveragesList';

type BundledProductUiProps = {
    bundledProducts?: BundledProducts;
};

export const BundledProductsUi: React.FC<BundledProductUiProps> = ({ bundledProducts }) => {
    const { t, f } = useTranslationWithFormatting('bundled-products');

    if (!bundledProducts) {
        return null;
    }

    const validProducts = bundledProducts.filter(
        (bundledProduct: BundledProduct) => !isMandatoryFieldMissing(bundledProduct),
    );

    const isError = !bundledProducts.length || validProducts.length !== bundledProducts.length;

    const getClassName = (index: number, isError: boolean): string => {
        return [
            'u-mr-xxsmall',
            ...(index !== validProducts.length - 1 ? ['u-mb'] : []),
            ...(index === 0 && isError ? ['u-mt'] : []),
        ].join(' ');
    };

    const bundledProductsList = validProducts.map((bundledProduct: BundledProduct, index: number) => {
        const coveragesList = getCoveragesList(bundledProduct);
        const showCoverages = !!coveragesList && !!coveragesList.length;
        return (
            <div className={getClassName(index, isError)} key={index}>
                <Card className={'u-text-left u-pr-none'} element="article">
                    <h5>{bundledProduct.displayedProductType}</h5>
                    {bundledProduct.productDescription && <h6>{bundledProduct.productDescription}</h6>}
                    <Layout equalHeight divider={showCoverages}>
                        <Layout.Item default={showCoverages ? '2/3' : '1/1'} s="1/1">
                            <DefinitionListHorizontal list={getListItemsForProduct(t, f, bundledProduct)} />
                        </Layout.Item>
                        {!!coveragesList && !!coveragesList.length && (
                            <Layout.Item default="1/3" s="1/1">
                                <CoveragesList coveragesList={coveragesList}></CoveragesList>
                            </Layout.Item>
                        )}
                    </Layout>
                </Card>
            </div>
        );
    });
    return (
        <div>
            {isError && (
                <ErrorNotification
                    testId={'mandatory-fields-error'}
                    headline={t('error.headline')}
                    text={t('error.text')}
                />
            )}
            {bundledProductsList}
        </div>
    );
};
