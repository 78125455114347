import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { PartialEarlySettlement } from 'components/early-settlement/partial-early-settlement';

type ParamTypes = {
    contractId: string;
};
export const PartialEarlySettlementPage: React.FC = () => {
    const { t } = useTranslation('early-settlement');
    const { contractId } = useParams<ParamTypes>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('partial-early-settlement.title')}</Heading>
            <PartialEarlySettlement contractId={contractId} />
        </ContentSection>
    );
};
