import React from 'react';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { MyProfileUi } from './ui';
import { useMyProfile } from './useMyProfile';

const MyProfileWithHandlers = withLoadingAndNoConnectionHandler(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { data, isLoading, loadingError } = useMyProfile();
    useAnalyticsPageViewTracker('profile', !!data);

    return <MyProfileWithHandlers isLoading={isLoading} myProfileData={data} hasError={!!loadingError} />;
};
