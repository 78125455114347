import React from 'react';
import { useTranslationWithFormatting } from '../../../../../localization/useTranslationWithFormatting';
import { CheckmarkList, InfoIcon } from '@cp-shared-8/frontend-ui';

type CoveragesListProps = {
    coveragesList: string[];
};

export const CoveragesList: React.FC<CoveragesListProps> = ({ coveragesList }) => {
    const { t } = useTranslationWithFormatting('bundled-products');

    return (
        <div>
            <h6>
                {t('coverages.label')}
                <InfoIcon text={t('coverages.tooltip')} />
            </h6>
            <CheckmarkList items={coveragesList} />
        </div>
    );
};
