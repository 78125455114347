import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { CookiePolicyUi } from './ui';
import { useCookiePolicy } from './useCookiePolicy';

export const CookiePolicy: React.FC = () => {
    const { cmsContent: cookiePolicy, isLoading, loadingError } = useCookiePolicy();

    const CookiePolicyWithHandlers = withLoadingAndNoConnectionHandler(CookiePolicyUi);

    return <CookiePolicyWithHandlers isLoading={isLoading} cookiePolicy={cookiePolicy} hasError={!!loadingError} />;
};
