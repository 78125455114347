import { GenericErrorPage, getGenericErrorEndpoint } from '../../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<GenericErrorPage>({
    contentName: 'genericError',
    contentEndpoint: getGenericErrorEndpoint,
});

export default reducer;
export const fetchGenericError = fetchContent;
