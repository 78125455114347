import { IbanError } from '../../../../../../../../common';
import { BankData, SignedData } from '@cp-shared-8/apis';
import { Error, WithDefaultBusinessMarketApiError } from '@cp-shared-8/common-utilities';

const bankData: BankData = {
    isValid: true,
    bankDetails: {
        iban: 'PT50000201231234567890154',
        bankName: 'Postbank',
        bic: 'PBNKDEFF',
    },
};

export const IbanValidationSuccess: SignedData<BankData> = {
    data: bankData,
    checksum: '',
};

export const IbanValidationBusinessError: Error<WithDefaultBusinessMarketApiError<IbanError>> = {
    status: 502,
    code: 'MARKET_API_DEFAULT_BUSINESS_ERROR',
    message: 'Something went wrong.',
};
