import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getContractsEndpoint } from '../../common';
import {
    ActiveFinanceWithReturnCarNotification,
    ActiveFinanceWithUnpaidAndReturnCarNotification,
    ContractsWithAllValues,
} from './ExampleData';
import { ContractDetailsMock } from './contract-details/ContractDetailsResponseMocks';
import { IbanValidationMock } from './contract-details/financial-details/iban-section/edit-view/iban-view/validated-iban-input/IbanValidationResponseMocks';
import { AmortizationTableMocks } from './contract-details/ui/ContractDetailsUIResponseMocks';
import { PaymentDetailsMock } from '../payment-details/PaymentDetailsResponseMocks';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: [
            ...ContractsWithAllValues,
            ActiveFinanceWithReturnCarNotification,
            ActiveFinanceWithUnpaidAndReturnCarNotification,
        ],
    },
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

export const ContractsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        ContractsMock,
        ContractDetailsMock(ContractsWithAllValues[0]),
        IbanValidationMock,
        ...AmortizationTableMocks,
        PaymentDetailsMock(ContractsWithAllValues[1]),
        PaymentDetailsMock(ContractsWithAllValues[3]),
        PaymentDetailsMock(ActiveFinanceWithUnpaidAndReturnCarNotification),
    ]);

    return <div>{storyFn()}</div>;
};
