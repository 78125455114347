export type InitialValues = {
    iban: string;
    currentIban: string;
    bic: string;
};

export const getInitialValues = (iban?: string): InitialValues => {
    return {
        iban: '',
        currentIban: iban ?? '',
        bic: '',
    };
};
