import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RequestAppointmentForCarReturn } from '../../components/request-appointment/request-appointment-for-car-return';

type ParamTypes = {
    contractId: string;
};

export const RequestAppointmentForCarReturnPage: React.FC = () => {
    const { t } = useTranslation('request-appointment-for-car-return');

    const { contractId } = useParams<ParamTypes>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <p>{t('description')}</p>
            <RequestAppointmentForCarReturn contractId={contractId} />
        </ContentSection>
    );
};
