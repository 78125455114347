import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const validationSchema = (t: TFunction, initialSurname: string) => {
    const translationPrefix = 'identification.edit.form-view';
    const invalidInput = `${translationPrefix}.validation.invalid-input`;
    const equalAsCurrent = `${translationPrefix}.validation.equal-as-current`;
    const onlyLetters = /^[A-Za-z]+$/;
    return Yup.object().shape({
        surname: Yup.string()
            .trim()
            .required(t(invalidInput))
            .max(40, t(invalidInput))
            .matches(onlyLetters, t(invalidInput))
            .test('equalAsCurrent', t(equalAsCurrent), (value) => !!value && value !== initialSurname),
    });
};
