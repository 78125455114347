import React from 'react';
import { ContentSection, Layout, Logo, PageWrap } from '@vwfs-bronson/bronson-react';
import { RegistrationForm } from './registration-form';
import { useTranslation } from 'react-i18next';

type RegistrationUiProps = {
    privacyPolicy?: string;
    legalNotice?: string;
};

export const RegistrationUi: React.FC<RegistrationUiProps> = ({ privacyPolicy, legalNotice }) => {
    const { t } = useTranslation('registration');

    if (!privacyPolicy || !legalNotice) {
        return null;
    }

    return (
        <>
            <ContentSection>
                <PageWrap size={'xsmall'}>
                    <Layout className={'u-mt-large'}>
                        <Layout.Item default={'1/1'}>
                            <Logo className={'u-block-center'} />
                        </Layout.Item>
                        <Layout.Item default={'1/1'} className={'u-mb u-text-center'}>
                            <h1>{t('head.title')}</h1>
                            <div dangerouslySetInnerHTML={{ __html: t('head.text') }} />
                        </Layout.Item>
                        <RegistrationForm privacyPolicy={privacyPolicy} legalNotice={legalNotice} />
                    </Layout>
                </PageWrap>
            </ContentSection>
        </>
    );
};
