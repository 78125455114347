import React from 'react';
import { useTranslation } from 'react-i18next';

import { WithDefaultCpIntegrationErrors } from '@cp-shared-8/common-utilities';
import { Notification, NotificationStatus, ScrollToTopOnNavigation } from '@cp-shared-8/frontend-ui';
import { RegistrationError } from 'common';

export const errorTestIds: { [key: string]: string } = {
    noConnection: 'error-notification-no-connection',
    notFound: 'error-notification-customer-not-found',
    noContracts: 'error-notification-no-contracts',
    blocked: 'error-notification-blocked',
    registered: 'error-notification-already-registered',
    other: 'error-notification-other',
};

type RegistrationErrorNotificationProps = {
    errorCode?: WithDefaultCpIntegrationErrors<RegistrationError>;
    className?: string;
};

export const RegistrationErrorNotification: React.FC<RegistrationErrorNotificationProps> = ({
    errorCode,
    className,
}) => {
    const { t } = useTranslation('registration');

    if (!errorCode) {
        return null;
    }

    const isRegistrationErrorCode = (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>) => {
        return errorCode in RegistrationError;
    };

    return (
        <ScrollToTopOnNavigation>
            {!isRegistrationErrorCode(errorCode) && (
                <Notification
                    testId={errorTestIds.noConnection}
                    status={NotificationStatus.error}
                    text={t('errors.no-connection.text')}
                    className={className}
                />
            )}
            {errorCode === RegistrationError.CUSTOMER_NOT_FOUND && (
                <Notification
                    testId={errorTestIds.notFound}
                    status={NotificationStatus.warning}
                    headline={t('errors.customer-not-found.headline')}
                    text={t('errors.customer-not-found.text')}
                    className={className}
                />
            )}
            {errorCode === RegistrationError.NO_CONTRACTS_FOR_CUSTOMER && (
                <Notification
                    testId={errorTestIds.noContracts}
                    status={NotificationStatus.error}
                    headline={t('errors.no-contracts.headline')}
                    text={t('errors.no-contracts.text')}
                    className={className}
                />
            )}
            {errorCode === RegistrationError.CUSTOMER_BLOCKED && (
                <Notification
                    testId={errorTestIds.blocked}
                    status={NotificationStatus.error}
                    headline={t('errors.blocked.headline')}
                    className={className}
                >
                    <span dangerouslySetInnerHTML={{ __html: t('errors.blocked.text') }} />
                </Notification>
            )}
            {errorCode === RegistrationError.CUSTOMER_ALREADY_REGISTERED && (
                <Notification
                    testId={errorTestIds.registered}
                    status={NotificationStatus.error}
                    headline={t('errors.already-registered.headline')}
                    text={t('errors.already-registered.text')}
                    className={className}
                />
            )}
            {errorCode === RegistrationError.OTHER && (
                <Notification
                    testId={errorTestIds.other}
                    status={NotificationStatus.error}
                    headline={t('errors.other.headline')}
                    text={t('errors.other.text')}
                    className={className}
                />
            )}
        </ScrollToTopOnNavigation>
    );
};
